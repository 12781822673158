
@font-face {
    font-family: 'Material Icons';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/materialicons/v53/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2) format('woff2'); }
.material-icons {
    font-family: Material Icons;
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr; }

@font-face {
    font-family: Linearicons-Free;
    src: url(https://cdn.linearicons.com/free/1.0.0/Linearicons-Free.eot);
    src: url(https://cdn.linearicons.com/free/1.0.0/Linearicons-Free.eot?#iefix) format("embedded-opentype"), url(https://cdn.linearicons.com/free/1.0.0/Linearicons-Free.woff2) format("woff2"), url(https://cdn.linearicons.com/free/1.0.0/Linearicons-Free.ttf) format("truetype"), url(https://cdn.linearicons.com/free/1.0.0/Linearicons-Free.woff) format("woff"), url(https://cdn.linearicons.com/free/1.0.0/Linearicons-Free.svg#Linearicons-Free) format("svg");
    font-weight: 400;
    font-style: normal; }

.lnr {
    font-family: Linearicons-Free;
    speak: none;
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }

.lnr-home {
    &:before {
        content: ""; } }

.lnr-apartment {
    &:before {
        content: ""; } }

.lnr-pencil {
    &:before {
        content: ""; } }

.lnr-magic-wand {
    &:before {
        content: ""; } }

.lnr-drop {
    &:before {
        content: ""; } }

.lnr-lighter {
    &:before {
        content: ""; } }

.lnr-poop {
    &:before {
        content: ""; } }

.lnr-sun {
    &:before {
        content: ""; } }

.lnr-moon {
    &:before {
        content: ""; } }

.lnr-cloud {
    &:before {
        content: ""; } }

.lnr-cloud-upload {
    &:before {
        content: ""; } }

.lnr-cloud-download {
    &:before {
        content: ""; } }

.lnr-cloud-sync {
    &:before {
        content: ""; } }

.lnr-cloud-check {
    &:before {
        content: ""; } }

.lnr-database {
    &:before {
        content: ""; } }

.lnr-lock {
    &:before {
        content: ""; } }

.lnr-cog {
    &:before {
        content: ""; } }

.lnr-trash {
    &:before {
        content: ""; } }

.lnr-dice {
    &:before {
        content: ""; } }

.lnr-heart {
    &:before {
        content: ""; } }

.lnr-star {
    &:before {
        content: ""; } }

.lnr-star-half {
    &:before {
        content: ""; } }

.lnr-star-empty {
    &:before {
        content: ""; } }

.lnr-flag {
    &:before {
        content: ""; } }

.lnr-envelope {
    &:before {
        content: ""; } }

.lnr-paperclip {
    &:before {
        content: ""; } }

.lnr-inbox {
    &:before {
        content: ""; } }

.lnr-eye {
    &:before {
        content: ""; } }

.lnr-printer {
    &:before {
        content: ""; } }

.lnr-file-empty {
    &:before {
        content: ""; } }

.lnr-file-add {
    &:before {
        content: ""; } }

.lnr-enter {
    &:before {
        content: ""; } }

.lnr-exit {
    &:before {
        content: ""; } }

.lnr-graduation-hat {
    &:before {
        content: ""; } }

.lnr-license {
    &:before {
        content: ""; } }

.lnr-music-note {
    &:before {
        content: ""; } }

.lnr-film-play {
    &:before {
        content: ""; } }

.lnr-camera-video {
    &:before {
        content: ""; } }

.lnr-camera {
    &:before {
        content: ""; } }

.lnr-picture {
    &:before {
        content: ""; } }

.lnr-book {
    &:before {
        content: ""; } }

.lnr-bookmark {
    &:before {
        content: ""; } }

.lnr-user {
    &:before {
        content: ""; } }

.lnr-users {
    &:before {
        content: ""; } }

.lnr-shirt {
    &:before {
        content: ""; } }

.lnr-store {
    &:before {
        content: ""; } }

.lnr-cart {
    &:before {
        content: ""; } }

.lnr-tag {
    &:before {
        content: ""; } }

.lnr-phone-handset {
    &:before {
        content: ""; } }

.lnr-phone {
    &:before {
        content: ""; } }

.lnr-pushpin {
    &:before {
        content: ""; } }

.lnr-map-marker {
    &:before {
        content: ""; } }

.lnr-map {
    &:before {
        content: ""; } }

.lnr-location {
    &:before {
        content: ""; } }

.lnr-calendar-full {
    &:before {
        content: ""; } }

.lnr-keyboard {
    &:before {
        content: ""; } }

.lnr-spell-check {
    &:before {
        content: ""; } }

.lnr-screen {
    &:before {
        content: ""; } }

.lnr-smartphone {
    &:before {
        content: ""; } }

.lnr-tablet {
    &:before {
        content: ""; } }

.lnr-laptop {
    &:before {
        content: ""; } }

.lnr-laptop-phone {
    &:before {
        content: ""; } }

.lnr-power-switch {
    &:before {
        content: ""; } }

.lnr-bubble {
    &:before {
        content: ""; } }

.lnr-heart-pulse {
    &:before {
        content: ""; } }

.lnr-construction {
    &:before {
        content: ""; } }

.lnr-pie-chart {
    &:before {
        content: ""; } }

.lnr-chart-bars {
    &:before {
        content: ""; } }

.lnr-gift {
    &:before {
        content: ""; } }

.lnr-diamond {
    &:before {
        content: ""; } }

.lnr-linearicons {
    &:before {
        content: ""; } }

.lnr-dinner {
    &:before {
        content: ""; } }

.lnr-coffee-cup {
    &:before {
        content: ""; } }

.lnr-leaf {
    &:before {
        content: ""; } }

.lnr-paw {
    &:before {
        content: ""; } }

.lnr-rocket {
    &:before {
        content: ""; } }

.lnr-briefcase {
    &:before {
        content: ""; } }

.lnr-bus {
    &:before {
        content: ""; } }

.lnr-car {
    &:before {
        content: ""; } }

.lnr-train {
    &:before {
        content: ""; } }

.lnr-bicycle {
    &:before {
        content: ""; } }

.lnr-wheelchair {
    &:before {
        content: ""; } }

.lnr-select {
    &:before {
        content: ""; } }

.lnr-earth {
    &:before {
        content: ""; } }

.lnr-smile {
    &:before {
        content: ""; } }

.lnr-sad {
    &:before {
        content: ""; } }

.lnr-neutral {
    &:before {
        content: ""; } }

.lnr-mustache {
    &:before {
        content: ""; } }

.lnr-alarm {
    &:before {
        content: ""; } }

.lnr-bullhorn {
    &:before {
        content: ""; } }

.lnr-volume-high {
    &:before {
        content: ""; } }

.lnr-volume-medium {
    &:before {
        content: ""; } }

.lnr-volume-low {
    &:before {
        content: ""; } }

.lnr-volume {
    &:before {
        content: ""; } }

.lnr-mic {
    &:before {
        content: ""; } }

.lnr-hourglass {
    &:before {
        content: ""; } }

.lnr-undo {
    &:before {
        content: ""; } }

.lnr-redo {
    &:before {
        content: ""; } }

.lnr-sync {
    &:before {
        content: ""; } }

.lnr-history {
    &:before {
        content: ""; } }

.lnr-clock {
    &:before {
        content: ""; } }

.lnr-download {
    &:before {
        content: ""; } }

.lnr-upload {
    &:before {
        content: ""; } }

.lnr-enter-down {
    &:before {
        content: ""; } }

.lnr-exit-up {
    &:before {
        content: ""; } }

.lnr-bug {
    &:before {
        content: ""; } }

.lnr-code {
    &:before {
        content: ""; } }

.lnr-link {
    &:before {
        content: ""; } }

.lnr-unlink {
    &:before {
        content: ""; } }

.lnr-thumbs-up {
    &:before {
        content: ""; } }

.lnr-thumbs-down {
    &:before {
        content: ""; } }

.lnr-magnifier {
    &:before {
        content: ""; } }

.lnr-cross {
    &:before {
        content: ""; } }

.lnr-menu {
    &:before {
        content: ""; } }

.lnr-list {
    &:before {
        content: ""; } }

.lnr-chevron-up {
    &:before {
        content: ""; } }

.lnr-chevron-down {
    &:before {
        content: ""; } }

.lnr-chevron-left {
    &:before {
        content: ""; } }

.lnr-chevron-right {
    &:before {
        content: ""; } }

.lnr-arrow-up {
    &:before {
        content: ""; } }

.lnr-arrow-down {
    &:before {
        content: ""; } }

.lnr-arrow-left {
    &:before {
        content: ""; } }

.lnr-arrow-right {
    &:before {
        content: ""; } }

.lnr-move {
    &:before {
        content: ""; } }

.lnr-warning {
    &:before {
        content: ""; } }

.lnr-question-circle {
    &:before {
        content: ""; } }

.lnr-menu-circle {
    &:before {
        content: ""; } }

.lnr-checkmark-circle {
    &:before {
        content: ""; } }

.lnr-cross-circle {
    &:before {
        content: ""; } }

.lnr-plus-circle {
    &:before {
        content: ""; } }

.lnr-circle-minus {
    &:before {
        content: ""; } }

.lnr-arrow-up-circle {
    &:before {
        content: ""; } }

.lnr-arrow-down-circle {
    &:before {
        content: ""; } }

.lnr-arrow-left-circle {
    &:before {
        content: ""; } }

.lnr-arrow-right-circle {
    &:before {
        content: ""; } }

.lnr-chevron-up-circle {
    &:before {
        content: ""; } }

.lnr-chevron-down-circle {
    &:before {
        content: ""; } }

.lnr-chevron-left-circle {
    &:before {
        content: ""; } }

.lnr-chevron-right-circle {
    &:before {
        content: ""; } }

.lnr-crop {
    &:before {
        content: ""; } }

.lnr-frame-expand {
    &:before {
        content: ""; } }

.lnr-frame-contract {
    &:before {
        content: ""; } }

.lnr-layers {
    &:before {
        content: ""; } }

.lnr-funnel {
    &:before {
        content: ""; } }

.lnr-text-format {
    &:before {
        content: ""; } }

.lnr-text-format-remove {
    &:before {
        content: ""; } }

.lnr-text-size {
    &:before {
        content: ""; } }

.lnr-bold {
    &:before {
        content: ""; } }

.lnr-italic {
    &:before {
        content: ""; } }

.lnr-underline {
    &:before {
        content: ""; } }

.lnr-strikethrough {
    &:before {
        content: ""; } }

.lnr-highlight {
    &:before {
        content: ""; } }

.lnr-text-align-left {
    &:before {
        content: ""; } }

.lnr-text-align-center {
    &:before {
        content: ""; } }

.lnr-text-align-right {
    &:before {
        content: ""; } }

.lnr-text-align-justify {
    &:before {
        content: ""; } }

.lnr-line-spacing {
    &:before {
        content: ""; } }

.lnr-indent-increase {
    &:before {
        content: ""; } }

.lnr-indent-decrease {
    &:before {
        content: ""; } }

.lnr-pilcrow {
    &:before {
        content: ""; } }

.lnr-direction-ltr {
    &:before {
        content: ""; } }

.lnr-direction-rtl {
    &:before {
        content: ""; } }

.lnr-page-break {
    &:before {
        content: ""; } }

.lnr-sort-alpha-asc {
    &:before {
        content: ""; } }

.lnr-sort-amount-asc {
    &:before {
        content: ""; } }

.lnr-hand {
    &:before {
        content: ""; } }

.lnr-pointer-up {
    &:before {
        content: ""; } }

.lnr-pointer-right {
    &:before {
        content: ""; } }

.lnr-pointer-down {
    &:before {
        content: ""; } }

.lnr-pointer-left {
    &:before {
        content: ""; } }

.fa,
.fas,
.far,
.fal,
.fad,
.fab {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1; }

.fa-lg {
    font-size: 1.33333em;
    line-height: 0.75em;
    vertical-align: -.0667em; }

.fa-xs {
    font-size: .75em; }

.fa-sm {
    font-size: .875em; }

.fa-1x {
    font-size: 1em; }

.fa-2x {
    font-size: 2em; }

.fa-3x {
    font-size: 3em; }

.fa-4x {
    font-size: 4em; }

.fa-5x {
    font-size: 5em; }

.fa-6x {
    font-size: 6em; }

.fa-7x {
    font-size: 7em; }

.fa-8x {
    font-size: 8em; }

.fa-9x {
    font-size: 9em; }

.fa-10x {
    font-size: 10em; }

.fa-fw {
    text-align: center;
    width: 1.25em; }

.fa-ul {
    list-style-type: none;
    margin-left: 2.5em;
    padding-left: 0; }
.fa-ul > li {
    position: relative; }

.fa-li {
    left: -2em;
    position: absolute;
    text-align: center;
    width: 2em;
    line-height: inherit; }

.fa-border {
    border: solid 0.08em #eee;
    border-radius: .1em;
    padding: .2em .25em .15em; }

.fa-pull-left {
    float: left; }

.fa-pull-right {
    float: right; }

.fa.fa-pull-left,
.fas.fa-pull-left,
.far.fa-pull-left,
.fal.fa-pull-left,
.fab.fa-pull-left {
    margin-right: .3em; }

.fa.fa-pull-right,
.fas.fa-pull-right,
.far.fa-pull-right,
.fal.fa-pull-right,
.fab.fa-pull-right {
    margin-left: .3em; }

.fa-spin {
    -webkit-animation: fa-spin 2s infinite linear;
    animation: fa-spin 2s infinite linear; }

.fa-pulse {
    -webkit-animation: fa-spin 1s infinite steps(8);
    animation: fa-spin 1s infinite steps(8); }

@-webkit-keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@keyframes fa-spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg); }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg); } }

.fa-rotate-90 {
    -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=1)";
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg); }

.fa-rotate-180 {
    -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2)";
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg); }

.fa-rotate-270 {
    -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=3)";
    -webkit-transform: rotate(270deg);
    transform: rotate(270deg); }

.fa-flip-horizontal {
    -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1)";
    -webkit-transform: scale(-1, 1);
    transform: scale(-1, 1); }

.fa-flip-vertical {
    -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
    -webkit-transform: scale(1, -1);
    transform: scale(1, -1); }

.fa-flip-both, .fa-flip-horizontal.fa-flip-vertical {
    -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
    -webkit-transform: scale(-1, -1);
    transform: scale(-1, -1);

    &:root .fa-rotate-90,
    &:root .fa-rotate-180,
    &:root .fa-rotate-270,
    &:root .fa-flip-horizontal,
    &:root .fa-flip-vertical,
    &:root .fa-flip-both {
        -webkit-filter: none;
        filter: none; } }

.fa-stack {
    display: inline-block;
    height: 2em;
    line-height: 2em;
    position: relative;
    vertical-align: middle;
    width: 2.5em; }

.fa-stack-1x,
.fa-stack-2x {
    left: 0;
    position: absolute;
    text-align: center;
    width: 100%; }

.fa-stack-1x {
    line-height: inherit; }

.fa-stack-2x {
    font-size: 2em; }

.fa-inverse {
    color: #fff; }

.fa-500px:before {
    content: "\f26e"; }

.fa-accessible-icon:before {
    content: "\f368"; }

.fa-accusoft:before {
    content: "\f369"; }

.fa-acquisitions-incorporated:before {
    content: "\f6af"; }

.fa-ad:before {
    content: "\f641"; }

.fa-address-book:before {
    content: "\f2b9"; }

.fa-address-card:before {
    content: "\f2bb"; }

.fa-adjust:before {
    content: "\f042"; }

.fa-adn:before {
    content: "\f170"; }

.fa-adobe:before {
    content: "\f778"; }

.fa-adversal:before {
    content: "\f36a"; }

.fa-affiliatetheme:before {
    content: "\f36b"; }

.fa-air-freshener:before {
    content: "\f5d0"; }

.fa-airbnb:before {
    content: "\f834"; }

.fa-algolia:before {
    content: "\f36c"; }

.fa-align-center:before {
    content: "\f037"; }

.fa-align-justify:before {
    content: "\f039"; }

.fa-align-left:before {
    content: "\f036"; }

.fa-align-right:before {
    content: "\f038"; }

.fa-alipay:before {
    content: "\f642"; }

.fa-allergies:before {
    content: "\f461"; }

.fa-amazon:before {
    content: "\f270"; }

.fa-amazon-pay:before {
    content: "\f42c"; }

.fa-ambulance:before {
    content: "\f0f9"; }

.fa-american-sign-language-interpreting:before {
    content: "\f2a3"; }

.fa-amilia:before {
    content: "\f36d"; }

.fa-anchor:before {
    content: "\f13d"; }

.fa-android:before {
    content: "\f17b"; }

.fa-angellist:before {
    content: "\f209"; }

.fa-angle-double-down:before {
    content: "\f103"; }

.fa-angle-double-left:before {
    content: "\f100"; }

.fa-angle-double-right:before {
    content: "\f101"; }

.fa-angle-double-up:before {
    content: "\f102"; }

.fa-angle-down:before {
    content: "\f107"; }

.fa-angle-left:before {
    content: "\f104"; }

.fa-angle-right:before {
    content: "\f105"; }

.fa-angle-up:before {
    content: "\f106"; }

.fa-angry:before {
    content: "\f556"; }

.fa-angrycreative:before {
    content: "\f36e"; }

.fa-angular:before {
    content: "\f420"; }

.fa-ankh:before {
    content: "\f644"; }

.fa-app-store:before {
    content: "\f36f"; }

.fa-app-store-ios:before {
    content: "\f370"; }

.fa-apper:before {
    content: "\f371"; }

.fa-apple:before {
    content: "\f179"; }

.fa-apple-alt:before {
    content: "\f5d1"; }

.fa-apple-pay:before {
    content: "\f415"; }

.fa-archive:before {
    content: "\f187"; }

.fa-archway:before {
    content: "\f557"; }

.fa-arrow-alt-circle-down:before {
    content: "\f358"; }

.fa-arrow-alt-circle-left:before {
    content: "\f359"; }

.fa-arrow-alt-circle-right:before {
    content: "\f35a"; }

.fa-arrow-alt-circle-up:before {
    content: "\f35b"; }

.fa-arrow-circle-down:before {
    content: "\f0ab"; }

.fa-arrow-circle-left:before {
    content: "\f0a8"; }

.fa-arrow-circle-right:before {
    content: "\f0a9"; }

.fa-arrow-circle-up:before {
    content: "\f0aa"; }

.fa-arrow-down:before {
    content: "\f063"; }

.fa-arrow-left:before {
    content: "\f060"; }

.fa-arrow-right:before {
    content: "\f061"; }

.fa-arrow-up:before {
    content: "\f062"; }

.fa-arrows-alt:before {
    content: "\f0b2"; }

.fa-arrows-alt-h:before {
    content: "\f337"; }

.fa-arrows-alt-v:before {
    content: "\f338"; }

.fa-artstation:before {
    content: "\f77a"; }

.fa-assistive-listening-systems:before {
    content: "\f2a2"; }

.fa-asterisk:before {
    content: "\f069"; }

.fa-asymmetrik:before {
    content: "\f372"; }

.fa-at:before {
    content: "\f1fa"; }

.fa-atlas:before {
    content: "\f558"; }

.fa-atlassian:before {
    content: "\f77b"; }

.fa-atom:before {
    content: "\f5d2"; }

.fa-audible:before {
    content: "\f373"; }

.fa-audio-description:before {
    content: "\f29e"; }

.fa-autoprefixer:before {
    content: "\f41c"; }

.fa-avianex:before {
    content: "\f374"; }

.fa-aviato:before {
    content: "\f421"; }

.fa-award:before {
    content: "\f559"; }

.fa-aws:before {
    content: "\f375"; }

.fa-baby:before {
    content: "\f77c"; }

.fa-baby-carriage:before {
    content: "\f77d"; }

.fa-backspace:before {
    content: "\f55a"; }

.fa-backward:before {
    content: "\f04a"; }

.fa-bacon:before {
    content: "\f7e5"; }

.fa-bacteria:before {
    content: "\e059"; }

.fa-bacterium:before {
    content: "\e05a"; }

.fa-bahai:before {
    content: "\f666"; }

.fa-balance-scale:before {
    content: "\f24e"; }

.fa-balance-scale-left:before {
    content: "\f515"; }

.fa-balance-scale-right:before {
    content: "\f516"; }

.fa-ban:before {
    content: "\f05e"; }

.fa-band-aid:before {
    content: "\f462"; }

.fa-bandcamp:before {
    content: "\f2d5"; }

.fa-barcode:before {
    content: "\f02a"; }

.fa-bars:before {
    content: "\f0c9"; }

.fa-baseball-ball:before {
    content: "\f433"; }

.fa-basketball-ball:before {
    content: "\f434"; }

.fa-bath:before {
    content: "\f2cd"; }

.fa-battery-empty:before {
    content: "\f244"; }

.fa-battery-full:before {
    content: "\f240"; }

.fa-battery-half:before {
    content: "\f242"; }

.fa-battery-quarter:before {
    content: "\f243"; }

.fa-battery-three-quarters:before {
    content: "\f241"; }

.fa-battle-net:before {
    content: "\f835"; }

.fa-bed:before {
    content: "\f236"; }

.fa-beer:before {
    content: "\f0fc"; }

.fa-behance:before {
    content: "\f1b4"; }

.fa-behance-square:before {
    content: "\f1b5"; }

.fa-bell:before {
    content: "\f0f3"; }

.fa-bell-slash:before {
    content: "\f1f6"; }

.fa-bezier-curve:before {
    content: "\f55b"; }

.fa-bible:before {
    content: "\f647"; }

.fa-bicycle:before {
    content: "\f206"; }

.fa-biking:before {
    content: "\f84a"; }

.fa-bimobject:before {
    content: "\f378"; }

.fa-binoculars:before {
    content: "\f1e5"; }

.fa-biohazard:before {
    content: "\f780"; }

.fa-birthday-cake:before {
    content: "\f1fd"; }

.fa-bitbucket:before {
    content: "\f171"; }

.fa-bitcoin:before {
    content: "\f379"; }

.fa-bity:before {
    content: "\f37a"; }

.fa-black-tie:before {
    content: "\f27e"; }

.fa-blackberry:before {
    content: "\f37b"; }

.fa-blender:before {
    content: "\f517"; }

.fa-blender-phone:before {
    content: "\f6b6"; }

.fa-blind:before {
    content: "\f29d"; }

.fa-blog:before {
    content: "\f781"; }

.fa-blogger:before {
    content: "\f37c"; }

.fa-blogger-b:before {
    content: "\f37d"; }

.fa-bluetooth:before {
    content: "\f293"; }

.fa-bluetooth-b:before {
    content: "\f294"; }

.fa-bold:before {
    content: "\f032"; }

.fa-bolt:before {
    content: "\f0e7"; }

.fa-bomb:before {
    content: "\f1e2"; }

.fa-bone:before {
    content: "\f5d7"; }

.fa-bong:before {
    content: "\f55c"; }

.fa-book:before {
    content: "\f02d"; }

.fa-book-dead:before {
    content: "\f6b7"; }

.fa-book-medical:before {
    content: "\f7e6"; }

.fa-book-open:before {
    content: "\f518"; }

.fa-book-reader:before {
    content: "\f5da"; }

.fa-bookmark:before {
    content: "\f02e"; }

.fa-bootstrap:before {
    content: "\f836"; }

.fa-border-all:before {
    content: "\f84c"; }

.fa-border-none:before {
    content: "\f850"; }

.fa-border-style:before {
    content: "\f853"; }

.fa-bowling-ball:before {
    content: "\f436"; }

.fa-box:before {
    content: "\f466"; }

.fa-box-open:before {
    content: "\f49e"; }

.fa-box-tissue:before {
    content: "\e05b"; }

.fa-boxes:before {
    content: "\f468"; }

.fa-braille:before {
    content: "\f2a1"; }

.fa-brain:before {
    content: "\f5dc"; }

.fa-bread-slice:before {
    content: "\f7ec"; }

.fa-briefcase:before {
    content: "\f0b1"; }

.fa-briefcase-medical:before {
    content: "\f469"; }

.fa-broadcast-tower:before {
    content: "\f519"; }

.fa-broom:before {
    content: "\f51a"; }

.fa-brush:before {
    content: "\f55d"; }

.fa-btc:before {
    content: "\f15a"; }

.fa-buffer:before {
    content: "\f837"; }

.fa-bug:before {
    content: "\f188"; }

.fa-building:before {
    content: "\f1ad"; }

.fa-bullhorn:before {
    content: "\f0a1"; }

.fa-bullseye:before {
    content: "\f140"; }

.fa-burn:before {
    content: "\f46a"; }

.fa-buromobelexperte:before {
    content: "\f37f"; }

.fa-bus:before {
    content: "\f207"; }

.fa-bus-alt:before {
    content: "\f55e"; }

.fa-business-time:before {
    content: "\f64a"; }

.fa-buy-n-large:before {
    content: "\f8a6"; }

.fa-buysellads:before {
    content: "\f20d"; }

.fa-calculator:before {
    content: "\f1ec"; }

.fa-calendar:before {
    content: "\f133"; }

.fa-calendar-alt:before {
    content: "\f073"; }

.fa-calendar-check:before {
    content: "\f274"; }

.fa-calendar-day:before {
    content: "\f783"; }

.fa-calendar-minus:before {
    content: "\f272"; }

.fa-calendar-plus:before {
    content: "\f271"; }

.fa-calendar-times:before {
    content: "\f273"; }

.fa-calendar-week:before {
    content: "\f784"; }

.fa-camera:before {
    content: "\f030"; }

.fa-camera-retro:before {
    content: "\f083"; }

.fa-campground:before {
    content: "\f6bb"; }

.fa-canadian-maple-leaf:before {
    content: "\f785"; }

.fa-candy-cane:before {
    content: "\f786"; }

.fa-cannabis:before {
    content: "\f55f"; }

.fa-capsules:before {
    content: "\f46b"; }

.fa-car:before {
    content: "\f1b9"; }

.fa-car-alt:before {
    content: "\f5de"; }

.fa-car-battery:before {
    content: "\f5df"; }

.fa-car-crash:before {
    content: "\f5e1"; }

.fa-car-side:before {
    content: "\f5e4"; }

.fa-caravan:before {
    content: "\f8ff"; }

.fa-caret-down:before {
    content: "\f0d7"; }

.fa-caret-left:before {
    content: "\f0d9"; }

.fa-caret-right:before {
    content: "\f0da"; }

.fa-caret-square-down:before {
    content: "\f150"; }

.fa-caret-square-left:before {
    content: "\f191"; }

.fa-caret-square-right:before {
    content: "\f152"; }

.fa-caret-square-up:before {
    content: "\f151"; }

.fa-caret-up:before {
    content: "\f0d8"; }

.fa-carrot:before {
    content: "\f787"; }

.fa-cart-arrow-down:before {
    content: "\f218"; }

.fa-cart-plus:before {
    content: "\f217"; }

.fa-cash-register:before {
    content: "\f788"; }

.fa-cat:before {
    content: "\f6be"; }

.fa-cc-amazon-pay:before {
    content: "\f42d"; }

.fa-cc-amex:before {
    content: "\f1f3"; }

.fa-cc-apple-pay:before {
    content: "\f416"; }

.fa-cc-diners-club:before {
    content: "\f24c"; }

.fa-cc-discover:before {
    content: "\f1f2"; }

.fa-cc-jcb:before {
    content: "\f24b"; }

.fa-cc-mastercard:before {
    content: "\f1f1"; }

.fa-cc-paypal:before {
    content: "\f1f4"; }

.fa-cc-stripe:before {
    content: "\f1f5"; }

.fa-cc-visa:before {
    content: "\f1f0"; }

.fa-centercode:before {
    content: "\f380"; }

.fa-centos:before {
    content: "\f789"; }

.fa-certificate:before {
    content: "\f0a3"; }

.fa-chair:before {
    content: "\f6c0"; }

.fa-chalkboard:before {
    content: "\f51b"; }

.fa-chalkboard-teacher:before {
    content: "\f51c"; }

.fa-charging-station:before {
    content: "\f5e7"; }

.fa-chart-area:before {
    content: "\f1fe"; }

.fa-chart-bar:before {
    content: "\f080"; }

.fa-chart-line:before {
    content: "\f201"; }

.fa-chart-pie:before {
    content: "\f200"; }

.fa-check:before {
    content: "\f00c"; }

.fa-check-circle:before {
    content: "\f058"; }

.fa-check-double:before {
    content: "\f560"; }

.fa-check-square:before {
    content: "\f14a"; }

.fa-cheese:before {
    content: "\f7ef"; }

.fa-chess:before {
    content: "\f439"; }

.fa-chess-bishop:before {
    content: "\f43a"; }

.fa-chess-board:before {
    content: "\f43c"; }

.fa-chess-king:before {
    content: "\f43f"; }

.fa-chess-knight:before {
    content: "\f441"; }

.fa-chess-pawn:before {
    content: "\f443"; }

.fa-chess-queen:before {
    content: "\f445"; }

.fa-chess-rook:before {
    content: "\f447"; }

.fa-chevron-circle-down:before {
    content: "\f13a"; }

.fa-chevron-circle-left:before {
    content: "\f137"; }

.fa-chevron-circle-right:before {
    content: "\f138"; }

.fa-chevron-circle-up:before {
    content: "\f139"; }

.fa-chevron-down:before {
    content: "\f078"; }

.fa-chevron-left:before {
    content: "\f053"; }

.fa-chevron-right:before {
    content: "\f054"; }

.fa-chevron-up:before {
    content: "\f077"; }

.fa-child:before {
    content: "\f1ae"; }

.fa-chrome:before {
    content: "\f268"; }

.fa-chromecast:before {
    content: "\f838"; }

.fa-church:before {
    content: "\f51d"; }

.fa-circle:before {
    content: "\f111"; }

.fa-circle-notch:before {
    content: "\f1ce"; }

.fa-city:before {
    content: "\f64f"; }

.fa-clinic-medical:before {
    content: "\f7f2"; }

.fa-clipboard:before {
    content: "\f328"; }

.fa-clipboard-check:before {
    content: "\f46c"; }

.fa-clipboard-list:before {
    content: "\f46d"; }

.fa-clock:before {
    content: "\f017"; }

.fa-clone:before {
    content: "\f24d"; }

.fa-closed-captioning:before {
    content: "\f20a"; }

.fa-cloud:before {
    content: "\f0c2"; }

.fa-cloud-download-alt:before {
    content: "\f381"; }

.fa-cloud-meatball:before {
    content: "\f73b"; }

.fa-cloud-moon:before {
    content: "\f6c3"; }

.fa-cloud-moon-rain:before {
    content: "\f73c"; }

.fa-cloud-rain:before {
    content: "\f73d"; }

.fa-cloud-showers-heavy:before {
    content: "\f740"; }

.fa-cloud-sun:before {
    content: "\f6c4"; }

.fa-cloud-sun-rain:before {
    content: "\f743"; }

.fa-cloud-upload-alt:before {
    content: "\f382"; }

.fa-cloudscale:before {
    content: "\f383"; }

.fa-cloudsmith:before {
    content: "\f384"; }

.fa-cloudversify:before {
    content: "\f385"; }

.fa-cocktail:before {
    content: "\f561"; }

.fa-code:before {
    content: "\f121"; }

.fa-code-branch:before {
    content: "\f126"; }

.fa-codepen:before {
    content: "\f1cb"; }

.fa-codiepie:before {
    content: "\f284"; }

.fa-coffee:before {
    content: "\f0f4"; }

.fa-cog:before {
    content: "\f013"; }

.fa-cogs:before {
    content: "\f085"; }

.fa-coins:before {
    content: "\f51e"; }

.fa-columns:before {
    content: "\f0db"; }

.fa-comment:before {
    content: "\f075"; }

.fa-comment-alt:before {
    content: "\f27a"; }

.fa-comment-dollar:before {
    content: "\f651"; }

.fa-comment-dots:before {
    content: "\f4ad"; }

.fa-comment-medical:before {
    content: "\f7f5"; }

.fa-comment-slash:before {
    content: "\f4b3"; }

.fa-comments:before {
    content: "\f086"; }

.fa-comments-dollar:before {
    content: "\f653"; }

.fa-compact-disc:before {
    content: "\f51f"; }

.fa-compass:before {
    content: "\f14e"; }

.fa-compress:before {
    content: "\f066"; }

.fa-compress-alt:before {
    content: "\f422"; }

.fa-compress-arrows-alt:before {
    content: "\f78c"; }

.fa-concierge-bell:before {
    content: "\f562"; }

.fa-confluence:before {
    content: "\f78d"; }

.fa-connectdevelop:before {
    content: "\f20e"; }

.fa-contao:before {
    content: "\f26d"; }

.fa-cookie:before {
    content: "\f563"; }

.fa-cookie-bite:before {
    content: "\f564"; }

.fa-copy:before {
    content: "\f0c5"; }

.fa-copyright:before {
    content: "\f1f9"; }

.fa-cotton-bureau:before {
    content: "\f89e"; }

.fa-couch:before {
    content: "\f4b8"; }

.fa-cpanel:before {
    content: "\f388"; }

.fa-creative-commons:before {
    content: "\f25e"; }

.fa-creative-commons-by:before {
    content: "\f4e7"; }

.fa-creative-commons-nc:before {
    content: "\f4e8"; }

.fa-creative-commons-nc-eu:before {
    content: "\f4e9"; }

.fa-creative-commons-nc-jp:before {
    content: "\f4ea"; }

.fa-creative-commons-nd:before {
    content: "\f4eb"; }

.fa-creative-commons-pd:before {
    content: "\f4ec"; }

.fa-creative-commons-pd-alt:before {
    content: "\f4ed"; }

.fa-creative-commons-remix:before {
    content: "\f4ee"; }

.fa-creative-commons-sa:before {
    content: "\f4ef"; }

.fa-creative-commons-sampling:before {
    content: "\f4f0"; }

.fa-creative-commons-sampling-plus:before {
    content: "\f4f1"; }

.fa-creative-commons-share:before {
    content: "\f4f2"; }

.fa-creative-commons-zero:before {
    content: "\f4f3"; }

.fa-credit-card:before {
    content: "\f09d"; }

.fa-critical-role:before {
    content: "\f6c9"; }

.fa-crop:before {
    content: "\f125"; }

.fa-crop-alt:before {
    content: "\f565"; }

.fa-cross:before {
    content: "\f654"; }

.fa-crosshairs:before {
    content: "\f05b"; }

.fa-crow:before {
    content: "\f520"; }

.fa-crown:before {
    content: "\f521"; }

.fa-crutch:before {
    content: "\f7f7"; }

.fa-css3:before {
    content: "\f13c"; }

.fa-css3-alt:before {
    content: "\f38b"; }

.fa-cube:before {
    content: "\f1b2"; }

.fa-cubes:before {
    content: "\f1b3"; }

.fa-cut:before {
    content: "\f0c4"; }

.fa-cuttlefish:before {
    content: "\f38c"; }

.fa-d-and-d:before {
    content: "\f38d"; }

.fa-d-and-d-beyond:before {
    content: "\f6ca"; }

.fa-dailymotion:before {
    content: "\e052"; }

.fa-dashcube:before {
    content: "\f210"; }

.fa-database:before {
    content: "\f1c0"; }

.fa-deaf:before {
    content: "\f2a4"; }

.fa-deezer:before {
    content: "\e077"; }

.fa-delicious:before {
    content: "\f1a5"; }

.fa-democrat:before {
    content: "\f747"; }

.fa-deploydog:before {
    content: "\f38e"; }

.fa-deskpro:before {
    content: "\f38f"; }

.fa-desktop:before {
    content: "\f108"; }

.fa-dev:before {
    content: "\f6cc"; }

.fa-deviantart:before {
    content: "\f1bd"; }

.fa-dharmachakra:before {
    content: "\f655"; }

.fa-dhl:before {
    content: "\f790"; }

.fa-diagnoses:before {
    content: "\f470"; }

.fa-diaspora:before {
    content: "\f791"; }

.fa-dice:before {
    content: "\f522"; }

.fa-dice-d20:before {
    content: "\f6cf"; }

.fa-dice-d6:before {
    content: "\f6d1"; }

.fa-dice-five:before {
    content: "\f523"; }

.fa-dice-four:before {
    content: "\f524"; }

.fa-dice-one:before {
    content: "\f525"; }

.fa-dice-six:before {
    content: "\f526"; }

.fa-dice-three:before {
    content: "\f527"; }

.fa-dice-two:before {
    content: "\f528"; }

.fa-digg:before {
    content: "\f1a6"; }

.fa-digital-ocean:before {
    content: "\f391"; }

.fa-digital-tachograph:before {
    content: "\f566"; }

.fa-directions:before {
    content: "\f5eb"; }

.fa-discord:before {
    content: "\f392"; }

.fa-discourse:before {
    content: "\f393"; }

.fa-disease:before {
    content: "\f7fa"; }

.fa-divide:before {
    content: "\f529"; }

.fa-dizzy:before {
    content: "\f567"; }

.fa-dna:before {
    content: "\f471"; }

.fa-dochub:before {
    content: "\f394"; }

.fa-docker:before {
    content: "\f395"; }

.fa-dog:before {
    content: "\f6d3"; }

.fa-dollar-sign:before {
    content: "\f155"; }

.fa-dolly:before {
    content: "\f472"; }

.fa-dolly-flatbed:before {
    content: "\f474"; }

.fa-donate:before {
    content: "\f4b9"; }

.fa-door-closed:before {
    content: "\f52a"; }

.fa-door-open:before {
    content: "\f52b"; }

.fa-dot-circle:before {
    content: "\f192"; }

.fa-dove:before {
    content: "\f4ba"; }

.fa-download:before {
    content: "\f019"; }

.fa-draft2digital:before {
    content: "\f396"; }

.fa-drafting-compass:before {
    content: "\f568"; }

.fa-dragon:before {
    content: "\f6d5"; }

.fa-draw-polygon:before {
    content: "\f5ee"; }

.fa-dribbble:before {
    content: "\f17d"; }

.fa-dribbble-square:before {
    content: "\f397"; }

.fa-dropbox:before {
    content: "\f16b"; }

.fa-drum:before {
    content: "\f569"; }

.fa-drum-steelpan:before {
    content: "\f56a"; }

.fa-drumstick-bite:before {
    content: "\f6d7"; }

.fa-drupal:before {
    content: "\f1a9"; }

.fa-dumbbell:before {
    content: "\f44b"; }

.fa-dumpster:before {
    content: "\f793"; }

.fa-dumpster-fire:before {
    content: "\f794"; }

.fa-dungeon:before {
    content: "\f6d9"; }

.fa-dyalog:before {
    content: "\f399"; }

.fa-earlybirds:before {
    content: "\f39a"; }

.fa-ebay:before {
    content: "\f4f4"; }

.fa-edge:before {
    content: "\f282"; }

.fa-edge-legacy:before {
    content: "\e078"; }

.fa-edit:before {
    content: "\f044"; }

.fa-egg:before {
    content: "\f7fb"; }

.fa-eject:before {
    content: "\f052"; }

.fa-elementor:before {
    content: "\f430"; }

.fa-ellipsis-h:before {
    content: "\f141"; }

.fa-ellipsis-v:before {
    content: "\f142"; }

.fa-ello:before {
    content: "\f5f1"; }

.fa-ember:before {
    content: "\f423"; }

.fa-empire:before {
    content: "\f1d1"; }

.fa-envelope:before {
    content: "\f0e0"; }

.fa-envelope-open:before {
    content: "\f2b6"; }

.fa-envelope-open-text:before {
    content: "\f658"; }

.fa-envelope-square:before {
    content: "\f199"; }

.fa-envira:before {
    content: "\f299"; }

.fa-equals:before {
    content: "\f52c"; }

.fa-eraser:before {
    content: "\f12d"; }

.fa-erlang:before {
    content: "\f39d"; }

.fa-ethereum:before {
    content: "\f42e"; }

.fa-ethernet:before {
    content: "\f796"; }

.fa-etsy:before {
    content: "\f2d7"; }

.fa-euro-sign:before {
    content: "\f153"; }

.fa-evernote:before {
    content: "\f839"; }

.fa-exchange-alt:before {
    content: "\f362"; }

.fa-exclamation:before {
    content: "\f12a"; }

.fa-exclamation-circle:before {
    content: "\f06a"; }

.fa-exclamation-triangle:before {
    content: "\f071"; }

.fa-expand:before {
    content: "\f065"; }

.fa-expand-alt:before {
    content: "\f424"; }

.fa-expand-arrows-alt:before {
    content: "\f31e"; }

.fa-expeditedssl:before {
    content: "\f23e"; }

.fa-external-link-alt:before {
    content: "\f35d"; }

.fa-external-link-square-alt:before {
    content: "\f360"; }

.fa-eye:before {
    content: "\f06e"; }

.fa-eye-dropper:before {
    content: "\f1fb"; }

.fa-eye-slash:before {
    content: "\f070"; }

.fa-facebook:before {
    content: "\f09a"; }

.fa-facebook-f:before {
    content: "\f39e"; }

.fa-facebook-messenger:before {
    content: "\f39f"; }

.fa-facebook-square:before {
    content: "\f082"; }

.fa-fan:before {
    content: "\f863"; }

.fa-fantasy-flight-games:before {
    content: "\f6dc"; }

.fa-fast-backward:before {
    content: "\f049"; }

.fa-fast-forward:before {
    content: "\f050"; }

.fa-faucet:before {
    content: "\e005"; }

.fa-fax:before {
    content: "\f1ac"; }

.fa-feather:before {
    content: "\f52d"; }

.fa-feather-alt:before {
    content: "\f56b"; }

.fa-fedex:before {
    content: "\f797"; }

.fa-fedora:before {
    content: "\f798"; }

.fa-female:before {
    content: "\f182"; }

.fa-fighter-jet:before {
    content: "\f0fb"; }

.fa-figma:before {
    content: "\f799"; }

.fa-file:before {
    content: "\f15b"; }

.fa-file-alt:before {
    content: "\f15c"; }

.fa-file-archive:before {
    content: "\f1c6"; }

.fa-file-audio:before {
    content: "\f1c7"; }

.fa-file-code:before {
    content: "\f1c9"; }

.fa-file-contract:before {
    content: "\f56c"; }

.fa-file-csv:before {
    content: "\f6dd"; }

.fa-file-download:before {
    content: "\f56d"; }

.fa-file-excel:before {
    content: "\f1c3"; }

.fa-file-export:before {
    content: "\f56e"; }

.fa-file-image:before {
    content: "\f1c5"; }

.fa-file-import:before {
    content: "\f56f"; }

.fa-file-invoice:before {
    content: "\f570"; }

.fa-file-invoice-dollar:before {
    content: "\f571"; }

.fa-file-medical:before {
    content: "\f477"; }

.fa-file-medical-alt:before {
    content: "\f478"; }

.fa-file-pdf:before {
    content: "\f1c1"; }

.fa-file-powerpoint:before {
    content: "\f1c4"; }

.fa-file-prescription:before {
    content: "\f572"; }

.fa-file-signature:before {
    content: "\f573"; }

.fa-file-upload:before {
    content: "\f574"; }

.fa-file-video:before {
    content: "\f1c8"; }

.fa-file-word:before {
    content: "\f1c2"; }

.fa-fill:before {
    content: "\f575"; }

.fa-fill-drip:before {
    content: "\f576"; }

.fa-film:before {
    content: "\f008"; }

.fa-filter:before {
    content: "\f0b0"; }

.fa-fingerprint:before {
    content: "\f577"; }

.fa-fire:before {
    content: "\f06d"; }

.fa-fire-alt:before {
    content: "\f7e4"; }

.fa-fire-extinguisher:before {
    content: "\f134"; }

.fa-firefox:before {
    content: "\f269"; }

.fa-firefox-browser:before {
    content: "\e007"; }

.fa-first-aid:before {
    content: "\f479"; }

.fa-first-order:before {
    content: "\f2b0"; }

.fa-first-order-alt:before {
    content: "\f50a"; }

.fa-firstdraft:before {
    content: "\f3a1"; }

.fa-fish:before {
    content: "\f578"; }

.fa-fist-raised:before {
    content: "\f6de"; }

.fa-flag:before {
    content: "\f024"; }

.fa-flag-checkered:before {
    content: "\f11e"; }

.fa-flag-usa:before {
    content: "\f74d"; }

.fa-flask:before {
    content: "\f0c3"; }

.fa-flickr:before {
    content: "\f16e"; }

.fa-flipboard:before {
    content: "\f44d"; }

.fa-flushed:before {
    content: "\f579"; }

.fa-fly:before {
    content: "\f417"; }

.fa-folder:before {
    content: "\f07b"; }

.fa-folder-minus:before {
    content: "\f65d"; }

.fa-folder-open:before {
    content: "\f07c"; }

.fa-folder-plus:before {
    content: "\f65e"; }

.fa-font:before {
    content: "\f031"; }

.fa-font-awesome:before {
    content: "\f2b4"; }

.fa-font-awesome-alt:before {
    content: "\f35c"; }

.fa-font-awesome-flag:before {
    content: "\f425"; }

.fa-font-awesome-logo-full:before {
    content: "\f4e6"; }

.fa-fonticons:before {
    content: "\f280"; }

.fa-fonticons-fi:before {
    content: "\f3a2"; }

.fa-football-ball:before {
    content: "\f44e"; }

.fa-fort-awesome:before {
    content: "\f286"; }

.fa-fort-awesome-alt:before {
    content: "\f3a3"; }

.fa-forumbee:before {
    content: "\f211"; }

.fa-forward:before {
    content: "\f04e"; }

.fa-foursquare:before {
    content: "\f180"; }

.fa-free-code-camp:before {
    content: "\f2c5"; }

.fa-freebsd:before {
    content: "\f3a4"; }

.fa-frog:before {
    content: "\f52e"; }

.fa-frown:before {
    content: "\f119"; }

.fa-frown-open:before {
    content: "\f57a"; }

.fa-fulcrum:before {
    content: "\f50b"; }

.fa-funnel-dollar:before {
    content: "\f662"; }

.fa-futbol:before {
    content: "\f1e3"; }

.fa-galactic-republic:before {
    content: "\f50c"; }

.fa-galactic-senate:before {
    content: "\f50d"; }

.fa-gamepad:before {
    content: "\f11b"; }

.fa-gas-pump:before {
    content: "\f52f"; }

.fa-gavel:before {
    content: "\f0e3"; }

.fa-gem:before {
    content: "\f3a5"; }

.fa-genderless:before {
    content: "\f22d"; }

.fa-get-pocket:before {
    content: "\f265"; }

.fa-gg:before {
    content: "\f260"; }

.fa-gg-circle:before {
    content: "\f261"; }

.fa-ghost:before {
    content: "\f6e2"; }

.fa-gift:before {
    content: "\f06b"; }

.fa-gifts:before {
    content: "\f79c"; }

.fa-git:before {
    content: "\f1d3"; }

.fa-git-alt:before {
    content: "\f841"; }

.fa-git-square:before {
    content: "\f1d2"; }

.fa-github:before {
    content: "\f09b"; }

.fa-github-alt:before {
    content: "\f113"; }

.fa-github-square:before {
    content: "\f092"; }

.fa-gitkraken:before {
    content: "\f3a6"; }

.fa-gitlab:before {
    content: "\f296"; }

.fa-gitter:before {
    content: "\f426"; }

.fa-glass-cheers:before {
    content: "\f79f"; }

.fa-glass-martini:before {
    content: "\f000"; }

.fa-glass-martini-alt:before {
    content: "\f57b"; }

.fa-glass-whiskey:before {
    content: "\f7a0"; }

.fa-glasses:before {
    content: "\f530"; }

.fa-glide:before {
    content: "\f2a5"; }

.fa-glide-g:before {
    content: "\f2a6"; }

.fa-globe:before {
    content: "\f0ac"; }

.fa-globe-africa:before {
    content: "\f57c"; }

.fa-globe-americas:before {
    content: "\f57d"; }

.fa-globe-asia:before {
    content: "\f57e"; }

.fa-globe-europe:before {
    content: "\f7a2"; }

.fa-gofore:before {
    content: "\f3a7"; }

.fa-golf-ball:before {
    content: "\f450"; }

.fa-goodreads:before {
    content: "\f3a8"; }

.fa-goodreads-g:before {
    content: "\f3a9"; }

.fa-google:before {
    content: "\f1a0"; }

.fa-google-drive:before {
    content: "\f3aa"; }

.fa-google-pay:before {
    content: "\e079"; }

.fa-google-play:before {
    content: "\f3ab"; }

.fa-google-plus:before {
    content: "\f2b3"; }

.fa-google-plus-g:before {
    content: "\f0d5"; }

.fa-google-plus-square:before {
    content: "\f0d4"; }

.fa-google-wallet:before {
    content: "\f1ee"; }

.fa-gopuram:before {
    content: "\f664"; }

.fa-graduation-cap:before {
    content: "\f19d"; }

.fa-gratipay:before {
    content: "\f184"; }

.fa-grav:before {
    content: "\f2d6"; }

.fa-greater-than:before {
    content: "\f531"; }

.fa-greater-than-equal:before {
    content: "\f532"; }

.fa-grimace:before {
    content: "\f57f"; }

.fa-grin:before {
    content: "\f580"; }

.fa-grin-alt:before {
    content: "\f581"; }

.fa-grin-beam:before {
    content: "\f582"; }

.fa-grin-beam-sweat:before {
    content: "\f583"; }

.fa-grin-hearts:before {
    content: "\f584"; }

.fa-grin-squint:before {
    content: "\f585"; }

.fa-grin-squint-tears:before {
    content: "\f586"; }

.fa-grin-stars:before {
    content: "\f587"; }

.fa-grin-tears:before {
    content: "\f588"; }

.fa-grin-tongue:before {
    content: "\f589"; }

.fa-grin-tongue-squint:before {
    content: "\f58a"; }

.fa-grin-tongue-wink:before {
    content: "\f58b"; }

.fa-grin-wink:before {
    content: "\f58c"; }

.fa-grip-horizontal:before {
    content: "\f58d"; }

.fa-grip-lines:before {
    content: "\f7a4"; }

.fa-grip-lines-vertical:before {
    content: "\f7a5"; }

.fa-grip-vertical:before {
    content: "\f58e"; }

.fa-gripfire:before {
    content: "\f3ac"; }

.fa-grunt:before {
    content: "\f3ad"; }

.fa-guitar:before {
    content: "\f7a6"; }

.fa-gulp:before {
    content: "\f3ae"; }

.fa-h-square:before {
    content: "\f0fd"; }

.fa-hacker-news:before {
    content: "\f1d4"; }

.fa-hacker-news-square:before {
    content: "\f3af"; }

.fa-hackerrank:before {
    content: "\f5f7"; }

.fa-hamburger:before {
    content: "\f805"; }

.fa-hammer:before {
    content: "\f6e3"; }

.fa-hamsa:before {
    content: "\f665"; }

.fa-hand-holding:before {
    content: "\f4bd"; }

.fa-hand-holding-heart:before {
    content: "\f4be"; }

.fa-hand-holding-medical:before {
    content: "\e05c"; }

.fa-hand-holding-usd:before {
    content: "\f4c0"; }

.fa-hand-holding-water:before {
    content: "\f4c1"; }

.fa-hand-lizard:before {
    content: "\f258"; }

.fa-hand-middle-finger:before {
    content: "\f806"; }

.fa-hand-paper:before {
    content: "\f256"; }

.fa-hand-peace:before {
    content: "\f25b"; }

.fa-hand-point-down:before {
    content: "\f0a7"; }

.fa-hand-point-left:before {
    content: "\f0a5"; }

.fa-hand-point-right:before {
    content: "\f0a4"; }

.fa-hand-point-up:before {
    content: "\f0a6"; }

.fa-hand-pointer:before {
    content: "\f25a"; }

.fa-hand-rock:before {
    content: "\f255"; }

.fa-hand-scissors:before {
    content: "\f257"; }

.fa-hand-sparkles:before {
    content: "\e05d"; }

.fa-hand-spock:before {
    content: "\f259"; }

.fa-hands:before {
    content: "\f4c2"; }

.fa-hands-helping:before {
    content: "\f4c4"; }

.fa-hands-wash:before {
    content: "\e05e"; }

.fa-handshake:before {
    content: "\f2b5"; }

.fa-handshake-alt-slash:before {
    content: "\e05f"; }

.fa-handshake-slash:before {
    content: "\e060"; }

.fa-hanukiah:before {
    content: "\f6e6"; }

.fa-hard-hat:before {
    content: "\f807"; }

.fa-hashtag:before {
    content: "\f292"; }

.fa-hat-cowboy:before {
    content: "\f8c0"; }

.fa-hat-cowboy-side:before {
    content: "\f8c1"; }

.fa-hat-wizard:before {
    content: "\f6e8"; }

.fa-hdd:before {
    content: "\f0a0"; }

.fa-head-side-cough:before {
    content: "\e061"; }

.fa-head-side-cough-slash:before {
    content: "\e062"; }

.fa-head-side-mask:before {
    content: "\e063"; }

.fa-head-side-virus:before {
    content: "\e064"; }

.fa-heading:before {
    content: "\f1dc"; }

.fa-headphones:before {
    content: "\f025"; }

.fa-headphones-alt:before {
    content: "\f58f"; }

.fa-headset:before {
    content: "\f590"; }

.fa-heart:before {
    content: "\f004"; }

.fa-heart-broken:before {
    content: "\f7a9"; }

.fa-heartbeat:before {
    content: "\f21e"; }

.fa-helicopter:before {
    content: "\f533"; }

.fa-highlighter:before {
    content: "\f591"; }

.fa-hiking:before {
    content: "\f6ec"; }

.fa-hippo:before {
    content: "\f6ed"; }

.fa-hips:before {
    content: "\f452"; }

.fa-hire-a-helper:before {
    content: "\f3b0"; }

.fa-history:before {
    content: "\f1da"; }

.fa-hockey-puck:before {
    content: "\f453"; }

.fa-holly-berry:before {
    content: "\f7aa"; }

.fa-home:before {
    content: "\f015"; }

.fa-hooli:before {
    content: "\f427"; }

.fa-hornbill:before {
    content: "\f592"; }

.fa-horse:before {
    content: "\f6f0"; }

.fa-horse-head:before {
    content: "\f7ab"; }

.fa-hospital:before {
    content: "\f0f8"; }

.fa-hospital-alt:before {
    content: "\f47d"; }

.fa-hospital-symbol:before {
    content: "\f47e"; }

.fa-hospital-user:before {
    content: "\f80d"; }

.fa-hot-tub:before {
    content: "\f593"; }

.fa-hotdog:before {
    content: "\f80f"; }

.fa-hotel:before {
    content: "\f594"; }

.fa-hotjar:before {
    content: "\f3b1"; }

.fa-hourglass:before {
    content: "\f254"; }

.fa-hourglass-end:before {
    content: "\f253"; }

.fa-hourglass-half:before {
    content: "\f252"; }

.fa-hourglass-start:before {
    content: "\f251"; }

.fa-house-damage:before {
    content: "\f6f1"; }

.fa-house-user:before {
    content: "\e065"; }

.fa-houzz:before {
    content: "\f27c"; }

.fa-hryvnia:before {
    content: "\f6f2"; }

.fa-html5:before {
    content: "\f13b"; }

.fa-hubspot:before {
    content: "\f3b2"; }

.fa-i-cursor:before {
    content: "\f246"; }

.fa-ice-cream:before {
    content: "\f810"; }

.fa-icicles:before {
    content: "\f7ad"; }

.fa-icons:before {
    content: "\f86d"; }

.fa-id-badge:before {
    content: "\f2c1"; }

.fa-id-card:before {
    content: "\f2c2"; }

.fa-id-card-alt:before {
    content: "\f47f"; }

.fa-ideal:before {
    content: "\e013"; }

.fa-igloo:before {
    content: "\f7ae"; }

.fa-image:before {
    content: "\f03e"; }

.fa-images:before {
    content: "\f302"; }

.fa-imdb:before {
    content: "\f2d8"; }

.fa-inbox:before {
    content: "\f01c"; }

.fa-indent:before {
    content: "\f03c"; }

.fa-industry:before {
    content: "\f275"; }

.fa-infinity:before {
    content: "\f534"; }

.fa-info:before {
    content: "\f129"; }

.fa-info-circle:before {
    content: "\f05a"; }

.fa-instagram:before {
    content: "\f16d"; }

.fa-instagram-square:before {
    content: "\e055"; }

.fa-intercom:before {
    content: "\f7af"; }

.fa-internet-explorer:before {
    content: "\f26b"; }

.fa-invision:before {
    content: "\f7b0"; }

.fa-ioxhost:before {
    content: "\f208"; }

.fa-italic:before {
    content: "\f033"; }

.fa-itch-io:before {
    content: "\f83a"; }

.fa-itunes:before {
    content: "\f3b4"; }

.fa-itunes-note:before {
    content: "\f3b5"; }

.fa-java:before {
    content: "\f4e4"; }

.fa-jedi:before {
    content: "\f669"; }

.fa-jedi-order:before {
    content: "\f50e"; }

.fa-jenkins:before {
    content: "\f3b6"; }

.fa-jira:before {
    content: "\f7b1"; }

.fa-joget:before {
    content: "\f3b7"; }

.fa-joint:before {
    content: "\f595"; }

.fa-joomla:before {
    content: "\f1aa"; }

.fa-journal-whills:before {
    content: "\f66a"; }

.fa-js:before {
    content: "\f3b8"; }

.fa-js-square:before {
    content: "\f3b9"; }

.fa-jsfiddle:before {
    content: "\f1cc"; }

.fa-kaaba:before {
    content: "\f66b"; }

.fa-kaggle:before {
    content: "\f5fa"; }

.fa-key:before {
    content: "\f084"; }

.fa-keybase:before {
    content: "\f4f5"; }

.fa-keyboard:before {
    content: "\f11c"; }

.fa-keycdn:before {
    content: "\f3ba"; }

.fa-khanda:before {
    content: "\f66d"; }

.fa-kickstarter:before {
    content: "\f3bb"; }

.fa-kickstarter-k:before {
    content: "\f3bc"; }

.fa-kiss:before {
    content: "\f596"; }

.fa-kiss-beam:before {
    content: "\f597"; }

.fa-kiss-wink-heart:before {
    content: "\f598"; }

.fa-kiwi-bird:before {
    content: "\f535"; }

.fa-korvue:before {
    content: "\f42f"; }

.fa-landmark:before {
    content: "\f66f"; }

.fa-language:before {
    content: "\f1ab"; }

.fa-laptop:before {
    content: "\f109"; }

.fa-laptop-code:before {
    content: "\f5fc"; }

.fa-laptop-house:before {
    content: "\e066"; }

.fa-laptop-medical:before {
    content: "\f812"; }

.fa-laravel:before {
    content: "\f3bd"; }

.fa-lastfm:before {
    content: "\f202"; }

.fa-lastfm-square:before {
    content: "\f203"; }

.fa-laugh:before {
    content: "\f599"; }

.fa-laugh-beam:before {
    content: "\f59a"; }

.fa-laugh-squint:before {
    content: "\f59b"; }

.fa-laugh-wink:before {
    content: "\f59c"; }

.fa-layer-group:before {
    content: "\f5fd"; }

.fa-leaf:before {
    content: "\f06c"; }

.fa-leanpub:before {
    content: "\f212"; }

.fa-lemon:before {
    content: "\f094"; }

.fa-less:before {
    content: "\f41d"; }

.fa-less-than:before {
    content: "\f536"; }

.fa-less-than-equal:before {
    content: "\f537"; }

.fa-level-down-alt:before {
    content: "\f3be"; }

.fa-level-up-alt:before {
    content: "\f3bf"; }

.fa-life-ring:before {
    content: "\f1cd"; }

.fa-lightbulb:before {
    content: "\f0eb"; }

.fa-line:before {
    content: "\f3c0"; }

.fa-link:before {
    content: "\f0c1"; }

.fa-linkedin:before {
    content: "\f08c"; }

.fa-linkedin-in:before {
    content: "\f0e1"; }

.fa-linode:before {
    content: "\f2b8"; }

.fa-linux:before {
    content: "\f17c"; }

.fa-lira-sign:before {
    content: "\f195"; }

.fa-list:before {
    content: "\f03a"; }

.fa-list-alt:before {
    content: "\f022"; }

.fa-list-ol:before {
    content: "\f0cb"; }

.fa-list-ul:before {
    content: "\f0ca"; }

.fa-location-arrow:before {
    content: "\f124"; }

.fa-lock:before {
    content: "\f023"; }

.fa-lock-open:before {
    content: "\f3c1"; }

.fa-long-arrow-alt-down:before {
    content: "\f309"; }

.fa-long-arrow-alt-left:before {
    content: "\f30a"; }

.fa-long-arrow-alt-right:before {
    content: "\f30b"; }

.fa-long-arrow-alt-up:before {
    content: "\f30c"; }

.fa-low-vision:before {
    content: "\f2a8"; }

.fa-luggage-cart:before {
    content: "\f59d"; }

.fa-lungs:before {
    content: "\f604"; }

.fa-lungs-virus:before {
    content: "\e067"; }

.fa-lyft:before {
    content: "\f3c3"; }

.fa-magento:before {
    content: "\f3c4"; }

.fa-magic:before {
    content: "\f0d0"; }

.fa-magnet:before {
    content: "\f076"; }

.fa-mail-bulk:before {
    content: "\f674"; }

.fa-mailchimp:before {
    content: "\f59e"; }

.fa-male:before {
    content: "\f183"; }

.fa-mandalorian:before {
    content: "\f50f"; }

.fa-map:before {
    content: "\f279"; }

.fa-map-marked:before {
    content: "\f59f"; }

.fa-map-marked-alt:before {
    content: "\f5a0"; }

.fa-map-marker:before {
    content: "\f041"; }

.fa-map-marker-alt:before {
    content: "\f3c5"; }

.fa-map-pin:before {
    content: "\f276"; }

.fa-map-signs:before {
    content: "\f277"; }

.fa-markdown:before {
    content: "\f60f"; }

.fa-marker:before {
    content: "\f5a1"; }

.fa-mars:before {
    content: "\f222"; }

.fa-mars-double:before {
    content: "\f227"; }

.fa-mars-stroke:before {
    content: "\f229"; }

.fa-mars-stroke-h:before {
    content: "\f22b"; }

.fa-mars-stroke-v:before {
    content: "\f22a"; }

.fa-mask:before {
    content: "\f6fa"; }

.fa-mastodon:before {
    content: "\f4f6"; }

.fa-maxcdn:before {
    content: "\f136"; }

.fa-mdb:before {
    content: "\f8ca"; }

.fa-medal:before {
    content: "\f5a2"; }

.fa-medapps:before {
    content: "\f3c6"; }

.fa-medium:before {
    content: "\f23a"; }

.fa-medium-m:before {
    content: "\f3c7"; }

.fa-medkit:before {
    content: "\f0fa"; }

.fa-medrt:before {
    content: "\f3c8"; }

.fa-meetup:before {
    content: "\f2e0"; }

.fa-megaport:before {
    content: "\f5a3"; }

.fa-meh:before {
    content: "\f11a"; }

.fa-meh-blank:before {
    content: "\f5a4"; }

.fa-meh-rolling-eyes:before {
    content: "\f5a5"; }

.fa-memory:before {
    content: "\f538"; }

.fa-mendeley:before {
    content: "\f7b3"; }

.fa-menorah:before {
    content: "\f676"; }

.fa-mercury:before {
    content: "\f223"; }

.fa-meteor:before {
    content: "\f753"; }

.fa-microblog:before {
    content: "\e01a"; }

.fa-microchip:before {
    content: "\f2db"; }

.fa-microphone:before {
    content: "\f130"; }

.fa-microphone-alt:before {
    content: "\f3c9"; }

.fa-microphone-alt-slash:before {
    content: "\f539"; }

.fa-microphone-slash:before {
    content: "\f131"; }

.fa-microscope:before {
    content: "\f610"; }

.fa-microsoft:before {
    content: "\f3ca"; }

.fa-minus:before {
    content: "\f068"; }

.fa-minus-circle:before {
    content: "\f056"; }

.fa-minus-square:before {
    content: "\f146"; }

.fa-mitten:before {
    content: "\f7b5"; }

.fa-mix:before {
    content: "\f3cb"; }

.fa-mixcloud:before {
    content: "\f289"; }

.fa-mixer:before {
    content: "\e056"; }

.fa-mizuni:before {
    content: "\f3cc"; }

.fa-mobile:before {
    content: "\f10b"; }

.fa-mobile-alt:before {
    content: "\f3cd"; }

.fa-modx:before {
    content: "\f285"; }

.fa-monero:before {
    content: "\f3d0"; }

.fa-money-bill:before {
    content: "\f0d6"; }

.fa-money-bill-alt:before {
    content: "\f3d1"; }

.fa-money-bill-wave:before {
    content: "\f53a"; }

.fa-money-bill-wave-alt:before {
    content: "\f53b"; }

.fa-money-check:before {
    content: "\f53c"; }

.fa-money-check-alt:before {
    content: "\f53d"; }

.fa-monument:before {
    content: "\f5a6"; }

.fa-moon:before {
    content: "\f186"; }

.fa-mortar-pestle:before {
    content: "\f5a7"; }

.fa-mosque:before {
    content: "\f678"; }

.fa-motorcycle:before {
    content: "\f21c"; }

.fa-mountain:before {
    content: "\f6fc"; }

.fa-mouse:before {
    content: "\f8cc"; }

.fa-mouse-pointer:before {
    content: "\f245"; }

.fa-mug-hot:before {
    content: "\f7b6"; }

.fa-music:before {
    content: "\f001"; }

.fa-napster:before {
    content: "\f3d2"; }

.fa-neos:before {
    content: "\f612"; }

.fa-network-wired:before {
    content: "\f6ff"; }

.fa-neuter:before {
    content: "\f22c"; }

.fa-newspaper:before {
    content: "\f1ea"; }

.fa-nimblr:before {
    content: "\f5a8"; }

.fa-node:before {
    content: "\f419"; }

.fa-node-js:before {
    content: "\f3d3"; }

.fa-not-equal:before {
    content: "\f53e"; }

.fa-notes-medical:before {
    content: "\f481"; }

.fa-npm:before {
    content: "\f3d4"; }

.fa-ns8:before {
    content: "\f3d5"; }

.fa-nutritionix:before {
    content: "\f3d6"; }

.fa-object-group:before {
    content: "\f247"; }

.fa-object-ungroup:before {
    content: "\f248"; }

.fa-odnoklassniki:before {
    content: "\f263"; }

.fa-odnoklassniki-square:before {
    content: "\f264"; }

.fa-oil-can:before {
    content: "\f613"; }

.fa-old-republic:before {
    content: "\f510"; }

.fa-om:before {
    content: "\f679"; }

.fa-opencart:before {
    content: "\f23d"; }

.fa-openid:before {
    content: "\f19b"; }

.fa-opera:before {
    content: "\f26a"; }

.fa-optin-monster:before {
    content: "\f23c"; }

.fa-orcid:before {
    content: "\f8d2"; }

.fa-osi:before {
    content: "\f41a"; }

.fa-otter:before {
    content: "\f700"; }

.fa-outdent:before {
    content: "\f03b"; }

.fa-page4:before {
    content: "\f3d7"; }

.fa-pagelines:before {
    content: "\f18c"; }

.fa-pager:before {
    content: "\f815"; }

.fa-paint-brush:before {
    content: "\f1fc"; }

.fa-paint-roller:before {
    content: "\f5aa"; }

.fa-palette:before {
    content: "\f53f"; }

.fa-palfed:before {
    content: "\f3d8"; }

.fa-pallet:before {
    content: "\f482"; }

.fa-paper-plane:before {
    content: "\f1d8"; }

.fa-paperclip:before {
    content: "\f0c6"; }

.fa-parachute-box:before {
    content: "\f4cd"; }

.fa-paragraph:before {
    content: "\f1dd"; }

.fa-parking:before {
    content: "\f540"; }

.fa-passport:before {
    content: "\f5ab"; }

.fa-pastafarianism:before {
    content: "\f67b"; }

.fa-paste:before {
    content: "\f0ea"; }

.fa-patreon:before {
    content: "\f3d9"; }

.fa-pause:before {
    content: "\f04c"; }

.fa-pause-circle:before {
    content: "\f28b"; }

.fa-paw:before {
    content: "\f1b0"; }

.fa-paypal:before {
    content: "\f1ed"; }

.fa-peace:before {
    content: "\f67c"; }

.fa-pen:before {
    content: "\f304"; }

.fa-pen-alt:before {
    content: "\f305"; }

.fa-pen-fancy:before {
    content: "\f5ac"; }

.fa-pen-nib:before {
    content: "\f5ad"; }

.fa-pen-square:before {
    content: "\f14b"; }

.fa-pencil-alt:before {
    content: "\f303"; }

.fa-pencil-ruler:before {
    content: "\f5ae"; }

.fa-penny-arcade:before {
    content: "\f704"; }

.fa-people-arrows:before {
    content: "\e068"; }

.fa-people-carry:before {
    content: "\f4ce"; }

.fa-pepper-hot:before {
    content: "\f816"; }

.fa-percent:before {
    content: "\f295"; }

.fa-percentage:before {
    content: "\f541"; }

.fa-periscope:before {
    content: "\f3da"; }

.fa-person-booth:before {
    content: "\f756"; }

.fa-phabricator:before {
    content: "\f3db"; }

.fa-phoenix-framework:before {
    content: "\f3dc"; }

.fa-phoenix-squadron:before {
    content: "\f511"; }

.fa-phone:before {
    content: "\f095"; }

.fa-phone-alt:before {
    content: "\f879"; }

.fa-phone-slash:before {
    content: "\f3dd"; }

.fa-phone-square:before {
    content: "\f098"; }

.fa-phone-square-alt:before {
    content: "\f87b"; }

.fa-phone-volume:before {
    content: "\f2a0"; }

.fa-photo-video:before {
    content: "\f87c"; }

.fa-php:before {
    content: "\f457"; }

.fa-pied-piper:before {
    content: "\f2ae"; }

.fa-pied-piper-alt:before {
    content: "\f1a8"; }

.fa-pied-piper-hat:before {
    content: "\f4e5"; }

.fa-pied-piper-pp:before {
    content: "\f1a7"; }

.fa-pied-piper-square:before {
    content: "\e01e"; }

.fa-piggy-bank:before {
    content: "\f4d3"; }

.fa-pills:before {
    content: "\f484"; }

.fa-pinterest:before {
    content: "\f0d2"; }

.fa-pinterest-p:before {
    content: "\f231"; }

.fa-pinterest-square:before {
    content: "\f0d3"; }

.fa-pizza-slice:before {
    content: "\f818"; }

.fa-place-of-worship:before {
    content: "\f67f"; }

.fa-plane:before {
    content: "\f072"; }

.fa-plane-arrival:before {
    content: "\f5af"; }

.fa-plane-departure:before {
    content: "\f5b0"; }

.fa-plane-slash:before {
    content: "\e069"; }

.fa-play:before {
    content: "\f04b"; }

.fa-play-circle:before {
    content: "\f144"; }

.fa-playstation:before {
    content: "\f3df"; }

.fa-plug:before {
    content: "\f1e6"; }

.fa-plus:before {
    content: "\f067"; }

.fa-plus-circle:before {
    content: "\f055"; }

.fa-plus-square:before {
    content: "\f0fe"; }

.fa-podcast:before {
    content: "\f2ce"; }

.fa-poll:before {
    content: "\f681"; }

.fa-poll-h:before {
    content: "\f682"; }

.fa-poo:before {
    content: "\f2fe"; }

.fa-poo-storm:before {
    content: "\f75a"; }

.fa-poop:before {
    content: "\f619"; }

.fa-portrait:before {
    content: "\f3e0"; }

.fa-pound-sign:before {
    content: "\f154"; }

.fa-power-off:before {
    content: "\f011"; }

.fa-pray:before {
    content: "\f683"; }

.fa-praying-hands:before {
    content: "\f684"; }

.fa-prescription:before {
    content: "\f5b1"; }

.fa-prescription-bottle:before {
    content: "\f485"; }

.fa-prescription-bottle-alt:before {
    content: "\f486"; }

.fa-print:before {
    content: "\f02f"; }

.fa-procedures:before {
    content: "\f487"; }

.fa-product-hunt:before {
    content: "\f288"; }

.fa-project-diagram:before {
    content: "\f542"; }

.fa-pump-medical:before {
    content: "\e06a"; }

.fa-pump-soap:before {
    content: "\e06b"; }

.fa-pushed:before {
    content: "\f3e1"; }

.fa-puzzle-piece:before {
    content: "\f12e"; }

.fa-python:before {
    content: "\f3e2"; }

.fa-qq:before {
    content: "\f1d6"; }

.fa-qrcode:before {
    content: "\f029"; }

.fa-question:before {
    content: "\f128"; }

.fa-question-circle:before {
    content: "\f059"; }

.fa-quidditch:before {
    content: "\f458"; }

.fa-quinscape:before {
    content: "\f459"; }

.fa-quora:before {
    content: "\f2c4"; }

.fa-quote-left:before {
    content: "\f10d"; }

.fa-quote-right:before {
    content: "\f10e"; }

.fa-quran:before {
    content: "\f687"; }

.fa-r-project:before {
    content: "\f4f7"; }

.fa-radiation:before {
    content: "\f7b9"; }

.fa-radiation-alt:before {
    content: "\f7ba"; }

.fa-rainbow:before {
    content: "\f75b"; }

.fa-random:before {
    content: "\f074"; }

.fa-raspberry-pi:before {
    content: "\f7bb"; }

.fa-ravelry:before {
    content: "\f2d9"; }

.fa-react:before {
    content: "\f41b"; }

.fa-reacteurope:before {
    content: "\f75d"; }

.fa-readme:before {
    content: "\f4d5"; }

.fa-rebel:before {
    content: "\f1d0"; }

.fa-receipt:before {
    content: "\f543"; }

.fa-record-vinyl:before {
    content: "\f8d9"; }

.fa-recycle:before {
    content: "\f1b8"; }

.fa-red-river:before {
    content: "\f3e3"; }

.fa-reddit:before {
    content: "\f1a1"; }

.fa-reddit-alien:before {
    content: "\f281"; }

.fa-reddit-square:before {
    content: "\f1a2"; }

.fa-redhat:before {
    content: "\f7bc"; }

.fa-redo:before {
    content: "\f01e"; }

.fa-redo-alt:before {
    content: "\f2f9"; }

.fa-registered:before {
    content: "\f25d"; }

.fa-remove-format:before {
    content: "\f87d"; }

.fa-renren:before {
    content: "\f18b"; }

.fa-reply:before {
    content: "\f3e5"; }

.fa-reply-all:before {
    content: "\f122"; }

.fa-replyd:before {
    content: "\f3e6"; }

.fa-republican:before {
    content: "\f75e"; }

.fa-researchgate:before {
    content: "\f4f8"; }

.fa-resolving:before {
    content: "\f3e7"; }

.fa-restroom:before {
    content: "\f7bd"; }

.fa-retweet:before {
    content: "\f079"; }

.fa-rev:before {
    content: "\f5b2"; }

.fa-ribbon:before {
    content: "\f4d6"; }

.fa-ring:before {
    content: "\f70b"; }

.fa-road:before {
    content: "\f018"; }

.fa-robot:before {
    content: "\f544"; }

.fa-rocket:before {
    content: "\f135"; }

.fa-rocketchat:before {
    content: "\f3e8"; }

.fa-rockrms:before {
    content: "\f3e9"; }

.fa-route:before {
    content: "\f4d7"; }

.fa-rss:before {
    content: "\f09e"; }

.fa-rss-square:before {
    content: "\f143"; }

.fa-ruble-sign:before {
    content: "\f158"; }

.fa-ruler:before {
    content: "\f545"; }

.fa-ruler-combined:before {
    content: "\f546"; }

.fa-ruler-horizontal:before {
    content: "\f547"; }

.fa-ruler-vertical:before {
    content: "\f548"; }

.fa-running:before {
    content: "\f70c"; }

.fa-rupee-sign:before {
    content: "\f156"; }

.fa-rust:before {
    content: "\e07a"; }

.fa-sad-cry:before {
    content: "\f5b3"; }

.fa-sad-tear:before {
    content: "\f5b4"; }

.fa-safari:before {
    content: "\f267"; }

.fa-salesforce:before {
    content: "\f83b"; }

.fa-sass:before {
    content: "\f41e"; }

.fa-satellite:before {
    content: "\f7bf"; }

.fa-satellite-dish:before {
    content: "\f7c0"; }

.fa-save:before {
    content: "\f0c7"; }

.fa-schlix:before {
    content: "\f3ea"; }

.fa-school:before {
    content: "\f549"; }

.fa-screwdriver:before {
    content: "\f54a"; }

.fa-scribd:before {
    content: "\f28a"; }

.fa-scroll:before {
    content: "\f70e"; }

.fa-sd-card:before {
    content: "\f7c2"; }

.fa-search:before {
    content: "\f002"; }

.fa-search-dollar:before {
    content: "\f688"; }

.fa-search-location:before {
    content: "\f689"; }

.fa-search-minus:before {
    content: "\f010"; }

.fa-search-plus:before {
    content: "\f00e"; }

.fa-searchengin:before {
    content: "\f3eb"; }

.fa-seedling:before {
    content: "\f4d8"; }

.fa-sellcast:before {
    content: "\f2da"; }

.fa-sellsy:before {
    content: "\f213"; }

.fa-server:before {
    content: "\f233"; }

.fa-servicestack:before {
    content: "\f3ec"; }

.fa-shapes:before {
    content: "\f61f"; }

.fa-share:before {
    content: "\f064"; }

.fa-share-alt:before {
    content: "\f1e0"; }

.fa-share-alt-square:before {
    content: "\f1e1"; }

.fa-share-square:before {
    content: "\f14d"; }

.fa-shekel-sign:before {
    content: "\f20b"; }

.fa-shield-alt:before {
    content: "\f3ed"; }

.fa-shield-virus:before {
    content: "\e06c"; }

.fa-ship:before {
    content: "\f21a"; }

.fa-shipping-fast:before {
    content: "\f48b"; }

.fa-shirtsinbulk:before {
    content: "\f214"; }

.fa-shoe-prints:before {
    content: "\f54b"; }

.fa-shopify:before {
    content: "\e057"; }

.fa-shopping-bag:before {
    content: "\f290"; }

.fa-shopping-basket:before {
    content: "\f291"; }

.fa-shopping-cart:before {
    content: "\f07a"; }

.fa-shopware:before {
    content: "\f5b5"; }

.fa-shower:before {
    content: "\f2cc"; }

.fa-shuttle-van:before {
    content: "\f5b6"; }

.fa-sign:before {
    content: "\f4d9"; }

.fa-sign-in-alt:before {
    content: "\f2f6"; }

.fa-sign-language:before {
    content: "\f2a7"; }

.fa-sign-out-alt:before {
    content: "\f2f5"; }

.fa-signal:before {
    content: "\f012"; }

.fa-signature:before {
    content: "\f5b7"; }

.fa-sim-card:before {
    content: "\f7c4"; }

.fa-simplybuilt:before {
    content: "\f215"; }

.fa-sink:before {
    content: "\e06d"; }

.fa-sistrix:before {
    content: "\f3ee"; }

.fa-sitemap:before {
    content: "\f0e8"; }

.fa-sith:before {
    content: "\f512"; }

.fa-skating:before {
    content: "\f7c5"; }

.fa-sketch:before {
    content: "\f7c6"; }

.fa-skiing:before {
    content: "\f7c9"; }

.fa-skiing-nordic:before {
    content: "\f7ca"; }

.fa-skull:before {
    content: "\f54c"; }

.fa-skull-crossbones:before {
    content: "\f714"; }

.fa-skyatlas:before {
    content: "\f216"; }

.fa-skype:before {
    content: "\f17e"; }

.fa-slack:before {
    content: "\f198"; }

.fa-slack-hash:before {
    content: "\f3ef"; }

.fa-slash:before {
    content: "\f715"; }

.fa-sleigh:before {
    content: "\f7cc"; }

.fa-sliders-h:before {
    content: "\f1de"; }

.fa-slideshare:before {
    content: "\f1e7"; }

.fa-smile:before {
    content: "\f118"; }

.fa-smile-beam:before {
    content: "\f5b8"; }

.fa-smile-wink:before {
    content: "\f4da"; }

.fa-smog:before {
    content: "\f75f"; }

.fa-smoking:before {
    content: "\f48d"; }

.fa-smoking-ban:before {
    content: "\f54d"; }

.fa-sms:before {
    content: "\f7cd"; }

.fa-snapchat:before {
    content: "\f2ab"; }

.fa-snapchat-ghost:before {
    content: "\f2ac"; }

.fa-snapchat-square:before {
    content: "\f2ad"; }

.fa-snowboarding:before {
    content: "\f7ce"; }

.fa-snowflake:before {
    content: "\f2dc"; }

.fa-snowman:before {
    content: "\f7d0"; }

.fa-snowplow:before {
    content: "\f7d2"; }

.fa-soap:before {
    content: "\e06e"; }

.fa-socks:before {
    content: "\f696"; }

.fa-solar-panel:before {
    content: "\f5ba"; }

.fa-sort:before {
    content: "\f0dc"; }

.fa-sort-alpha-down:before {
    content: "\f15d"; }

.fa-sort-alpha-down-alt:before {
    content: "\f881"; }

.fa-sort-alpha-up:before {
    content: "\f15e"; }

.fa-sort-alpha-up-alt:before {
    content: "\f882"; }

.fa-sort-amount-down:before {
    content: "\f160"; }

.fa-sort-amount-down-alt:before {
    content: "\f884"; }

.fa-sort-amount-up:before {
    content: "\f161"; }

.fa-sort-amount-up-alt:before {
    content: "\f885"; }

.fa-sort-down:before {
    content: "\f0dd"; }

.fa-sort-numeric-down:before {
    content: "\f162"; }

.fa-sort-numeric-down-alt:before {
    content: "\f886"; }

.fa-sort-numeric-up:before {
    content: "\f163"; }

.fa-sort-numeric-up-alt:before {
    content: "\f887"; }

.fa-sort-up:before {
    content: "\f0de"; }

.fa-soundcloud:before {
    content: "\f1be"; }

.fa-sourcetree:before {
    content: "\f7d3"; }

.fa-spa:before {
    content: "\f5bb"; }

.fa-space-shuttle:before {
    content: "\f197"; }

.fa-speakap:before {
    content: "\f3f3"; }

.fa-speaker-deck:before {
    content: "\f83c"; }

.fa-spell-check:before {
    content: "\f891"; }

.fa-spider:before {
    content: "\f717"; }

.fa-spinner:before {
    content: "\f110"; }

.fa-splotch:before {
    content: "\f5bc"; }

.fa-spotify:before {
    content: "\f1bc"; }

.fa-spray-can:before {
    content: "\f5bd"; }

.fa-square:before {
    content: "\f0c8"; }

.fa-square-full:before {
    content: "\f45c"; }

.fa-square-root-alt:before {
    content: "\f698"; }

.fa-squarespace:before {
    content: "\f5be"; }

.fa-stack-exchange:before {
    content: "\f18d"; }

.fa-stack-overflow:before {
    content: "\f16c"; }

.fa-stackpath:before {
    content: "\f842"; }

.fa-stamp:before {
    content: "\f5bf"; }

.fa-star:before {
    content: "\f005"; }

.fa-star-and-crescent:before {
    content: "\f699"; }

.fa-star-half:before {
    content: "\f089"; }

.fa-star-half-alt:before {
    content: "\f5c0"; }

.fa-star-of-david:before {
    content: "\f69a"; }

.fa-star-of-life:before {
    content: "\f621"; }

.fa-staylinked:before {
    content: "\f3f5"; }

.fa-steam:before {
    content: "\f1b6"; }

.fa-steam-square:before {
    content: "\f1b7"; }

.fa-steam-symbol:before {
    content: "\f3f6"; }

.fa-step-backward:before {
    content: "\f048"; }

.fa-step-forward:before {
    content: "\f051"; }

.fa-stethoscope:before {
    content: "\f0f1"; }

.fa-sticker-mule:before {
    content: "\f3f7"; }

.fa-sticky-note:before {
    content: "\f249"; }

.fa-stop:before {
    content: "\f04d"; }

.fa-stop-circle:before {
    content: "\f28d"; }

.fa-stopwatch:before {
    content: "\f2f2"; }

.fa-stopwatch-20:before {
    content: "\e06f"; }

.fa-store:before {
    content: "\f54e"; }

.fa-store-alt:before {
    content: "\f54f"; }

.fa-store-alt-slash:before {
    content: "\e070"; }

.fa-store-slash:before {
    content: "\e071"; }

.fa-strava:before {
    content: "\f428"; }

.fa-stream:before {
    content: "\f550"; }

.fa-street-view:before {
    content: "\f21d"; }

.fa-strikethrough:before {
    content: "\f0cc"; }

.fa-stripe:before {
    content: "\f429"; }

.fa-stripe-s:before {
    content: "\f42a"; }

.fa-stroopwafel:before {
    content: "\f551"; }

.fa-studiovinari:before {
    content: "\f3f8"; }

.fa-stumbleupon:before {
    content: "\f1a4"; }

.fa-stumbleupon-circle:before {
    content: "\f1a3"; }

.fa-subscript:before {
    content: "\f12c"; }

.fa-subway:before {
    content: "\f239"; }

.fa-suitcase:before {
    content: "\f0f2"; }

.fa-suitcase-rolling:before {
    content: "\f5c1"; }

.fa-sun:before {
    content: "\f185"; }

.fa-superpowers:before {
    content: "\f2dd"; }

.fa-superscript:before {
    content: "\f12b"; }

.fa-supple:before {
    content: "\f3f9"; }

.fa-surprise:before {
    content: "\f5c2"; }

.fa-suse:before {
    content: "\f7d6"; }

.fa-swatchbook:before {
    content: "\f5c3"; }

.fa-swift:before {
    content: "\f8e1"; }

.fa-swimmer:before {
    content: "\f5c4"; }

.fa-swimming-pool:before {
    content: "\f5c5"; }

.fa-symfony:before {
    content: "\f83d"; }

.fa-synagogue:before {
    content: "\f69b"; }

.fa-sync:before {
    content: "\f021"; }

.fa-sync-alt:before {
    content: "\f2f1"; }

.fa-syringe:before {
    content: "\f48e"; }

.fa-table:before {
    content: "\f0ce"; }

.fa-table-tennis:before {
    content: "\f45d"; }

.fa-tablet:before {
    content: "\f10a"; }

.fa-tablet-alt:before {
    content: "\f3fa"; }

.fa-tablets:before {
    content: "\f490"; }

.fa-tachometer-alt:before {
    content: "\f3fd"; }

.fa-tag:before {
    content: "\f02b"; }

.fa-tags:before {
    content: "\f02c"; }

.fa-tape:before {
    content: "\f4db"; }

.fa-tasks:before {
    content: "\f0ae"; }

.fa-taxi:before {
    content: "\f1ba"; }

.fa-teamspeak:before {
    content: "\f4f9"; }

.fa-teeth:before {
    content: "\f62e"; }

.fa-teeth-open:before {
    content: "\f62f"; }

.fa-telegram:before {
    content: "\f2c6"; }

.fa-telegram-plane:before {
    content: "\f3fe"; }

.fa-temperature-high:before {
    content: "\f769"; }

.fa-temperature-low:before {
    content: "\f76b"; }

.fa-tencent-weibo:before {
    content: "\f1d5"; }

.fa-tenge:before {
    content: "\f7d7"; }

.fa-terminal:before {
    content: "\f120"; }

.fa-text-height:before {
    content: "\f034"; }

.fa-text-width:before {
    content: "\f035"; }

.fa-th:before {
    content: "\f00a"; }

.fa-th-large:before {
    content: "\f009"; }

.fa-th-list:before {
    content: "\f00b"; }

.fa-the-red-yeti:before {
    content: "\f69d"; }

.fa-theater-masks:before {
    content: "\f630"; }

.fa-themeco:before {
    content: "\f5c6"; }

.fa-themeisle:before {
    content: "\f2b2"; }

.fa-thermometer:before {
    content: "\f491"; }

.fa-thermometer-empty:before {
    content: "\f2cb"; }

.fa-thermometer-full:before {
    content: "\f2c7"; }

.fa-thermometer-half:before {
    content: "\f2c9"; }

.fa-thermometer-quarter:before {
    content: "\f2ca"; }

.fa-thermometer-three-quarters:before {
    content: "\f2c8"; }

.fa-think-peaks:before {
    content: "\f731"; }

.fa-thumbs-down:before {
    content: "\f165"; }

.fa-thumbs-up:before {
    content: "\f164"; }

.fa-thumbtack:before {
    content: "\f08d"; }

.fa-ticket-alt:before {
    content: "\f3ff"; }

.fa-tiktok:before {
    content: "\e07b"; }

.fa-times:before {
    content: "\f00d"; }

.fa-times-circle:before {
    content: "\f057"; }

.fa-tint:before {
    content: "\f043"; }

.fa-tint-slash:before {
    content: "\f5c7"; }

.fa-tired:before {
    content: "\f5c8"; }

.fa-toggle-off:before {
    content: "\f204"; }

.fa-toggle-on:before {
    content: "\f205"; }

.fa-toilet:before {
    content: "\f7d8"; }

.fa-toilet-paper:before {
    content: "\f71e"; }

.fa-toilet-paper-slash:before {
    content: "\e072"; }

.fa-toolbox:before {
    content: "\f552"; }

.fa-tools:before {
    content: "\f7d9"; }

.fa-tooth:before {
    content: "\f5c9"; }

.fa-torah:before {
    content: "\f6a0"; }

.fa-torii-gate:before {
    content: "\f6a1"; }

.fa-tractor:before {
    content: "\f722"; }

.fa-trade-federation:before {
    content: "\f513"; }

.fa-trademark:before {
    content: "\f25c"; }

.fa-traffic-light:before {
    content: "\f637"; }

.fa-trailer:before {
    content: "\e041"; }

.fa-train:before {
    content: "\f238"; }

.fa-tram:before {
    content: "\f7da"; }

.fa-transgender:before {
    content: "\f224"; }

.fa-transgender-alt:before {
    content: "\f225"; }

.fa-trash:before {
    content: "\f1f8"; }

.fa-trash-alt:before {
    content: "\f2ed"; }

.fa-trash-restore:before {
    content: "\f829"; }

.fa-trash-restore-alt:before {
    content: "\f82a"; }

.fa-tree:before {
    content: "\f1bb"; }

.fa-trello:before {
    content: "\f181"; }

.fa-tripadvisor:before {
    content: "\f262"; }

.fa-trophy:before {
    content: "\f091"; }

.fa-truck:before {
    content: "\f0d1"; }

.fa-truck-loading:before {
    content: "\f4de"; }

.fa-truck-monster:before {
    content: "\f63b"; }

.fa-truck-moving:before {
    content: "\f4df"; }

.fa-truck-pickup:before {
    content: "\f63c"; }

.fa-tshirt:before {
    content: "\f553"; }

.fa-tty:before {
    content: "\f1e4"; }

.fa-tumblr:before {
    content: "\f173"; }

.fa-tumblr-square:before {
    content: "\f174"; }

.fa-tv:before {
    content: "\f26c"; }

.fa-twitch:before {
    content: "\f1e8"; }

.fa-twitter:before {
    content: "\f099"; }

.fa-twitter-square:before {
    content: "\f081"; }

.fa-typo3:before {
    content: "\f42b"; }

.fa-uber:before {
    content: "\f402"; }

.fa-ubuntu:before {
    content: "\f7df"; }

.fa-uikit:before {
    content: "\f403"; }

.fa-umbraco:before {
    content: "\f8e8"; }

.fa-umbrella:before {
    content: "\f0e9"; }

.fa-umbrella-beach:before {
    content: "\f5ca"; }

.fa-underline:before {
    content: "\f0cd"; }

.fa-undo:before {
    content: "\f0e2"; }

.fa-undo-alt:before {
    content: "\f2ea"; }

.fa-uniregistry:before {
    content: "\f404"; }

.fa-unity:before {
    content: "\e049"; }

.fa-universal-access:before {
    content: "\f29a"; }

.fa-university:before {
    content: "\f19c"; }

.fa-unlink:before {
    content: "\f127"; }

.fa-unlock:before {
    content: "\f09c"; }

.fa-unlock-alt:before {
    content: "\f13e"; }

.fa-unsplash:before {
    content: "\e07c"; }

.fa-untappd:before {
    content: "\f405"; }

.fa-upload:before {
    content: "\f093"; }

.fa-ups:before {
    content: "\f7e0"; }

.fa-usb:before {
    content: "\f287"; }

.fa-user:before {
    content: "\f007"; }

.fa-user-alt:before {
    content: "\f406"; }

.fa-user-alt-slash:before {
    content: "\f4fa"; }

.fa-user-astronaut:before {
    content: "\f4fb"; }

.fa-user-check:before {
    content: "\f4fc"; }

.fa-user-circle:before {
    content: "\f2bd"; }

.fa-user-clock:before {
    content: "\f4fd"; }

.fa-user-cog:before {
    content: "\f4fe"; }

.fa-user-edit:before {
    content: "\f4ff"; }

.fa-user-friends:before {
    content: "\f500"; }

.fa-user-graduate:before {
    content: "\f501"; }

.fa-user-injured:before {
    content: "\f728"; }

.fa-user-lock:before {
    content: "\f502"; }

.fa-user-md:before {
    content: "\f0f0"; }

.fa-user-minus:before {
    content: "\f503"; }

.fa-user-ninja:before {
    content: "\f504"; }

.fa-user-nurse:before {
    content: "\f82f"; }

.fa-user-plus:before {
    content: "\f234"; }

.fa-user-secret:before {
    content: "\f21b"; }

.fa-user-shield:before {
    content: "\f505"; }

.fa-user-slash:before {
    content: "\f506"; }

.fa-user-tag:before {
    content: "\f507"; }

.fa-user-tie:before {
    content: "\f508"; }

.fa-user-times:before {
    content: "\f235"; }

.fa-users:before {
    content: "\f0c0"; }

.fa-users-cog:before {
    content: "\f509"; }

.fa-users-slash:before {
    content: "\e073"; }

.fa-usps:before {
    content: "\f7e1"; }

.fa-ussunnah:before {
    content: "\f407"; }

.fa-utensil-spoon:before {
    content: "\f2e5"; }

.fa-utensils:before {
    content: "\f2e7"; }

.fa-vaadin:before {
    content: "\f408"; }

.fa-vector-square:before {
    content: "\f5cb"; }

.fa-venus:before {
    content: "\f221"; }

.fa-venus-double:before {
    content: "\f226"; }

.fa-venus-mars:before {
    content: "\f228"; }

.fa-viacoin:before {
    content: "\f237"; }

.fa-viadeo:before {
    content: "\f2a9"; }

.fa-viadeo-square:before {
    content: "\f2aa"; }

.fa-vial:before {
    content: "\f492"; }

.fa-vials:before {
    content: "\f493"; }

.fa-viber:before {
    content: "\f409"; }

.fa-video:before {
    content: "\f03d"; }

.fa-video-slash:before {
    content: "\f4e2"; }

.fa-vihara:before {
    content: "\f6a7"; }

.fa-vimeo:before {
    content: "\f40a"; }

.fa-vimeo-square:before {
    content: "\f194"; }

.fa-vimeo-v:before {
    content: "\f27d"; }

.fa-vine:before {
    content: "\f1ca"; }

.fa-virus:before {
    content: "\e074"; }

.fa-virus-slash:before {
    content: "\e075"; }

.fa-viruses:before {
    content: "\e076"; }

.fa-vk:before {
    content: "\f189"; }

.fa-vnv:before {
    content: "\f40b"; }

.fa-voicemail:before {
    content: "\f897"; }

.fa-volleyball-ball:before {
    content: "\f45f"; }

.fa-volume-down:before {
    content: "\f027"; }

.fa-volume-mute:before {
    content: "\f6a9"; }

.fa-volume-off:before {
    content: "\f026"; }

.fa-volume-up:before {
    content: "\f028"; }

.fa-vote-yea:before {
    content: "\f772"; }

.fa-vr-cardboard:before {
    content: "\f729"; }

.fa-vuejs:before {
    content: "\f41f"; }

.fa-walking:before {
    content: "\f554"; }

.fa-wallet:before {
    content: "\f555"; }

.fa-warehouse:before {
    content: "\f494"; }

.fa-water:before {
    content: "\f773"; }

.fa-wave-square:before {
    content: "\f83e"; }

.fa-waze:before {
    content: "\f83f"; }

.fa-weebly:before {
    content: "\f5cc"; }

.fa-weibo:before {
    content: "\f18a"; }

.fa-weight:before {
    content: "\f496"; }

.fa-weight-hanging:before {
    content: "\f5cd"; }

.fa-weixin:before {
    content: "\f1d7"; }

.fa-whatsapp:before {
    content: "\f232"; }

.fa-whatsapp-square:before {
    content: "\f40c"; }

.fa-wheelchair:before {
    content: "\f193"; }

.fa-whmcs:before {
    content: "\f40d"; }

.fa-wifi:before {
    content: "\f1eb"; }

.fa-wikipedia-w:before {
    content: "\f266"; }

.fa-wind:before {
    content: "\f72e"; }

.fa-window-close:before {
    content: "\f410"; }

.fa-window-maximize:before {
    content: "\f2d0"; }

.fa-window-minimize:before {
    content: "\f2d1"; }

.fa-window-restore:before {
    content: "\f2d2"; }

.fa-windows:before {
    content: "\f17a"; }

.fa-wine-bottle:before {
    content: "\f72f"; }

.fa-wine-glass:before {
    content: "\f4e3"; }

.fa-wine-glass-alt:before {
    content: "\f5ce"; }

.fa-wix:before {
    content: "\f5cf"; }

.fa-wizards-of-the-coast:before {
    content: "\f730"; }

.fa-wolf-pack-battalion:before {
    content: "\f514"; }

.fa-won-sign:before {
    content: "\f159"; }

.fa-wordpress:before {
    content: "\f19a"; }

.fa-wordpress-simple:before {
    content: "\f411"; }

.fa-wpbeginner:before {
    content: "\f297"; }

.fa-wpexplorer:before {
    content: "\f2de"; }

.fa-wpforms:before {
    content: "\f298"; }

.fa-wpressr:before {
    content: "\f3e4"; }

.fa-wrench:before {
    content: "\f0ad"; }

.fa-x-ray:before {
    content: "\f497"; }

.fa-xbox:before {
    content: "\f412"; }

.fa-xing:before {
    content: "\f168"; }

.fa-xing-square:before {
    content: "\f169"; }

.fa-y-combinator:before {
    content: "\f23b"; }

.fa-yahoo:before {
    content: "\f19e"; }

.fa-yammer:before {
    content: "\f840"; }

.fa-yandex:before {
    content: "\f413"; }

.fa-yandex-international:before {
    content: "\f414"; }

.fa-yarn:before {
    content: "\f7e3"; }

.fa-yelp:before {
    content: "\f1e9"; }

.fa-yen-sign:before {
    content: "\f157"; }

.fa-yin-yang:before {
    content: "\f6ad"; }

.fa-yoast:before {
    content: "\f2b1"; }

.fa-youtube:before {
    content: "\f167"; }

.fa-youtube-square:before {
    content: "\f431"; }

.fa-zhihu:before {
    content: "\f63f"; }

.sr-only {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px; }

.sr-only-focusable:active, .sr-only-focusable:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto; }
@font-face {
    font-family: 'Font Awesome 5 Brands';
    font-style: normal;
    font-weight: 400;
    font-display: block;
    src: url("../fonts/fa-brands-400.eot");
    src: url("../fonts/fa-brands-400.eot?#iefix") format("embedded-opentype"), url("../fonts/fa-brands-400.woff2") format("woff2"), url("../fonts/fa-brands-400.woff") format("woff"), url("../fonts/fa-brands-400.ttf") format("truetype"), url("../fonts/fa-brands-400.svg#fontawesome") format("svg"); }

.fab {
    font-family: 'Font Awesome 5 Brands';
    font-weight: 400; }
@font-face {
    font-family: 'Font Awesome 5 Free';
    font-style: normal;
    font-weight: 400;
    font-display: block;
    src: url("../fonts/fa-regular-400.eot");
    src: url("../fonts/fa-regular-400.eot?#iefix") format("embedded-opentype"), url("../fonts/fa-regular-400.woff2") format("woff2"), url("../fonts/fa-regular-400.woff") format("woff"), url("../fonts/fa-regular-400.ttf") format("truetype"), url("../fonts/fa-regular-400.svg#fontawesome") format("svg"); }

.far {
    font-family: 'Font Awesome 5 Free';
    font-weight: 400; }
@font-face {
    font-family: 'Font Awesome 5 Free';
    font-style: normal;
    font-weight: 900;
    font-display: block;
    src: url("../fonts/fa-solid-900.eot");
    src: url("../fonts/fa-solid-900.eot?#iefix") format("embedded-opentype"), url("../fonts/fa-solid-900.woff2") format("woff2"), url("../fonts/fa-solid-900.woff") format("woff"), url("../fonts/fa-solid-900.ttf") format("truetype"), url("../fonts/fa-solid-900.svg#fontawesome") format("svg"); }

.fa,
.fas {
    font-family: 'Font Awesome 5 Free';
    font-weight: 900; }

$main-color:  #1a77b9;
html, body {
	margin: 0;
	padding: 0;

	/*Avoid flicker on slides transitions for mobile phones #336 */
	-webkit-tap-highlight-color: rgba(0,0,0,0); }

.pp-section {
	height: 100%;
	position: absolute;
	width: 100%;

	/* fixes flickering in firefox*/
	backface-visibility: hidden; }

.pp-easing {
	-webkit-transition: all 1000ms cubic-bezier(0.550, 0.085, 0.000, 0.990);
	-moz-transition: all 1000ms cubic-bezier(0.550, 0.085, 0.000, 0.990);
	-o-transition: all 1000ms cubic-bezier(0.550, 0.085, 0.000, 0.990);
	transition: all 1000ms cubic-bezier(0.550, 0.085, 0.000, 0.990);
	/* custom */
	-webkit-transition-timing-function: cubic-bezier(0.550, 0.085, 0.000, 0.990);
	-moz-transition-timing-function: cubic-bezier(0.550, 0.085, 0.000, 0.990);
	-o-transition-timing-function: cubic-bezier(0.550, 0.085, 0.000, 0.990);
	transition-timing-function: cubic-bezier(0.550, 0.085, 0.000, 0.990);
	/* custom */ }

#pp-nav {
	position: fixed;
	z-index: 100;
	margin-top: -32px;
	top: 50%;
	opacity: 1; }

#pp-nav.right {
	right: 17px; }

#pp-nav.left {
	left: 17px; }

.pp-section.pp-table {
	display: table; }

.pp-tableCell {
	display: table-cell;
	vertical-align: middle;
	width: 100%;
	height: 100%; }

.pp-slidesNav {
	position: absolute;
	z-index: 4;
	left: 50%;
	opacity: 1; }

.pp-slidesNav.bottom {
	bottom: 17px; }

.pp-slidesNav.top {
	top: 17px; }

#pp-nav ul,
.pp-slidesNav ul {
  margin: 0;
  padding: 0; }

#pp-nav li,
.pp-slidesNav li {
	display: block;
	width: 14px;
	height: 13px;
	margin: 7px;
	position: relative; }

.pp-slidesNav li {
	display: inline-block; }

#pp-nav li a,
.pp-slidesNav li a {
	display: block;
	position: relative;
	z-index: 1;
	width: 100%;
	height: 100%;
	cursor: pointer;
	text-decoration: none; }

#pp-nav li .active span,
.pp-slidesNav .active span {
	background: #333; }

#pp-nav span,
.pp-slidesNav span {
	top: 2px;
	left: 2px;
	width: 8px;
	height: 8px;
	border: 1px solid #000;
	background: rgba(0, 0, 0, 0);
	border-radius: 50%;
	position: absolute;
	z-index: 1; }

.pp-tooltip {
	position: absolute;
	top: -2px;
	color: #fff;
	font-size: 14px;
	font-family: arial, helvetica, sans-serif;
	white-space: nowrap;
	max-width: 220px; }

.pp-tooltip.right {
	right: 20px; }

.pp-tooltip.left {
	left: 20px; }

.pp-scrollable {
	overflow-y: scroll;
	height: 100%; }


[hidden],
.d-n,
.hidden {
	display: none !important; }

.dekstop {
	@media (max-width: 1025px) {
		display: none !important; } }

.mobile {
	display: flex;
	align-items: center;

	@media (min-width: 1024.98px) {
		display: none !important; } }

.d-b {
	display: block; }

.d-ib {
	display: inline-block; }

.d-f {
	display: flex; }

.d-if {
	display: inline-flex; }

.fw-w {
	flex-wrap: wrap; }

.ai-c {
	align-items: center; }

.ai-fe {
	align-items: flex-end; }

.as-c {
	align-self: center; }

.as-fe {
	align-self: flex-end; }

.jc-c {
	justify-content: center; }

.jc-fe {
	justify-content: flex-end; }

.jc-sb {
	justify-content: space-between; }

.fcc {
	display: flex;
	justify-content: center;
	align-items: center; }

// List, ul, ol, dl
.lst-n {
	list-style-type: none; }

// Margin
.ml-at {
	margin-left: auto; }

.mr-at {
	margin-right: auto; }

.mx-at {
	margin-right: auto;
	margin-left: auto; }

.ov-h {
	overflow: hidden; }

.fs-i {
	font-style: italic; }

.tt-u {
	text-transform: uppercase; }

.tt-c {
	text-transform: capitalize; }

.ta-c {
	text-align: center; }

.td-u {
	text-decoration: underline; }

.pt-10 {
	padding-top: 10px; }

.color-while {
	color: #fff !important; }

.bg-light {
	background-color: #fff !important; }

.bg-gray {
	background-color: #f5f5f5; }

button {
	background-color: transparent;
	&:focus {
		outline: none;
		box-shadow: none; } }


.btn {
	border: 0;
	padding: 0;
	&:focus {
		outline: none;
		box-shadow: none; }

	&__new {
		font-size: 14px;
		font-family: "Montserrat";
		color: rgb(255, 255, 255);
		font-weight: bold;
		text-decoration: underline;
		text-transform: uppercase;
		line-height: 2;
		text-align: left;
		transition: .3s all ease-in-out;
		// &:hover
		// 	text-decoration: underline $main-color
		//
		span {
			transform: skewX(20deg);
			.ri-arrow-right-line {
				font-size: 14px;
				line-height: 20px;
				padding-left: 10px; } }


		@media(max-width: 991.98px) {
			width: 120px;
			font-size: 14px; } }


	&__new2 {
		width: 190px;
		height: 40px;
		border-radius: 20px;
		background-color: #0b3155;
		line-height: 40px;
		text-align: center;
		color: #fff;
		font-size: 15px;
		font-family: "Montserrat";
		&:hover {
			background-color: #3361ae;
			color: #fff; } }
	&-detail {
		width: 190px;
		height: 50px;
		border-radius: 25px;
		text-transform: uppercase;
		color: #fff;
		font-size: 14px;
		font-weight: 500;
		font-family: "Montserrat";
		display: flex;
		align-items: center;
		justify-content: center;
		.icon {
			position: relative;
			width: 17px;
			height: 17px;
			margin-left: 10px;
			&::before {
				width: 100%;
				height: 100%;
				position: absolute;
				content: url('../assets/icons/plus.png');
				top: 0;
				right: 0; } }
		&:hover {
			transition: all .3s ease-in-out;
			.icon {
				&::before {
					content: url('../assets/icons/plus-hover.png'); } } }

		@media screen and ( max-width: 1440px) {
			width: 160px;
			height: 40px; } } }







@media(min-width: 1025px) {
	@mixin transition($time, $property: all, $easing: ease-in) {
		transition: $property $time $easing; }
	$parallax-offset: 0;
	$content-offset: 30vh;
	$transition-speed: 2s;
	$slide-number: 9;
	.full-page {
		overflow: hidden;
		section {
			padding: 0 0;
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center center;
			overflow: hidden;
			will-change: transform;
			backface-visibility: hidden;
			// height: 100vh
			height: 100vh + $parallax-offset;
			position: fixed;
			width: 100%;
			transform: translateY(100vh);
			@include transition($transition-speed, all, cubic-bezier(0.22, 0.44, 0, 1));
			&:first-child {
				opacity: 1;
				transform: translateY(-$parallax-offset / 2); } } }
	@for $i from 1 to ($slide-number + 1) {
		.full-page section:nth-child(#{$i}) {
			// z-index: ($slide-number + 1) - $i
			z-index: ($slide-number + 1) - $i;
			z-index: $i; } }
	section.up-scroll {
		transform: translate3d(0,-$parallax-offset / 2,0);
		+ section {
			transform: translate3d(0,(100vh + $parallax-offset),0); } }
	section.down-scroll {
		transform: translate3d(0,-$parallax-offset,0);
		+ section {
			transform: translate3d(0,-$parallax-offset / 2,0); }
		// &:last-child
 } }		// 	transform: translate3d(0,calc(-30vh - 780px) ,0)

#nav-pagination {
	position: fixed;
	top: 50%;
	right: 0;
	padding-right: 30px;
	transform: translateY(-50%);
	z-index: 999;
	@media(max-width: 1025px) {
		display: none; }
	ul {
		list-style-type: none;
		padding-left: 0;
		li {
			margin: 30px 0;
			width: 7px;
			height: 7px;
			background: #fff;
			cursor: pointer;
			border-radius: 50%;
			font-size: 12px;
			font-size: 0;
			display: flex;
			justify-content: center;
			transition: .4s cubic-bezier(0.22, 0.44, 0, 1) all;
			align-items: center;
			color: #ffffff;
			&.active, &:hover {
				color: #fff;
				transform: scale(1.8); }
			&.active {

				background: #ffffff; } } } }
@-webkit-keyframes wheel {
	0% {}
	opacity: 0;
	transform: scale(.3);
	top: 0;
	50% {}
	opacity: 1;
	transform: scale(1);
	100% {}
	opacity: 0;
	transform: scale(.3);
	top: 20px; }

.wheel {
	width: 30px;
	height: 30px;
	// right: 32px
	position: absolute;
	bottom: 100px;
	right: 20px;
	opacity: 1;
	transition: opacity .3s ease-in-out;
	z-index: 5;
	span {
		position: absolute;
		width: 100%;
		height: 2px;
		opacity: 0;
		transform: scale(.3);
		&:first-child {
			animation: wheel 2s ease-in-out .5s infinite; }
		&:nth-child(2) {
			animation: wheel 2s ease-in-out 1s infinite; }
		&:nth-child(3) {
			animation: wheel 2s ease-in-out infinite; }
		&:before, &:after {
			content: '';
			position: absolute;
			top: 0;
			height: 100%;
			width: 50%;
			background-color: #fff; }
		&::before {
			left: 0;
			transform: skewY(30deg); }
		&::after {
			right: 0;
			width: 50%;
			transform: skewY(-30deg); } } }
#fake-header {
	position: fixed;
	top: 0;
	transition: .1s ease-in-out all;
	opacity: 0;
	pointer-events: none;
	left: 0;
	height: 100vh;
	z-index: 9999;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	// background: url(/Data/Sites/1/media/bg-menu.png)
	// background: url(../img/index-about/bg-building.png)
	// background-image: $gradient-2
	background-size: cover;
	background-position: center center;
	ul {
		list-style: none;
		padding-left: 0;
		text-align: center;
		li {
			opacity: 0;
			transition: 2s all cubic-bezier(0.22, 0.44, 0, 1);
			transform: translateX(300px); }
		a {
			transition: 2s ease-in-out all;
			text-transform: uppercase;
			padding: 10px;
			display: inline-block;
			font-size: 24px;
			color: #ffffff;
			opacity: 0;
			&:hover {
				text-decoration: underline; } }
		&.nav-bar {
			.nav-item {
				&.dropdown {
					.dropdown-menu {
						display: none;
						position: relative;
						top: 0;
						float: none;
						background: none;
						overflow: hidden;
						border: none;
						margin: 0;
						padding: 0;
						.dropdown-item {
							background: none;
							&:hover {
								background: none; } } } }
				&:nth-child(3) {
					.nav-link {
						position: relative;
						&::after {
							padding-left: 15px;
							position: absolute;
							content: '\e874';
							font-size: 24px;
							color: #ffffff;
							font-family: 'Linearicons-Free'; }
						&.active {
							&::after {
								transform: rotate(180deg);
								transition: all ease-in-out .3s; } } } } } } }
	.main-menu {
		.register-news {
			padding: 10px;
			color: #ffffff;
			display: flex;
			align-items: center;
			justify-content: center;
			opacity: 0;
			transition: 2s all cubic-bezier(0.22, 0.44, 0, 1);
			transform: translateX(300px);
			em {
				padding-right: 5px; } } }
	.social {
		display: flex;
		align-items: center;
		justify-content: center;
		li {
			margin-right: 5px;
			cursor: pointer; } }
	.close-button {
		color: #ffffff;
		display: flex;
		align-items: center;
		position: absolute;
		transform: rotate(90deg);
		top: 50px;
		cursor: pointer;
		right: 20px;
		transition: .4s cubic-bezier(0.22, 0.44, 0, 1) all;
		em {
			font-size: 36px;
			margin-right: 10px; }
		span {
			text-transform: uppercase; }
		&:hover {
			color: #ffffff; } }
	.logo-fake-header {
		position: absolute;
		top: 15vh;
		z-index: 11;
		opacity: 0;
		transition: 2s all cubic-bezier(0.22, 0.44, 0, 1);
		transform: translateY(300px); }
	.menu-fake-header {
		ul {
			.item {
				a {}
				span {
					display: none; } } } }
	.menu-fake-header {
		.Module-141 {
			display: flex;
			justify-content: center;
			align-items: center;
			opacity: 0;
			transition: 2s all cubic-bezier(0.22, 0.44, 0, 1);
			transform: translateY(300px);
			@media(min-width: 1025px) {
				display: none; } } }
	@media(min-width: 1025px) {
		.logo-fake-header {
			position: initial;
			top: initial;
			z-index: 21;
			// img
 }			//     height: 121px
		.menu-fake-header {
			position: absolute;
			z-index: 11;
			width: 100%;
			justify-content: flex-end;
			ul {
				// text-align: right
				// padding-right: 50px
				.item {
					position: relative;
					display: flex;
					align-items: center;
					justify-content: flex-end;
					a {
						position: relative; }
					.number {
						font-size: 18px;
						font-weight: 300;
						display: block;
						padding-left: 25px;
						color: rgba(#ffffff,0.5); }
					&.active, &:hover {
						a {
							text-decoration: underline; }
						.number {
							color: rgba(#ffffff,1); } } } } } }
	// @media(min-width: 1200px)
	// 	.menu-fake-header
	// 		ul
	//	// padding-right: 70px
	&.active {
		opacity: 1;
		pointer-events: initial;
		.logo-fake-header {
			opacity: 1;
			transform: translateX(0); }
		ul {
			li {
				opacity: 1;
				transform: translateX(0);
				&:nth-child(2) {
					transition-delay: .2s; }
				&:nth-child(3) {
					transition-delay: .3s; }
				&:nth-child(4) {
					transition-delay: .4s; }
				&:nth-child(5) {
					transition-delay: .5s; }
				&:nth-child(6) {
					transition-delay: .6s; }
				&:nth-child(7) {
					transition-delay: .7s; }
				&:nth-child(8) {
					transition-delay: .8s; }
				&:nth-child(9) {
					transition-delay: .9s; } }
			a {
				opacity: 1; } }
		.menu-fake-header {
			.Module-141 {
				opacity: 1;
				transform: translateX(0);
 } }				// transition-delay: 1s
		.main-menu {
			.register-news {
				opacity: 1;
				transform: translateX(0); } } } }
@keyframes fadein {
	from {}
	opacity: 0;

	to {}
	opacity: 1; }

/* Firefox < 16 */
@-moz-keyframes fadein {
	from {}
	opacity: 0;

	to {}
	opacity: 1; }

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
	from {}
	opacity: 0;

	to {}
	opacity: 1; }

/* Internet Explorer */
@-ms-keyframes fadein {
	from {}
	opacity: 0;

	to {}
	opacity: 1; }

/* Opera < 12.1 */
@-o-keyframes fadein {
	from {}
	opacity: 0;

	to {}
	opacity: 1; }
#fake-header-open {
	position: absolute;
	transition: 2s cubic-bezier(0.22, 0.44, 0, 1) all;
	transform: translateY(-150px) rotate(90deg);
	right: 5px;
	top: 80px;
	z-index: 9998;
	text-transform: uppercase;
	// display: none
	&.active {
		transform: translateY(0) rotate(90deg); }
	.open-button {
		user-select: none;
		cursor: pointer;
		font-weight: 600;
		font-size: 14px;
		padding: 5px 12px;
		background: #c7931c;
		color: #fff;

		position: relative;
		&:after {
			content: '';
			height: calc(100% + 8px);
			width: calc(100% + 8px);
			position: absolute;
			border: 1px solid #c7931c;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%); }
		&::before {
			content: '';
			height: 1px;
			width: 60px;
			right: calc(100% + 20px);
			top: 50%;
			position: absolute;
			transform: translateY(-50%)  rotate(0);
			border-top: 1px solid #c7931c; } }
	// &.last
	// 	.open-button
	// 		background:#c7931c
	// 		color: #fff
	// 		&:after
	// 			border-color:#c7931c
	// 		&:before
 }	// 			border-color:#c7931c
@media (max-width: 1025px) {
	#fake-header-open {
		position: fixed;
		// transform: translateY(-150px) rotate(90deg)
		// display: none
		right: 0;
		.open-button {
			background: #c7931c;
			color: #fff;
			&:after {
				border-color: #c7931c; }
			&:before {
				border-color: #c7931c; } } }
	#fake-header {
		.close-button {
			right: -10px;
			top: 25px; }
		ul {
			a {
				font-size: 16px; } } } }
.full-page {
	main {
		padding-top: 0; }
	section {
		display: flex;
		align-items: center;
		@media(min-width: 1025px) {
			.animation-left {
				opacity: 0;
				transition: 2s all cubic-bezier(0.22, 0.44, 0, 1);
				transform: translateX(-300px); }
			.animation-right {
				opacity: 0;
				transition: 2s all cubic-bezier(0.22, 0.44, 0, 1);
				transform: translateX(300px); }
			.animation-bottom {
				opacity: 0;
				transition: 2s all cubic-bezier(0.22, 0.44, 0, 1);
				transform: translateY(300px); }
			.animation-top {
				opacity: 0;
				transition: 2s all cubic-bezier(0.22, 0.44, 0, 1);
				transform: translateY(-300px); }
			&.current-page {
				.animation-left {
					opacity: 1;
					transform: translateX(0); }
				.animation-right {
					opacity: 1;
					transform: translateX(0); }
				.animation-bottom {
					opacity: 1;
					transform: translateY(0); }
				.animation-top {
					opacity: 1;
					transform: translateY(0); } }
			> div {
				display: flex;
				flex-direction: column;
				justify-content: center;
				// padding: 50px 0
				> .Module {
					width: 100%;
					height: 100%; } } }
		&.about_home {
			.ModuleContent {
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				> .container {
					padding-top: 3vh;
					flex: 1;
					.row {
						height: 100%;
						align-content: center; } } } }
		&.project_city {
			.swiper-container {
				.swiper-wrapper .swiper-slide {
					.img {
						width: 100%;
						height: 100vh;
						img {
							height: 100%;
							object-fit: cover; } } } } }
		&.brand-display {
			.ModuleContent {
				display: flex;
				justify-content: center;
				align-items: center; } }
		&.video {
			.figure-media .figure-image .wrapper {
				height: 100vh;
				a {
					padding-top: 100%;
					img {
						height: 100vh; } } } } }
	@media screen and ( max-width: 1024.98px ) {
		section {
			> div {
				height: auto;
				> div {
					height: auto; } }
			&.about_home {
				.ModuleContent {
					> .container {
						padding-top: 0; } } }
			&.project_city {
				.swiper-container {
					.swiper-wrapper .swiper-slide {
						.img {
							width: 100%;
							height: auto;
							padding-top: 56.25%;
							position: relative;
							img {
								position: absolute;
								top: 0;
								left: 0;
								width: 100%; } } } } }
			&.video {
				.figure-media .figure-image .wrapper {
					height: auto;
					a {
						background: #000;
						img {
							opacity: 0.8;
							height: 100%;
							width: 100%;
							object-fit: cover; } } } } } }
	@media (min-width: 1025px) {
		header {
			transition: all 1.2s ease-in-out;
			right: auto;
			left: 50%;
			transform: translateX(-50%);
			&.active {
				transform: translateY(-100%) translateX(-50%); } } } }

*,
*::before,
*::after {
	box-sizing: border-box !important; }
@import url('https://fonts.googleapis.com/css2?family=Sarabun:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
html {
	-webkit-text-size-adjust: 100%;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	scroll-behavior: smooth; }

body {
	margin: 0;
	font-family: 'Montserrat', sans-serif;
	font-size: 16px;
	font-weight: 400;
	line-height: 1.5;
	color: #666;
	background-color: #fff; }

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
	display: block; }

[tabindex="-1"]:focus {
	outline: 0 !important; }

hr {
	box-sizing: content-box;
	height: 0;
	overflow: visible; }

h1,
h2,
h3,
h4,
h5,
h6 {
	margin-top: 0;
	margin-bottom: 0; }

a:not([href]) {
	color: #fff;
	text-decoration: none; }

p {
	margin-top: 0;
	margin-bottom: 0; }

abbr[title],
abbr[data-original-title] {
	text-decoration: underline;
	-webkit-text-decoration: underline dotted;
	text-decoration: underline dotted;
	cursor: help;
	border-bottom: 0;
	-webkit-text-decoration-skip-ink: none;
	text-decoration-skip-ink: none; }

address {
	margin-bottom: 0;
	font-style: normal;
	line-height: inherit; }

ol,
ul,
dl {
	margin-top: 0;
	margin-bottom: 0; }

ol ol,
ul ul,
ol ul,
ul ol {
	margin-bottom: 0; }

dt {
	font-weight: 700; }

dd {
	margin-bottom: 0;
	margin-left: 0; }

blockquote {
	margin: 0; }

b,
strong {
	font-weight: bolder;
	font-weight: 700; }

small {
	font-size: 80%; }

sub,
sup {
	position: relative;
	font-size: 75%;
	line-height: 0;
	vertical-align: baseline; }

sub {
	bottom: -0.25em; }

sup {
	top: -0.5em; }

a {
	color: #1C1C1C;
	text-decoration: none;
	background-color: transparent;

	&:hover {
		text-decoration: none; } }

pre,
code,
kbd,
samp {
	font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
	font-size: 1em; }

pre {
	margin-top: 0;
	margin-bottom: 0;
	overflow: auto; }

figure {
	margin: 0; }

img {
	vertical-align: middle;
	border-style: none;
	max-width: 100%; }

svg {
	overflow: hidden;
	vertical-align: middle; }

table {
	border-collapse: collapse; }

caption {
	padding-top: 0;
	padding-bottom: 0;
	color: #6c757d;
	text-align: left;
	caption-side: bottom; }

th {
	text-align: inherit; }

label {
	display: inline-block;
	margin-bottom: 0; }

button {
	border-radius: 0; }

input,
button,
select,
optgroup,
textarea {
	margin: 0;
	font-family: inherit;
	font-size: inherit;
	line-height: inherit; }

button,
input {
	overflow: visible; }

button,
select {
	text-transform: none; }

select {
	word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
	-webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
	cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
	padding: 0;
	border-style: none; }

input[type="radio"],
input[type="checkbox"] {
	box-sizing: border-box;
	padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
	-webkit-appearance: listbox; }

textarea {
	overflow: auto;
	resize: vertical; }

fieldset {
	min-width: 0;
	padding: 0;
	margin: 0;
	border: 0; }

legend {
	display: block;
	width: 100%;
	max-width: 100%;
	padding: 0;
	margin-bottom: 0;
	font-size: 1.5rem;
	line-height: inherit;
	color: inherit;
	white-space: normal; }

progress {
	vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
	height: auto; }

[type="search"] {
	outline-offset: -2px;
	-webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
	-webkit-appearance: none; }

 ::-webkit-file-upload-button {
	font: inherit;
	-webkit-appearance: button; }

output {
	display: inline-block; }

summary {
	display: list-item;
	cursor: pointer; }

template {
	display: none; }

*:focus,
*:active {
	outline: none; }

// ::-webkit-scrollbar-track
// 		box-shadow      : inset 0 0 6px rgba(255, 255, 255, 0)
// 		background-color: rgb(29, 175, 240)
//

// 	::-webkit-scrollbar
// 		width           : 5px
// 		height          : 5px
// 		background-color: rgb(29, 175, 240)
//

// 	::-webkit-scrollbar-thumb
// 		border-radius   : 2.5px
// 		box-shadow      : inset 0 0 6px rgba(255, 255, 255, 0)
// 		background-color: #fff
//
body {
	&.show-page {
		opacity: 1;
		transition: all ease-in-out .3s; }
	&.disabled {
		overflow: hidden; } }
#overlay {
	opacity: 0;
	visibility: hidden;
	pointer-events: none;

	background-color: rgba(0, 0, 0, .8);
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 150;
	transition: .3s all ease-in-out;

	&.active {
		opacity: 1;
		visibility: visible;
		pointer-events: visible; } }

.ovh {
	overflow: hidden; }
.section {
	margin-left: auto;
	padding: 30px 0;

	@media (min-width: 768px) {
		padding: 35px 0; }


	@media (min-width: 1025px) {
		padding: 40px 0; }


	@media (min-width: 1200px) {
		padding: 50px 0; }


	@media (min-width: 1438px) {
		padding: 80px 0; } }
.section-child {
	margin-left: auto;
	padding: 20px 0;

	@media (min-width: 600px) {
		padding: 30px 0; }


	@media (min-width: 768px) {
		padding: 35px 0; }


	@media (min-width: 1025px) {
		padding: 40px 0; }


	@media (min-width: 1200px) {
		padding: 50px 0; }


	@media (min-width: 1440px) {
		padding: 66px 0; } }
.main-title {
	background-image: url('../assets/images/index/title-bg.png');
	width: 330px;
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	height: 65px;
	text-align: center;
	h1 {
		font-size: 32px;
		font-family: "Montserrat";
		color: rgb(255, 255, 255);
		font-weight: bold;
		text-transform: uppercase;
		line-height: 65px; }
	@media(max-width: 1440px) {
		width: 232px;
		height: 45px;
		h1 {
			font-size: 20px;
			line-height: 45px; } } }
.block-title {
	position: relative;
	h2 {
		font-size: 30px;
		font-family: "Montserrat";
		color: rgb(51, 51, 51);
		font-weight: bold;
		text-align: left;
		padding-left: 23px;
		@media screen and ( max-width: 1440px) {
			font-size: 20px; }
		@media screen and ( max-width: 1200px) {
			font-size: 18px; } }
	&::before {
		position: absolute;
		content: '';
		background-color: rgb(26, 119, 185);
		width: 5px;
		height: 40px; }
	@media screen and ( max-width: 1440px) {
		&::before {
			height: 25px; } } }
.pages {
	margin-left: auto;
	width: 100%;

	.modulepager {
		display: flex;
		align-items: center;
		justify-content: center;

		.pagination {
			padding: 0;
			list-style: none;

			li {
				background-color: rgb(255, 255, 255);
				box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.1);
				width: 40px;
				height: 40px;
				margin-right: 20px;
				display: flex;
				align-items: center;
				justify-content: center;
				cursor: pointer;
				transition: ease-in-out all .3s;

				a {
					font-size: 18px;
					color: $main-color;
					text-decoration: none;
					font-family: 'Montserrat'; }


				&:hover {
					background-color: $main-color;
					a {
						color: #fff; } }



				&.active {
					background-color: $main-color;
					span,
					a {
						color: #fff; } } } } } }
.tab-panels {
	ul {
		padding: 0;
		list-style: none;
		display: flex;
		align-items: center;

		li {
			a {
				cursor: pointer;
				display: inline-block;
				font-size: 16px;
				font-family: "Montserrat";
				color: rgb(153, 153, 153);
				text-transform: uppercase;
				text-align: left;
				font-weight: 400; }


			@media(max-width: 991.98px) {
				width: auto; } }



		&.active {
			font-size: 16px;
			font-family: "Montserrat";
			color: rgb(51, 51, 51);
			text-transform: uppercase;
			text-align: left;
			font-weight: 500;
			transition: all .3s ease-in-out;
			position: relative;

			&::after {
				position: absolute;
				content: '';
				width: 100%;
				height: 2px;
				background-color: $main-color;
				left: 0;
				bottom: -1.5px; } } }




	.panel {
		background: #fff;
		padding: 30px;
		display: none;

		.panel__title {
			p {
				font-size: 18px;
				font-family: "Barlow";
				color: rgb(51, 51, 51);
				font-weight: bold; } }



		.panel__slide {
			position: relative;

			.panel-slide {
				max-width: 1020px;
				margin: 40px auto;
				cursor: pointer;

				.panel__img {
					border: solid 1px rgb(234, 234, 234);
					background-color: rgb(255, 255, 255);
					height: 200px;
					display: flex;
					align-items: center;
					text-align: center;
					justify-content: center;
					transition: all .3s ease-in-out;

					&:hover {
						border: solid 1px #0065b3;
						transition: all .3s ease-in-out; } }



				@media(max-width: 991.98px) {
					max-width: 100%;

					.panel__img {
						max-width: 100%; } } } }





		.panel__desc {
			max-width: 1020px;
			margin: 0 auto;
			padding-bottom: 50px;
			text-align: center;

			p {
				font-size: 18px;
				font-weight: 400;
				line-height: 24px;
				font-family: "Barlow";
				color: rgb(51, 51, 51); }


			ul {
				padding: 0;
				list-style: none;

				li {
					width: 100%;
					display: flex;
					margin-bottom: 16px;
					padding: 0;

					p {
						font-size: 16px;
						color: rgb(51, 51, 51);
						font-weight: 600; }


					span {
						font-size: 16px;
						color: rgb(51, 51, 51); } } } }





		.panel__list {
			max-width: 1020px;
			margin: 0 auto;
			padding: 0 10px;

			.item {
				border: solid 1px rgb(234, 234, 234);
				width: 256px;
				height: 255px;
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 0;

				@media screen and (max-width: 1025px) {
					width: 100%; } }



			@media screen and (max-width: 1025px) {
				max-width: 100%; } }



		@media(max-width: 991.98px) {
			padding: 15px;

			.panel__slide {
				.panel-slide {
					max-width: 100%;
					margin: 10px auto; } }



			.panel__desc {
				max-width: 100%;
				text-align: justify;
				padding-bottom: 10px;

				p {
					font-size: 14px; } } }




		&.active {
			display: block; } } }
.acc {
	width: 100%;

	.acc__card {
		position: relative; }


	.acc__title {
		&.active {
			h2 {
				.material-icons {
					transform: rotate(180deg);
					transition: all ease-in-out .3s; } } } }





	.acc__panel {
		display: none;
		margin: 0; } }

.swiper-button-next,
.swiper-button-prev {
	width: 36px;
	height: 36px;
	top: 40%;
	background-image: none;
	display: flex;
	align-items: center; }
.swiper-button-next {
	right: -35px;
	&::before {
		width: 100%;
		height: 100%;
		content: '\EA6E';
		font-family: 'remixicon';
		font-size: 36px;
		color: #ffffff;
		display: flex;
		align-items: center; } }
.swiper-button-prev {
	left: -35px;
	text-align: left;
	&::before {
		width: 100%;
		height: 100%;
		content: "\EA64";
		font-family: 'remixicon';
		font-size: 36px;
		color: #ffffff;
		display: flex;
		align-items: center; } }
#sidebar-wrapper {
	background-color: #eff8ff;
	box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.05);
	width: 220px;
	padding-top: 0;
	z-index: 500;
	.tool-ctrl {
		display: none; }
	.title {
		width: 100%;
		padding: 15px 0;
		background-color: #1a77ba;
		text-align: center;
		h2 {
			text-align: center;
			font-size: 20px;
			color: #ffffff; } }
	ul {
		list-style: none;
		padding: 0;
		padding-bottom: 0;
		li {
			padding: 15px 15px;
			border-bottom: solid 1px $main-color;
			a {
				font-size: 16px;
				font-family: "Montserrat";
				text-align: left;
				color: #333333; }
			&.active {
				a {
					color: #1a77ba; } }
			&:last-child {
				border: none; } } }
	@media screen and ( max-width: 1199px) {
		position: fixed;
		z-index: 99;
		right: 0;
		width: auto;
		margin-bottom: 0;
		transition: all .3s ease-in-out;
		padding: 5px;
		.title {
			display: none; }
		.tool-ctrl {
			width: 35px;
			height: 35px;
			display: block;
			-webkit-animation: rotation 2s infinite linear;
			.material-icons {
				font-size: 35px;
				color:  rgb(26, 119, 185); }
			@-webkit-keyframes rotation {
				from {
					-webkit-transform: rotate(0deg); }
				to {
					-webkit-transform: rotate(359deg); } } }
		ul {
			display: none;
			flex-direction: column;
			padding: 0;
			li {
				margin-left: 0;
				padding: 15px 15px; } } } }

.search-page {
	padding: 60px 0;

	.search-page-top {
		.page-header {
			h1 {
				font-size: 36px;
				font-family: "Montserrat";
				font-weight: 500;
				color: #333333;

				@media(max-width: 1025px) {
					font-size: 26px; } } }




		.searchcontrols {
			padding: 30px 0;

			.form-inline {
				.form-group {
					width: 100%;
					position: relative;
					.form-control {
						border: solid 1px #a1a1a1;
						width: 100%;
						height: 40px;
						border-radius: 0;
						&:focus {
							box-shadow: none; } }

					.seachpage-btn {
						display: none; } } } } }

	.searchresults {
		.modulepager {
			display: flex;
			align-items: center;
			padding-bottom: 30px;
			&:nth-child(1) {
				display: none; }

			.pagination {
				padding: 0;
				list-style: none;

				li {
					background-color: rgb(255, 255, 255);
					width: 38px;
					height: 38px;
					margin-right: 20px;
					display: flex;
					align-items: center;
					justify-content: center;
					cursor: pointer;
					transition: ease-in-out all .3s;

					a {
						font-size: 18px;
						color: $main-color;
						text-decoration: none;
						font-family: 'Montserrat';
						width: 100%;
						height: 100%;
						text-align: center;
						line-height: 38px; }


					&:hover {
						background-color: $main-color;

						a {
							color: #fff; } }



					&.active {
						span {
							color: #fff;
							width: 100%;
							height: 100%;
							text-align: center;
							line-height: 38px; }


						background-color: $main-color; } } } } }






	.searchresultlist {
		.searchresult {
			.NeatHtml {
				h3 {
					font-size: 18px;
					a {
						font-size: 25px;
						font-weight: 500;
						color: #1A0DAB;
						text-decoration: none; } } } } } }
.sticky-wrap {
	position: relative;
	#fake-section {
		pointer-events: none;
		position: absolute;
		padding-top: 60px;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		.container {
			height: 100%;
			.row {
				height: 100%;
				.col-xl-3 {
					height: 100%;
					#sidebar-wrapper {
						pointer-events: auto;
						position: sticky;
						top: 80px;
						@media screen and ( max-width: 1199px) {
							position: fixed;
							right: 0; } } } } } } }


html {
	box-sizing: border-box;
	-ms-overflow-style: scrollbar; }


*,
*::before,
*::after {
	box-sizing: inherit; }


.container {
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
	margin-right: auto;
	margin-left: auto; }


@media (min-width: 576px) {
	.container {
		max-width: 768px !important; } }



@media (min-width: 768px) {
	.container {
		max-width: 960px !important; } }



@media (min-width: 992px) {
	.container {
		max-width: 960px; } }



@media (min-width: 1200px) {
	.container {
		max-width: 1140px !important; } }

@media screen and ( min-width: 1400px) {
	.container {
		max-width: 1260px !important; } }


.container-fluid, .container-sm, .container-md, .container-lg, .container-xl {
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
	margin-right: auto;
	margin-left: auto; }


@media (min-width: 576px) {
	.container, .container-sm {
		max-width: 540px; } }



@media (min-width: 768px) {
	.container, .container-sm, .container-md {
		max-width: 720px; } }



@media (min-width: 992px) {
	.container, .container-sm, .container-md, .container-lg {
		max-width: 960px; } }



@media (min-width: 1200px) {
	.container, .container-sm, .container-md, .container-lg, .container-xl {
		max-width: 1140px; } }



.row {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	margin-right: -15px;
	margin-left: -15px; }


.no-gutters {
	margin-right: 0;
	margin-left: 0; }


.no-gutters > .col,
.no-gutters > [class*="col-"] {
	padding-right: 0;
	padding-left: 0; }


.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px; }


.col {
	-ms-flex-preferred-size: 0;
	flex-basis: 0;
	-ms-flex-positive: 1;
	flex-grow: 1;
	min-width: 0;
	max-width: 100%; }


.row-cols-1 > * {
	-ms-flex: 0 0 100%;
	flex: 0 0 100%;
	max-width: 100%; }


.row-cols-2 > * {
	-ms-flex: 0 0 50%;
	flex: 0 0 50%;
	max-width: 50%; }


.row-cols-3 > * {
	-ms-flex: 0 0 33.333333%;
	flex: 0 0 33.333333%;
	max-width: 33.333333%; }


.row-cols-4 > * {
	-ms-flex: 0 0 25%;
	flex: 0 0 25%;
	max-width: 25%; }


.row-cols-5 > * {
	-ms-flex: 0 0 20%;
	flex: 0 0 20%;
	max-width: 20%; }


.row-cols-6 > * {
	-ms-flex: 0 0 16.666667%;
	flex: 0 0 16.666667%;
	max-width: 16.666667%; }


.col-auto {
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
	width: auto;
	max-width: 100%; }


.col-1 {
	-ms-flex: 0 0 8.333333%;
	flex: 0 0 8.333333%;
	max-width: 8.333333%; }


.col-2 {
	-ms-flex: 0 0 16.666667%;
	flex: 0 0 16.666667%;
	max-width: 16.666667%; }


.col-3 {
	-ms-flex: 0 0 25%;
	flex: 0 0 25%;
	max-width: 25%; }


.col-4 {
	-ms-flex: 0 0 33.333333%;
	flex: 0 0 33.333333%;
	max-width: 33.333333%; }


.col-5 {
	-ms-flex: 0 0 41.666667%;
	flex: 0 0 41.666667%;
	max-width: 41.666667%; }


.col-6 {
	-ms-flex: 0 0 50%;
	flex: 0 0 50%;
	max-width: 50%; }


.col-7 {
	-ms-flex: 0 0 58.333333%;
	flex: 0 0 58.333333%;
	max-width: 58.333333%; }


.col-8 {
	-ms-flex: 0 0 66.666667%;
	flex: 0 0 66.666667%;
	max-width: 66.666667%; }


.col-9 {
	-ms-flex: 0 0 75%;
	flex: 0 0 75%;
	max-width: 75%; }


.col-10 {
	-ms-flex: 0 0 83.333333%;
	flex: 0 0 83.333333%;
	max-width: 83.333333%; }


.col-11 {
	-ms-flex: 0 0 91.666667%;
	flex: 0 0 91.666667%;
	max-width: 91.666667%; }


.col-12 {
	-ms-flex: 0 0 100%;
	flex: 0 0 100%;
	max-width: 100%; }


.order-first {
	-ms-flex-order: -1;
	order: -1; }


.order-last {
	-ms-flex-order: 13;
	order: 13; }


.order-0 {
	-ms-flex-order: 0;
	order: 0; }


.order-1 {
	-ms-flex-order: 1;
	order: 1; }


.order-2 {
	-ms-flex-order: 2;
	order: 2; }


.order-3 {
	-ms-flex-order: 3;
	order: 3; }


.order-4 {
	-ms-flex-order: 4;
	order: 4; }


.order-5 {
	-ms-flex-order: 5;
	order: 5; }


.order-6 {
	-ms-flex-order: 6;
	order: 6; }


.order-7 {
	-ms-flex-order: 7;
	order: 7; }


.order-8 {
	-ms-flex-order: 8;
	order: 8; }


.order-9 {
	-ms-flex-order: 9;
	order: 9; }


.order-10 {
	-ms-flex-order: 10;
	order: 10; }


.order-11 {
	-ms-flex-order: 11;
	order: 11; }


.order-12 {
	-ms-flex-order: 12;
	order: 12; }


.offset-1 {
	margin-left: 8.333333%; }


.offset-2 {
	margin-left: 16.666667%; }


.offset-3 {
	margin-left: 25%; }


.offset-4 {
	margin-left: 33.333333%; }


.offset-5 {
	margin-left: 41.666667%; }


.offset-6 {
	margin-left: 50%; }


.offset-7 {
	margin-left: 58.333333%; }


.offset-8 {
	margin-left: 66.666667%; }


.offset-9 {
	margin-left: 75%; }


.offset-10 {
	margin-left: 83.333333%; }


.offset-11 {
	margin-left: 91.666667%; }


@media (min-width: 576px) {
	.col-sm {
		-ms-flex-preferred-size: 0;
		flex-basis: 0;
		-ms-flex-positive: 1;
		flex-grow: 1;
		min-width: 0;
		max-width: 100%; }

	.row-cols-sm-1 > * {
		-ms-flex: 0 0 100%;
		flex: 0 0 100%;
		max-width: 100%; }

	.row-cols-sm-2 > * {
		-ms-flex: 0 0 50%;
		flex: 0 0 50%;
		max-width: 50%; }

	.row-cols-sm-3 > * {
		-ms-flex: 0 0 33.333333%;
		flex: 0 0 33.333333%;
		max-width: 33.333333%; }

	.row-cols-sm-4 > * {
		-ms-flex: 0 0 25%;
		flex: 0 0 25%;
		max-width: 25%; }

	.row-cols-sm-5 > * {
		-ms-flex: 0 0 20%;
		flex: 0 0 20%;
		max-width: 20%; }

	.row-cols-sm-6 > * {
		-ms-flex: 0 0 16.666667%;
		flex: 0 0 16.666667%;
		max-width: 16.666667%; }

	.col-sm-auto {
		-ms-flex: 0 0 auto;
		flex: 0 0 auto;
		width: auto;
		max-width: 100%; }

	.col-sm-1 {
		-ms-flex: 0 0 8.333333%;
		flex: 0 0 8.333333%;
		max-width: 8.333333%; }

	.col-sm-2 {
		-ms-flex: 0 0 16.666667%;
		flex: 0 0 16.666667%;
		max-width: 16.666667%; }

	.col-sm-3 {
		-ms-flex: 0 0 25%;
		flex: 0 0 25%;
		max-width: 25%; }

	.col-sm-4 {
		-ms-flex: 0 0 33.333333%;
		flex: 0 0 33.333333%;
		max-width: 33.333333%; }

	.col-sm-5 {
		-ms-flex: 0 0 41.666667%;
		flex: 0 0 41.666667%;
		max-width: 41.666667%; }

	.col-sm-6 {
		-ms-flex: 0 0 50%;
		flex: 0 0 50%;
		max-width: 50%; }

	.col-sm-7 {
		-ms-flex: 0 0 58.333333%;
		flex: 0 0 58.333333%;
		max-width: 58.333333%; }

	.col-sm-8 {
		-ms-flex: 0 0 66.666667%;
		flex: 0 0 66.666667%;
		max-width: 66.666667%; }

	.col-sm-9 {
		-ms-flex: 0 0 75%;
		flex: 0 0 75%;
		max-width: 75%; }

	.col-sm-10 {
		-ms-flex: 0 0 83.333333%;
		flex: 0 0 83.333333%;
		max-width: 83.333333%; }

	.col-sm-11 {
		-ms-flex: 0 0 91.666667%;
		flex: 0 0 91.666667%;
		max-width: 91.666667%; }

	.col-sm-12 {
		-ms-flex: 0 0 100%;
		flex: 0 0 100%;
		max-width: 100%; }

	.order-sm-first {
		-ms-flex-order: -1;
		order: -1; }

	.order-sm-last {
		-ms-flex-order: 13;
		order: 13; }

	.order-sm-0 {
		-ms-flex-order: 0;
		order: 0; }

	.order-sm-1 {
		-ms-flex-order: 1;
		order: 1; }

	.order-sm-2 {
		-ms-flex-order: 2;
		order: 2; }

	.order-sm-3 {
		-ms-flex-order: 3;
		order: 3; }

	.order-sm-4 {
		-ms-flex-order: 4;
		order: 4; }

	.order-sm-5 {
		-ms-flex-order: 5;
		order: 5; }

	.order-sm-6 {
		-ms-flex-order: 6;
		order: 6; }

	.order-sm-7 {
		-ms-flex-order: 7;
		order: 7; }

	.order-sm-8 {
		-ms-flex-order: 8;
		order: 8; }

	.order-sm-9 {
		-ms-flex-order: 9;
		order: 9; }

	.order-sm-10 {
		-ms-flex-order: 10;
		order: 10; }

	.order-sm-11 {
		-ms-flex-order: 11;
		order: 11; }

	.order-sm-12 {
		-ms-flex-order: 12;
		order: 12; }

	.offset-sm-0 {
		margin-left: 0; }

	.offset-sm-1 {
		margin-left: 8.333333%; }

	.offset-sm-2 {
		margin-left: 16.666667%; }

	.offset-sm-3 {
		margin-left: 25%; }

	.offset-sm-4 {
		margin-left: 33.333333%; }

	.offset-sm-5 {
		margin-left: 41.666667%; }

	.offset-sm-6 {
		margin-left: 50%; }

	.offset-sm-7 {
		margin-left: 58.333333%; }

	.offset-sm-8 {
		margin-left: 66.666667%; }

	.offset-sm-9 {
		margin-left: 75%; }

	.offset-sm-10 {
		margin-left: 83.333333%; }

	.offset-sm-11 {
		margin-left: 91.666667%; } }



@media (min-width: 768px) {
	.col-md {
		-ms-flex-preferred-size: 0;
		flex-basis: 0;
		-ms-flex-positive: 1;
		flex-grow: 1;
		min-width: 0;
		max-width: 100%; }

	.row-cols-md-1 > * {
		-ms-flex: 0 0 100%;
		flex: 0 0 100%;
		max-width: 100%; }

	.row-cols-md-2 > * {
		-ms-flex: 0 0 50%;
		flex: 0 0 50%;
		max-width: 50%; }

	.row-cols-md-3 > * {
		-ms-flex: 0 0 33.333333%;
		flex: 0 0 33.333333%;
		max-width: 33.333333%; }

	.row-cols-md-4 > * {
		-ms-flex: 0 0 25%;
		flex: 0 0 25%;
		max-width: 25%; }

	.row-cols-md-5 > * {
		-ms-flex: 0 0 20%;
		flex: 0 0 20%;
		max-width: 20%; }

	.row-cols-md-6 > * {
		-ms-flex: 0 0 16.666667%;
		flex: 0 0 16.666667%;
		max-width: 16.666667%; }

	.col-md-auto {
		-ms-flex: 0 0 auto;
		flex: 0 0 auto;
		width: auto;
		max-width: 100%; }

	.col-md-1 {
		-ms-flex: 0 0 8.333333%;
		flex: 0 0 8.333333%;
		max-width: 8.333333%; }

	.col-md-2 {
		-ms-flex: 0 0 16.666667%;
		flex: 0 0 16.666667%;
		max-width: 16.666667%; }

	.col-md-3 {
		-ms-flex: 0 0 25%;
		flex: 0 0 25%;
		max-width: 25%; }

	.col-md-4 {
		-ms-flex: 0 0 33.333333%;
		flex: 0 0 33.333333%;
		max-width: 33.333333%; }

	.col-md-5 {
		-ms-flex: 0 0 41.666667%;
		flex: 0 0 41.666667%;
		max-width: 41.666667%; }

	.col-md-6 {
		-ms-flex: 0 0 50%;
		flex: 0 0 50%;
		max-width: 50%; }

	.col-md-7 {
		-ms-flex: 0 0 58.333333%;
		flex: 0 0 58.333333%;
		max-width: 58.333333%; }

	.col-md-8 {
		-ms-flex: 0 0 66.666667%;
		flex: 0 0 66.666667%;
		max-width: 66.666667%; }

	.col-md-9 {
		-ms-flex: 0 0 75%;
		flex: 0 0 75%;
		max-width: 75%; }

	.col-md-10 {
		-ms-flex: 0 0 83.333333%;
		flex: 0 0 83.333333%;
		max-width: 83.333333%; }

	.col-md-11 {
		-ms-flex: 0 0 91.666667%;
		flex: 0 0 91.666667%;
		max-width: 91.666667%; }

	.col-md-12 {
		-ms-flex: 0 0 100%;
		flex: 0 0 100%;
		max-width: 100%; }

	.order-md-first {
		-ms-flex-order: -1;
		order: -1; }

	.order-md-last {
		-ms-flex-order: 13;
		order: 13; }

	.order-md-0 {
		-ms-flex-order: 0;
		order: 0; }

	.order-md-1 {
		-ms-flex-order: 1;
		order: 1; }

	.order-md-2 {
		-ms-flex-order: 2;
		order: 2; }

	.order-md-3 {
		-ms-flex-order: 3;
		order: 3; }

	.order-md-4 {
		-ms-flex-order: 4;
		order: 4; }

	.order-md-5 {
		-ms-flex-order: 5;
		order: 5; }

	.order-md-6 {
		-ms-flex-order: 6;
		order: 6; }

	.order-md-7 {
		-ms-flex-order: 7;
		order: 7; }

	.order-md-8 {
		-ms-flex-order: 8;
		order: 8; }

	.order-md-9 {
		-ms-flex-order: 9;
		order: 9; }

	.order-md-10 {
		-ms-flex-order: 10;
		order: 10; }

	.order-md-11 {
		-ms-flex-order: 11;
		order: 11; }

	.order-md-12 {
		-ms-flex-order: 12;
		order: 12; }

	.offset-md-0 {
		margin-left: 0; }

	.offset-md-1 {
		margin-left: 8.333333%; }

	.offset-md-2 {
		margin-left: 16.666667%; }

	.offset-md-3 {
		margin-left: 25%; }

	.offset-md-4 {
		margin-left: 33.333333%; }

	.offset-md-5 {
		margin-left: 41.666667%; }

	.offset-md-6 {
		margin-left: 50%; }

	.offset-md-7 {
		margin-left: 58.333333%; }

	.offset-md-8 {
		margin-left: 66.666667%; }

	.offset-md-9 {
		margin-left: 75%; }

	.offset-md-10 {
		margin-left: 83.333333%; }

	.offset-md-11 {
		margin-left: 91.666667%; } }



@media (min-width: 992px) {
	.col-lg {
		-ms-flex-preferred-size: 0;
		flex-basis: 0;
		-ms-flex-positive: 1;
		flex-grow: 1;
		min-width: 0;
		max-width: 100%; }

	.row-cols-lg-1 > * {
		-ms-flex: 0 0 100%;
		flex: 0 0 100%;
		max-width: 100%; }

	.row-cols-lg-2 > * {
		-ms-flex: 0 0 50%;
		flex: 0 0 50%;
		max-width: 50%; }

	.row-cols-lg-3 > * {
		-ms-flex: 0 0 33.333333%;
		flex: 0 0 33.333333%;
		max-width: 33.333333%; }

	.row-cols-lg-4 > * {
		-ms-flex: 0 0 25%;
		flex: 0 0 25%;
		max-width: 25%; }

	.row-cols-lg-5 > * {
		-ms-flex: 0 0 20%;
		flex: 0 0 20%;
		max-width: 20%; }

	.row-cols-lg-6 > * {
		-ms-flex: 0 0 16.666667%;
		flex: 0 0 16.666667%;
		max-width: 16.666667%; }

	.col-lg-auto {
		-ms-flex: 0 0 auto;
		flex: 0 0 auto;
		width: auto;
		max-width: 100%; }

	.col-lg-1 {
		-ms-flex: 0 0 8.333333%;
		flex: 0 0 8.333333%;
		max-width: 8.333333%; }

	.col-lg-2 {
		-ms-flex: 0 0 16.666667%;
		flex: 0 0 16.666667%;
		max-width: 16.666667%; }

	.col-lg-3 {
		-ms-flex: 0 0 25%;
		flex: 0 0 25%;
		max-width: 25%; }

	.col-lg-4 {
		-ms-flex: 0 0 33.333333%;
		flex: 0 0 33.333333%;
		max-width: 33.333333%; }

	.col-lg-5 {
		-ms-flex: 0 0 41.666667%;
		flex: 0 0 41.666667%;
		max-width: 41.666667%; }

	.col-lg-6 {
		-ms-flex: 0 0 50%;
		flex: 0 0 50%;
		max-width: 50%; }

	.col-lg-7 {
		-ms-flex: 0 0 58.333333%;
		flex: 0 0 58.333333%;
		max-width: 58.333333%; }

	.col-lg-8 {
		-ms-flex: 0 0 66.666667%;
		flex: 0 0 66.666667%;
		max-width: 66.666667%; }

	.col-lg-9 {
		-ms-flex: 0 0 75%;
		flex: 0 0 75%;
		max-width: 75%; }

	.col-lg-10 {
		-ms-flex: 0 0 83.333333%;
		flex: 0 0 83.333333%;
		max-width: 83.333333%; }

	.col-lg-11 {
		-ms-flex: 0 0 91.666667%;
		flex: 0 0 91.666667%;
		max-width: 91.666667%; }

	.col-lg-12 {
		-ms-flex: 0 0 100%;
		flex: 0 0 100%;
		max-width: 100%; }

	.order-lg-first {
		-ms-flex-order: -1;
		order: -1; }

	.order-lg-last {
		-ms-flex-order: 13;
		order: 13; }

	.order-lg-0 {
		-ms-flex-order: 0;
		order: 0; }

	.order-lg-1 {
		-ms-flex-order: 1;
		order: 1; }

	.order-lg-2 {
		-ms-flex-order: 2;
		order: 2; }

	.order-lg-3 {
		-ms-flex-order: 3;
		order: 3; }

	.order-lg-4 {
		-ms-flex-order: 4;
		order: 4; }

	.order-lg-5 {
		-ms-flex-order: 5;
		order: 5; }

	.order-lg-6 {
		-ms-flex-order: 6;
		order: 6; }

	.order-lg-7 {
		-ms-flex-order: 7;
		order: 7; }

	.order-lg-8 {
		-ms-flex-order: 8;
		order: 8; }

	.order-lg-9 {
		-ms-flex-order: 9;
		order: 9; }

	.order-lg-10 {
		-ms-flex-order: 10;
		order: 10; }

	.order-lg-11 {
		-ms-flex-order: 11;
		order: 11; }

	.order-lg-12 {
		-ms-flex-order: 12;
		order: 12; }

	.offset-lg-0 {
		margin-left: 0; }

	.offset-lg-1 {
		margin-left: 8.333333%; }

	.offset-lg-2 {
		margin-left: 16.666667%; }

	.offset-lg-3 {
		margin-left: 25%; }

	.offset-lg-4 {
		margin-left: 33.333333%; }

	.offset-lg-5 {
		margin-left: 41.666667%; }

	.offset-lg-6 {
		margin-left: 50%; }

	.offset-lg-7 {
		margin-left: 58.333333%; }

	.offset-lg-8 {
		margin-left: 66.666667%; }

	.offset-lg-9 {
		margin-left: 75%; }

	.offset-lg-10 {
		margin-left: 83.333333%; }

	.offset-lg-11 {
		margin-left: 91.666667%; } }



@media (min-width: 1200px) {
	.col-xl {
		-ms-flex-preferred-size: 0;
		flex-basis: 0;
		-ms-flex-positive: 1;
		flex-grow: 1;
		min-width: 0;
		max-width: 100%; }

	.row-cols-xl-1 > * {
		-ms-flex: 0 0 100%;
		flex: 0 0 100%;
		max-width: 100%; }

	.row-cols-xl-2 > * {
		-ms-flex: 0 0 50%;
		flex: 0 0 50%;
		max-width: 50%; }

	.row-cols-xl-3 > * {
		-ms-flex: 0 0 33.333333%;
		flex: 0 0 33.333333%;
		max-width: 33.333333%; }

	.row-cols-xl-4 > * {
		-ms-flex: 0 0 25%;
		flex: 0 0 25%;
		max-width: 25%; }

	.row-cols-xl-5 > * {
		-ms-flex: 0 0 20%;
		flex: 0 0 20%;
		max-width: 20%; }

	.row-cols-xl-6 > * {
		-ms-flex: 0 0 16.666667%;
		flex: 0 0 16.666667%;
		max-width: 16.666667%; }

	.col-xl-auto {
		-ms-flex: 0 0 auto;
		flex: 0 0 auto;
		width: auto;
		max-width: 100%; }

	.col-xl-1 {
		-ms-flex: 0 0 8.333333%;
		flex: 0 0 8.333333%;
		max-width: 8.333333%; }

	.col-xl-2 {
		-ms-flex: 0 0 16.666667%;
		flex: 0 0 16.666667%;
		max-width: 16.666667%; }

	.col-xl-3 {
		-ms-flex: 0 0 25%;
		flex: 0 0 25%;
		max-width: 25%; }

	.col-xl-4 {
		-ms-flex: 0 0 33.333333%;
		flex: 0 0 33.333333%;
		max-width: 33.333333%; }

	.col-xl-5 {
		-ms-flex: 0 0 41.666667%;
		flex: 0 0 41.666667%;
		max-width: 41.666667%; }

	.col-xl-6 {
		-ms-flex: 0 0 50%;
		flex: 0 0 50%;
		max-width: 50%; }

	.col-xl-7 {
		-ms-flex: 0 0 58.333333%;
		flex: 0 0 58.333333%;
		max-width: 58.333333%; }

	.col-xl-8 {
		-ms-flex: 0 0 66.666667%;
		flex: 0 0 66.666667%;
		max-width: 66.666667%; }

	.col-xl-9 {
		-ms-flex: 0 0 75%;
		flex: 0 0 75%;
		max-width: 75%; }

	.col-xl-10 {
		-ms-flex: 0 0 83.333333%;
		flex: 0 0 83.333333%;
		max-width: 83.333333%; }

	.col-xl-11 {
		-ms-flex: 0 0 91.666667%;
		flex: 0 0 91.666667%;
		max-width: 91.666667%; }

	.col-xl-12 {
		-ms-flex: 0 0 100%;
		flex: 0 0 100%;
		max-width: 100%; }

	.order-xl-first {
		-ms-flex-order: -1;
		order: -1; }

	.order-xl-last {
		-ms-flex-order: 13;
		order: 13; }

	.order-xl-0 {
		-ms-flex-order: 0;
		order: 0; }

	.order-xl-1 {
		-ms-flex-order: 1;
		order: 1; }

	.order-xl-2 {
		-ms-flex-order: 2;
		order: 2; }

	.order-xl-3 {
		-ms-flex-order: 3;
		order: 3; }

	.order-xl-4 {
		-ms-flex-order: 4;
		order: 4; }

	.order-xl-5 {
		-ms-flex-order: 5;
		order: 5; }

	.order-xl-6 {
		-ms-flex-order: 6;
		order: 6; }

	.order-xl-7 {
		-ms-flex-order: 7;
		order: 7; }

	.order-xl-8 {
		-ms-flex-order: 8;
		order: 8; }

	.order-xl-9 {
		-ms-flex-order: 9;
		order: 9; }

	.order-xl-10 {
		-ms-flex-order: 10;
		order: 10; }

	.order-xl-11 {
		-ms-flex-order: 11;
		order: 11; }

	.order-xl-12 {
		-ms-flex-order: 12;
		order: 12; }

	.offset-xl-0 {
		margin-left: 0; }

	.offset-xl-1 {
		margin-left: 8.333333%; }

	.offset-xl-2 {
		margin-left: 16.666667%; }

	.offset-xl-3 {
		margin-left: 25%; }

	.offset-xl-4 {
		margin-left: 33.333333%; }

	.offset-xl-5 {
		margin-left: 41.666667%; }

	.offset-xl-6 {
		margin-left: 50%; }

	.offset-xl-7 {
		margin-left: 58.333333%; }

	.offset-xl-8 {
		margin-left: 66.666667%; }

	.offset-xl-9 {
		margin-left: 75%; }

	.offset-xl-10 {
		margin-left: 83.333333%; }

	.offset-xl-11 {
		margin-left: 91.666667%; } }



.d-none {
	display: none !important; }


.d-inline {
	display: inline !important; }


.d-inline-block {
	display: inline-block !important; }


.d-block {
	display: block !important; }


.d-table {
	display: table !important; }


.d-table-row {
	display: table-row !important; }


.d-table-cell {
	display: table-cell !important; }


.d-flex {
	display: -ms-flexbox !important;
	display: flex !important; }


.d-inline-flex {
	display: -ms-inline-flexbox !important;
	display: inline-flex !important; }


@media (min-width: 576px) {
	.d-sm-none {
		display: none !important; }

	.d-sm-inline {
		display: inline !important; }

	.d-sm-inline-block {
		display: inline-block !important; }

	.d-sm-block {
		display: block !important; }

	.d-sm-table {
		display: table !important; }

	.d-sm-table-row {
		display: table-row !important; }

	.d-sm-table-cell {
		display: table-cell !important; }

	.d-sm-flex {
		display: -ms-flexbox !important;
		display: flex !important; }

	.d-sm-inline-flex {
		display: -ms-inline-flexbox !important;
		display: inline-flex !important; } }



@media (min-width: 768px) {
	.d-md-none {
		display: none !important; }

	.d-md-inline {
		display: inline !important; }

	.d-md-inline-block {
		display: inline-block !important; }

	.d-md-block {
		display: block !important; }

	.d-md-table {
		display: table !important; }

	.d-md-table-row {
		display: table-row !important; }

	.d-md-table-cell {
		display: table-cell !important; }

	.d-md-flex {
		display: -ms-flexbox !important;
		display: flex !important; }

	.d-md-inline-flex {
		display: -ms-inline-flexbox !important;
		display: inline-flex !important; } }



@media (min-width: 992px) {
	.d-lg-none {
		display: none !important; }

	.d-lg-inline {
		display: inline !important; }

	.d-lg-inline-block {
		display: inline-block !important; }

	.d-lg-block {
		display: block !important; }

	.d-lg-table {
		display: table !important; }

	.d-lg-table-row {
		display: table-row !important; }

	.d-lg-table-cell {
		display: table-cell !important; }

	.d-lg-flex {
		display: -ms-flexbox !important;
		display: flex !important; }

	.d-lg-inline-flex {
		display: -ms-inline-flexbox !important;
		display: inline-flex !important; } }



@media (min-width: 1200px) {
	.d-xl-none {
		display: none !important; }

	.d-xl-inline {
		display: inline !important; }

	.d-xl-inline-block {
		display: inline-block !important; }

	.d-xl-block {
		display: block !important; }

	.d-xl-table {
		display: table !important; }

	.d-xl-table-row {
		display: table-row !important; }

	.d-xl-table-cell {
		display: table-cell !important; }

	.d-xl-flex {
		display: -ms-flexbox !important;
		display: flex !important; }

	.d-xl-inline-flex {
		display: -ms-inline-flexbox !important;
		display: inline-flex !important; } }



@media print {
	.d-print-none {
		display: none !important; }

	.d-print-inline {
		display: inline !important; }

	.d-print-inline-block {
		display: inline-block !important; }

	.d-print-block {
		display: block !important; }

	.d-print-table {
		display: table !important; }

	.d-print-table-row {
		display: table-row !important; }

	.d-print-table-cell {
		display: table-cell !important; }

	.d-print-flex {
		display: -ms-flexbox !important;
		display: flex !important; }

	.d-print-inline-flex {
		display: -ms-inline-flexbox !important;
		display: inline-flex !important; } }



.flex-row {
	-ms-flex-direction: row !important;
	flex-direction: row !important; }


.flex-column {
	-ms-flex-direction: column !important;
	flex-direction: column !important; }


.flex-row-reverse {
	-ms-flex-direction: row-reverse !important;
	flex-direction: row-reverse !important; }


.flex-column-reverse {
	-ms-flex-direction: column-reverse !important;
	flex-direction: column-reverse !important; }


.flex-wrap {
	-ms-flex-wrap: wrap !important;
	flex-wrap: wrap !important; }


.flex-nowrap {
	-ms-flex-wrap: nowrap !important;
	flex-wrap: nowrap !important; }


.flex-wrap-reverse {
	-ms-flex-wrap: wrap-reverse !important;
	flex-wrap: wrap-reverse !important; }


.flex-fill {
	-ms-flex: 1 1 auto !important;
	flex: 1 1 auto !important; }


.flex-grow-0 {
	-ms-flex-positive: 0 !important;
	flex-grow: 0 !important; }


.flex-grow-1 {
	-ms-flex-positive: 1 !important;
	flex-grow: 1 !important; }


.flex-shrink-0 {
	-ms-flex-negative: 0 !important;
	flex-shrink: 0 !important; }


.flex-shrink-1 {
	-ms-flex-negative: 1 !important;
	flex-shrink: 1 !important; }


.justify-content-start {
	-ms-flex-pack: start !important;
	justify-content: flex-start !important; }


.justify-content-end {
	-ms-flex-pack: end !important;
	justify-content: flex-end !important; }


.justify-content-center {
	-ms-flex-pack: center !important;
	justify-content: center !important; }


.justify-content-between {
	-ms-flex-pack: justify !important;
	justify-content: space-between !important; }


.justify-content-around {
	-ms-flex-pack: distribute !important;
	justify-content: space-around !important; }


.align-items-start {
	-ms-flex-align: start !important;
	align-items: flex-start !important; }


.align-items-end {
	-ms-flex-align: end !important;
	align-items: flex-end !important; }


.align-items-center {
	-ms-flex-align: center !important;
	align-items: center !important; }


.align-items-baseline {
	-ms-flex-align: baseline !important;
	align-items: baseline !important; }


.align-items-stretch {
	-ms-flex-align: stretch !important;
	align-items: stretch !important; }


.align-content-start {
	-ms-flex-line-pack: start !important;
	align-content: flex-start !important; }


.align-content-end {
	-ms-flex-line-pack: end !important;
	align-content: flex-end !important; }


.align-content-center {
	-ms-flex-line-pack: center !important;
	align-content: center !important; }


.align-content-between {
	-ms-flex-line-pack: justify !important;
	align-content: space-between !important; }


.align-content-around {
	-ms-flex-line-pack: distribute !important;
	align-content: space-around !important; }


.align-content-stretch {
	-ms-flex-line-pack: stretch !important;
	align-content: stretch !important; }


.align-self-auto {
	-ms-flex-item-align: auto !important;
	align-self: auto !important; }


.align-self-start {
	-ms-flex-item-align: start !important;
	align-self: flex-start !important; }


.align-self-end {
	-ms-flex-item-align: end !important;
	align-self: flex-end !important; }


.align-self-center {
	-ms-flex-item-align: center !important;
	align-self: center !important; }


.align-self-baseline {
	-ms-flex-item-align: baseline !important;
	align-self: baseline !important; }


.align-self-stretch {
	-ms-flex-item-align: stretch !important;
	align-self: stretch !important; }


@media (min-width: 576px) {
	.flex-sm-row {
		-ms-flex-direction: row !important;
		flex-direction: row !important; }

	.flex-sm-column {
		-ms-flex-direction: column !important;
		flex-direction: column !important; }

	.flex-sm-row-reverse {
		-ms-flex-direction: row-reverse !important;
		flex-direction: row-reverse !important; }

	.flex-sm-column-reverse {
		-ms-flex-direction: column-reverse !important;
		flex-direction: column-reverse !important; }

	.flex-sm-wrap {
		-ms-flex-wrap: wrap !important;
		flex-wrap: wrap !important; }

	.flex-sm-nowrap {
		-ms-flex-wrap: nowrap !important;
		flex-wrap: nowrap !important; }

	.flex-sm-wrap-reverse {
		-ms-flex-wrap: wrap-reverse !important;
		flex-wrap: wrap-reverse !important; }

	.flex-sm-fill {
		-ms-flex: 1 1 auto !important;
		flex: 1 1 auto !important; }

	.flex-sm-grow-0 {
		-ms-flex-positive: 0 !important;
		flex-grow: 0 !important; }

	.flex-sm-grow-1 {
		-ms-flex-positive: 1 !important;
		flex-grow: 1 !important; }

	.flex-sm-shrink-0 {
		-ms-flex-negative: 0 !important;
		flex-shrink: 0 !important; }

	.flex-sm-shrink-1 {
		-ms-flex-negative: 1 !important;
		flex-shrink: 1 !important; }

	.justify-content-sm-start {
		-ms-flex-pack: start !important;
		justify-content: flex-start !important; }

	.justify-content-sm-end {
		-ms-flex-pack: end !important;
		justify-content: flex-end !important; }

	.justify-content-sm-center {
		-ms-flex-pack: center !important;
		justify-content: center !important; }

	.justify-content-sm-between {
		-ms-flex-pack: justify !important;
		justify-content: space-between !important; }

	.justify-content-sm-around {
		-ms-flex-pack: distribute !important;
		justify-content: space-around !important; }

	.align-items-sm-start {
		-ms-flex-align: start !important;
		align-items: flex-start !important; }

	.align-items-sm-end {
		-ms-flex-align: end !important;
		align-items: flex-end !important; }

	.align-items-sm-center {
		-ms-flex-align: center !important;
		align-items: center !important; }

	.align-items-sm-baseline {
		-ms-flex-align: baseline !important;
		align-items: baseline !important; }

	.align-items-sm-stretch {
		-ms-flex-align: stretch !important;
		align-items: stretch !important; }

	.align-content-sm-start {
		-ms-flex-line-pack: start !important;
		align-content: flex-start !important; }

	.align-content-sm-end {
		-ms-flex-line-pack: end !important;
		align-content: flex-end !important; }

	.align-content-sm-center {
		-ms-flex-line-pack: center !important;
		align-content: center !important; }

	.align-content-sm-between {
		-ms-flex-line-pack: justify !important;
		align-content: space-between !important; }

	.align-content-sm-around {
		-ms-flex-line-pack: distribute !important;
		align-content: space-around !important; }

	.align-content-sm-stretch {
		-ms-flex-line-pack: stretch !important;
		align-content: stretch !important; }

	.align-self-sm-auto {
		-ms-flex-item-align: auto !important;
		align-self: auto !important; }

	.align-self-sm-start {
		-ms-flex-item-align: start !important;
		align-self: flex-start !important; }

	.align-self-sm-end {
		-ms-flex-item-align: end !important;
		align-self: flex-end !important; }

	.align-self-sm-center {
		-ms-flex-item-align: center !important;
		align-self: center !important; }

	.align-self-sm-baseline {
		-ms-flex-item-align: baseline !important;
		align-self: baseline !important; }

	.align-self-sm-stretch {
		-ms-flex-item-align: stretch !important;
		align-self: stretch !important; } }



@media (min-width: 768px) {
	.flex-md-row {
		-ms-flex-direction: row !important;
		flex-direction: row !important; }

	.flex-md-column {
		-ms-flex-direction: column !important;
		flex-direction: column !important; }

	.flex-md-row-reverse {
		-ms-flex-direction: row-reverse !important;
		flex-direction: row-reverse !important; }

	.flex-md-column-reverse {
		-ms-flex-direction: column-reverse !important;
		flex-direction: column-reverse !important; }

	.flex-md-wrap {
		-ms-flex-wrap: wrap !important;
		flex-wrap: wrap !important; }

	.flex-md-nowrap {
		-ms-flex-wrap: nowrap !important;
		flex-wrap: nowrap !important; }

	.flex-md-wrap-reverse {
		-ms-flex-wrap: wrap-reverse !important;
		flex-wrap: wrap-reverse !important; }

	.flex-md-fill {
		-ms-flex: 1 1 auto !important;
		flex: 1 1 auto !important; }

	.flex-md-grow-0 {
		-ms-flex-positive: 0 !important;
		flex-grow: 0 !important; }

	.flex-md-grow-1 {
		-ms-flex-positive: 1 !important;
		flex-grow: 1 !important; }

	.flex-md-shrink-0 {
		-ms-flex-negative: 0 !important;
		flex-shrink: 0 !important; }

	.flex-md-shrink-1 {
		-ms-flex-negative: 1 !important;
		flex-shrink: 1 !important; }

	.justify-content-md-start {
		-ms-flex-pack: start !important;
		justify-content: flex-start !important; }

	.justify-content-md-end {
		-ms-flex-pack: end !important;
		justify-content: flex-end !important; }

	.justify-content-md-center {
		-ms-flex-pack: center !important;
		justify-content: center !important; }

	.justify-content-md-between {
		-ms-flex-pack: justify !important;
		justify-content: space-between !important; }

	.justify-content-md-around {
		-ms-flex-pack: distribute !important;
		justify-content: space-around !important; }

	.align-items-md-start {
		-ms-flex-align: start !important;
		align-items: flex-start !important; }

	.align-items-md-end {
		-ms-flex-align: end !important;
		align-items: flex-end !important; }

	.align-items-md-center {
		-ms-flex-align: center !important;
		align-items: center !important; }

	.align-items-md-baseline {
		-ms-flex-align: baseline !important;
		align-items: baseline !important; }

	.align-items-md-stretch {
		-ms-flex-align: stretch !important;
		align-items: stretch !important; }

	.align-content-md-start {
		-ms-flex-line-pack: start !important;
		align-content: flex-start !important; }

	.align-content-md-end {
		-ms-flex-line-pack: end !important;
		align-content: flex-end !important; }

	.align-content-md-center {
		-ms-flex-line-pack: center !important;
		align-content: center !important; }

	.align-content-md-between {
		-ms-flex-line-pack: justify !important;
		align-content: space-between !important; }

	.align-content-md-around {
		-ms-flex-line-pack: distribute !important;
		align-content: space-around !important; }

	.align-content-md-stretch {
		-ms-flex-line-pack: stretch !important;
		align-content: stretch !important; }

	.align-self-md-auto {
		-ms-flex-item-align: auto !important;
		align-self: auto !important; }

	.align-self-md-start {
		-ms-flex-item-align: start !important;
		align-self: flex-start !important; }

	.align-self-md-end {
		-ms-flex-item-align: end !important;
		align-self: flex-end !important; }

	.align-self-md-center {
		-ms-flex-item-align: center !important;
		align-self: center !important; }

	.align-self-md-baseline {
		-ms-flex-item-align: baseline !important;
		align-self: baseline !important; }

	.align-self-md-stretch {
		-ms-flex-item-align: stretch !important;
		align-self: stretch !important; } }



@media (min-width: 992px) {
	.flex-lg-row {
		-ms-flex-direction: row !important;
		flex-direction: row !important; }

	.flex-lg-column {
		-ms-flex-direction: column !important;
		flex-direction: column !important; }

	.flex-lg-row-reverse {
		-ms-flex-direction: row-reverse !important;
		flex-direction: row-reverse !important; }

	.flex-lg-column-reverse {
		-ms-flex-direction: column-reverse !important;
		flex-direction: column-reverse !important; }

	.flex-lg-wrap {
		-ms-flex-wrap: wrap !important;
		flex-wrap: wrap !important; }

	.flex-lg-nowrap {
		-ms-flex-wrap: nowrap !important;
		flex-wrap: nowrap !important; }

	.flex-lg-wrap-reverse {
		-ms-flex-wrap: wrap-reverse !important;
		flex-wrap: wrap-reverse !important; }

	.flex-lg-fill {
		-ms-flex: 1 1 auto !important;
		flex: 1 1 auto !important; }

	.flex-lg-grow-0 {
		-ms-flex-positive: 0 !important;
		flex-grow: 0 !important; }

	.flex-lg-grow-1 {
		-ms-flex-positive: 1 !important;
		flex-grow: 1 !important; }

	.flex-lg-shrink-0 {
		-ms-flex-negative: 0 !important;
		flex-shrink: 0 !important; }

	.flex-lg-shrink-1 {
		-ms-flex-negative: 1 !important;
		flex-shrink: 1 !important; }

	.justify-content-lg-start {
		-ms-flex-pack: start !important;
		justify-content: flex-start !important; }

	.justify-content-lg-end {
		-ms-flex-pack: end !important;
		justify-content: flex-end !important; }

	.justify-content-lg-center {
		-ms-flex-pack: center !important;
		justify-content: center !important; }

	.justify-content-lg-between {
		-ms-flex-pack: justify !important;
		justify-content: space-between !important; }

	.justify-content-lg-around {
		-ms-flex-pack: distribute !important;
		justify-content: space-around !important; }

	.align-items-lg-start {
		-ms-flex-align: start !important;
		align-items: flex-start !important; }

	.align-items-lg-end {
		-ms-flex-align: end !important;
		align-items: flex-end !important; }

	.align-items-lg-center {
		-ms-flex-align: center !important;
		align-items: center !important; }

	.align-items-lg-baseline {
		-ms-flex-align: baseline !important;
		align-items: baseline !important; }

	.align-items-lg-stretch {
		-ms-flex-align: stretch !important;
		align-items: stretch !important; }

	.align-content-lg-start {
		-ms-flex-line-pack: start !important;
		align-content: flex-start !important; }

	.align-content-lg-end {
		-ms-flex-line-pack: end !important;
		align-content: flex-end !important; }

	.align-content-lg-center {
		-ms-flex-line-pack: center !important;
		align-content: center !important; }

	.align-content-lg-between {
		-ms-flex-line-pack: justify !important;
		align-content: space-between !important; }

	.align-content-lg-around {
		-ms-flex-line-pack: distribute !important;
		align-content: space-around !important; }

	.align-content-lg-stretch {
		-ms-flex-line-pack: stretch !important;
		align-content: stretch !important; }

	.align-self-lg-auto {
		-ms-flex-item-align: auto !important;
		align-self: auto !important; }

	.align-self-lg-start {
		-ms-flex-item-align: start !important;
		align-self: flex-start !important; }

	.align-self-lg-end {
		-ms-flex-item-align: end !important;
		align-self: flex-end !important; }

	.align-self-lg-center {
		-ms-flex-item-align: center !important;
		align-self: center !important; }

	.align-self-lg-baseline {
		-ms-flex-item-align: baseline !important;
		align-self: baseline !important; }

	.align-self-lg-stretch {
		-ms-flex-item-align: stretch !important;
		align-self: stretch !important; } }



@media (min-width: 1200px) {
	.flex-xl-row {
		-ms-flex-direction: row !important;
		flex-direction: row !important; }

	.flex-xl-column {
		-ms-flex-direction: column !important;
		flex-direction: column !important; }

	.flex-xl-row-reverse {
		-ms-flex-direction: row-reverse !important;
		flex-direction: row-reverse !important; }

	.flex-xl-column-reverse {
		-ms-flex-direction: column-reverse !important;
		flex-direction: column-reverse !important; }

	.flex-xl-wrap {
		-ms-flex-wrap: wrap !important;
		flex-wrap: wrap !important; }

	.flex-xl-nowrap {
		-ms-flex-wrap: nowrap !important;
		flex-wrap: nowrap !important; }

	.flex-xl-wrap-reverse {
		-ms-flex-wrap: wrap-reverse !important;
		flex-wrap: wrap-reverse !important; }

	.flex-xl-fill {
		-ms-flex: 1 1 auto !important;
		flex: 1 1 auto !important; }

	.flex-xl-grow-0 {
		-ms-flex-positive: 0 !important;
		flex-grow: 0 !important; }

	.flex-xl-grow-1 {
		-ms-flex-positive: 1 !important;
		flex-grow: 1 !important; }

	.flex-xl-shrink-0 {
		-ms-flex-negative: 0 !important;
		flex-shrink: 0 !important; }

	.flex-xl-shrink-1 {
		-ms-flex-negative: 1 !important;
		flex-shrink: 1 !important; }

	.justify-content-xl-start {
		-ms-flex-pack: start !important;
		justify-content: flex-start !important; }

	.justify-content-xl-end {
		-ms-flex-pack: end !important;
		justify-content: flex-end !important; }

	.justify-content-xl-center {
		-ms-flex-pack: center !important;
		justify-content: center !important; }

	.justify-content-xl-between {
		-ms-flex-pack: justify !important;
		justify-content: space-between !important; }

	.justify-content-xl-around {
		-ms-flex-pack: distribute !important;
		justify-content: space-around !important; }

	.align-items-xl-start {
		-ms-flex-align: start !important;
		align-items: flex-start !important; }

	.align-items-xl-end {
		-ms-flex-align: end !important;
		align-items: flex-end !important; }

	.align-items-xl-center {
		-ms-flex-align: center !important;
		align-items: center !important; }

	.align-items-xl-baseline {
		-ms-flex-align: baseline !important;
		align-items: baseline !important; }

	.align-items-xl-stretch {
		-ms-flex-align: stretch !important;
		align-items: stretch !important; }

	.align-content-xl-start {
		-ms-flex-line-pack: start !important;
		align-content: flex-start !important; }

	.align-content-xl-end {
		-ms-flex-line-pack: end !important;
		align-content: flex-end !important; }

	.align-content-xl-center {
		-ms-flex-line-pack: center !important;
		align-content: center !important; }

	.align-content-xl-between {
		-ms-flex-line-pack: justify !important;
		align-content: space-between !important; }

	.align-content-xl-around {
		-ms-flex-line-pack: distribute !important;
		align-content: space-around !important; }

	.align-content-xl-stretch {
		-ms-flex-line-pack: stretch !important;
		align-content: stretch !important; }

	.align-self-xl-auto {
		-ms-flex-item-align: auto !important;
		align-self: auto !important; }

	.align-self-xl-start {
		-ms-flex-item-align: start !important;
		align-self: flex-start !important; }

	.align-self-xl-end {
		-ms-flex-item-align: end !important;
		align-self: flex-end !important; }

	.align-self-xl-center {
		-ms-flex-item-align: center !important;
		align-self: center !important; }

	.align-self-xl-baseline {
		-ms-flex-item-align: baseline !important;
		align-self: baseline !important; }

	.align-self-xl-stretch {
		-ms-flex-item-align: stretch !important;
		align-self: stretch !important; } }



.m-0 {
	margin: 0 !important; }


.mt-0,
.my-0 {
	margin-top: 0 !important; }


.mr-0,
.mx-0 {
	margin-right: 0 !important; }


.mb-0,
.my-0 {
	margin-bottom: 0 !important; }


.ml-0,
.mx-0 {
	margin-left: 0 !important; }


.m-1 {
	margin: 0.25rem !important; }


.mt-1,
.my-1 {
	margin-top: 0.25rem !important; }


.mr-1,
.mx-1 {
	margin-right: 0.25rem !important; }


.mb-1,
.my-1 {
	margin-bottom: 0.25rem !important; }


.ml-1,
.mx-1 {
	margin-left: 0.25rem !important; }


.m-2 {
	margin: 0.5rem !important; }


.mt-2,
.my-2 {
	margin-top: 0.5rem !important; }


.mr-2,
.mx-2 {
	margin-right: 0.5rem !important; }


.mb-2,
.my-2 {
	margin-bottom: 0.5rem !important; }


.ml-2,
.mx-2 {
	margin-left: 0.5rem !important; }


.m-3 {
	margin: 1rem !important; }


.mt-3,
.my-3 {
	margin-top: 1rem !important; }


.mr-3,
.mx-3 {
	margin-right: 1rem !important; }


.mb-3,
.my-3 {
	margin-bottom: 1rem !important; }


.ml-3,
.mx-3 {
	margin-left: 1rem !important; }


.m-4 {
	margin: 1.5rem !important; }


.mt-4,
.my-4 {
	margin-top: 1.5rem !important; }


.mr-4,
.mx-4 {
	margin-right: 1.5rem !important; }


.mb-4,
.my-4 {
	margin-bottom: 1.5rem !important; }


.ml-4,
.mx-4 {
	margin-left: 1.5rem !important; }


.m-5 {
	margin: 3rem !important; }


.mt-5,
.my-5 {
	margin-top: 3rem !important; }


.mr-5,
.mx-5 {
	margin-right: 3rem !important; }


.mb-5,
.my-5 {
	margin-bottom: 3rem !important; }


.ml-5,
.mx-5 {
	margin-left: 3rem !important; }


.p-0 {
	padding: 0 !important; }


.pt-0,
.py-0 {
	padding-top: 0 !important; }


.pr-0,
.px-0 {
	padding-right: 0 !important; }


.pb-0,
.py-0 {
	padding-bottom: 0 !important; }


.pl-0,
.px-0 {
	padding-left: 0 !important; }


.p-1 {
	padding: 0.25rem !important; }


.pt-1,
.py-1 {
	padding-top: 0.25rem !important; }


.pr-1,
.px-1 {
	padding-right: 0.25rem !important; }


.pb-1,
.py-1 {
	padding-bottom: 0.25rem !important; }


.pl-1,
.px-1 {
	padding-left: 0.25rem !important; }


.p-2 {
	padding: 0.5rem !important; }


.pt-2,
.py-2 {
	padding-top: 0.5rem !important; }


.pr-2,
.px-2 {
	padding-right: 0.5rem !important; }


.pb-2,
.py-2 {
	padding-bottom: 0.5rem !important; }


.pl-2,
.px-2 {
	padding-left: 0.5rem !important; }


.p-3 {
	padding: 1rem !important; }


.pt-3,
.py-3 {
	padding-top: 1rem !important; }


.pr-3,
.px-3 {
	padding-right: 1rem !important; }


.pb-3,
.py-3 {
	padding-bottom: 1rem !important; }


.pl-3,
.px-3 {
	padding-left: 1rem !important; }


.p-4 {
	padding: 1.5rem !important; }


.pt-4,
.py-4 {
	padding-top: 1.5rem !important; }


.pr-4,
.px-4 {
	padding-right: 1.5rem !important; }


.pb-4,
.py-4 {
	padding-bottom: 1.5rem !important; }


.pl-4,
.px-4 {
	padding-left: 1.5rem !important; }


.p-5 {
	padding: 3rem !important; }


.pt-5,
.py-5 {
	padding-top: 3rem !important; }


.pr-5,
.px-5 {
	padding-right: 3rem !important; }


.pb-5,
.py-5 {
	padding-bottom: 3rem !important; }


.pl-5,
.px-5 {
	padding-left: 3rem !important; }


.m-n1 {
	margin: -0.25rem !important; }


.mt-n1,
.my-n1 {
	margin-top: -0.25rem !important; }


.mr-n1,
.mx-n1 {
	margin-right: -0.25rem !important; }


.mb-n1,
.my-n1 {
	margin-bottom: -0.25rem !important; }


.ml-n1,
.mx-n1 {
	margin-left: -0.25rem !important; }


.m-n2 {
	margin: -0.5rem !important; }


.mt-n2,
.my-n2 {
	margin-top: -0.5rem !important; }


.mr-n2,
.mx-n2 {
	margin-right: -0.5rem !important; }


.mb-n2,
.my-n2 {
	margin-bottom: -0.5rem !important; }


.ml-n2,
.mx-n2 {
	margin-left: -0.5rem !important; }


.m-n3 {
	margin: -1rem !important; }


.mt-n3,
.my-n3 {
	margin-top: -1rem !important; }


.mr-n3,
.mx-n3 {
	margin-right: -1rem !important; }


.mb-n3,
.my-n3 {
	margin-bottom: -1rem !important; }


.ml-n3,
.mx-n3 {
	margin-left: -1rem !important; }


.m-n4 {
	margin: -1.5rem !important; }


.mt-n4,
.my-n4 {
	margin-top: -1.5rem !important; }


.mr-n4,
.mx-n4 {
	margin-right: -1.5rem !important; }


.mb-n4,
.my-n4 {
	margin-bottom: -1.5rem !important; }


.ml-n4,
.mx-n4 {
	margin-left: -1.5rem !important; }


.m-n5 {
	margin: -3rem !important; }


.mt-n5,
.my-n5 {
	margin-top: -3rem !important; }


.mr-n5,
.mx-n5 {
	margin-right: -3rem !important; }


.mb-n5,
.my-n5 {
	margin-bottom: -3rem !important; }


.ml-n5,
.mx-n5 {
	margin-left: -3rem !important; }


.m-auto {
	margin: auto !important; }


.mt-auto,
.my-auto {
	margin-top: auto !important; }


.mr-auto,
.mx-auto {
	margin-right: auto !important; }


.mb-auto,
.my-auto {
	margin-bottom: auto !important; }


.ml-auto,
.mx-auto {
	margin-left: auto !important; }


@media (min-width: 576px) {
	.m-sm-0 {
		margin: 0 !important; }

	.mt-sm-0,
	.my-sm-0 {
		margin-top: 0 !important; }

	.mr-sm-0,
	.mx-sm-0 {
		margin-right: 0 !important; }

	.mb-sm-0,
	.my-sm-0 {
		margin-bottom: 0 !important; }

	.ml-sm-0,
	.mx-sm-0 {
		margin-left: 0 !important; }

	.m-sm-1 {
		margin: 0.25rem !important; }

	.mt-sm-1,
	.my-sm-1 {
		margin-top: 0.25rem !important; }

	.mr-sm-1,
	.mx-sm-1 {
		margin-right: 0.25rem !important; }

	.mb-sm-1,
	.my-sm-1 {
		margin-bottom: 0.25rem !important; }

	.ml-sm-1,
	.mx-sm-1 {
		margin-left: 0.25rem !important; }

	.m-sm-2 {
		margin: 0.5rem !important; }

	.mt-sm-2,
	.my-sm-2 {
		margin-top: 0.5rem !important; }

	.mr-sm-2,
	.mx-sm-2 {
		margin-right: 0.5rem !important; }

	.mb-sm-2,
	.my-sm-2 {
		margin-bottom: 0.5rem !important; }

	.ml-sm-2,
	.mx-sm-2 {
		margin-left: 0.5rem !important; }

	.m-sm-3 {
		margin: 1rem !important; }

	.mt-sm-3,
	.my-sm-3 {
		margin-top: 1rem !important; }

	.mr-sm-3,
	.mx-sm-3 {
		margin-right: 1rem !important; }

	.mb-sm-3,
	.my-sm-3 {
		margin-bottom: 1rem !important; }

	.ml-sm-3,
	.mx-sm-3 {
		margin-left: 1rem !important; }

	.m-sm-4 {
		margin: 1.5rem !important; }

	.mt-sm-4,
	.my-sm-4 {
		margin-top: 1.5rem !important; }

	.mr-sm-4,
	.mx-sm-4 {
		margin-right: 1.5rem !important; }

	.mb-sm-4,
	.my-sm-4 {
		margin-bottom: 1.5rem !important; }

	.ml-sm-4,
	.mx-sm-4 {
		margin-left: 1.5rem !important; }

	.m-sm-5 {
		margin: 3rem !important; }

	.mt-sm-5,
	.my-sm-5 {
		margin-top: 3rem !important; }

	.mr-sm-5,
	.mx-sm-5 {
		margin-right: 3rem !important; }

	.mb-sm-5,
	.my-sm-5 {
		margin-bottom: 3rem !important; }

	.ml-sm-5,
	.mx-sm-5 {
		margin-left: 3rem !important; }

	.p-sm-0 {
		padding: 0 !important; }

	.pt-sm-0,
	.py-sm-0 {
		padding-top: 0 !important; }

	.pr-sm-0,
	.px-sm-0 {
		padding-right: 0 !important; }

	.pb-sm-0,
	.py-sm-0 {
		padding-bottom: 0 !important; }

	.pl-sm-0,
	.px-sm-0 {
		padding-left: 0 !important; }

	.p-sm-1 {
		padding: 0.25rem !important; }

	.pt-sm-1,
	.py-sm-1 {
		padding-top: 0.25rem !important; }

	.pr-sm-1,
	.px-sm-1 {
		padding-right: 0.25rem !important; }

	.pb-sm-1,
	.py-sm-1 {
		padding-bottom: 0.25rem !important; }

	.pl-sm-1,
	.px-sm-1 {
		padding-left: 0.25rem !important; }

	.p-sm-2 {
		padding: 0.5rem !important; }

	.pt-sm-2,
	.py-sm-2 {
		padding-top: 0.5rem !important; }

	.pr-sm-2,
	.px-sm-2 {
		padding-right: 0.5rem !important; }

	.pb-sm-2,
	.py-sm-2 {
		padding-bottom: 0.5rem !important; }

	.pl-sm-2,
	.px-sm-2 {
		padding-left: 0.5rem !important; }

	.p-sm-3 {
		padding: 1rem !important; }

	.pt-sm-3,
	.py-sm-3 {
		padding-top: 1rem !important; }

	.pr-sm-3,
	.px-sm-3 {
		padding-right: 1rem !important; }

	.pb-sm-3,
	.py-sm-3 {
		padding-bottom: 1rem !important; }

	.pl-sm-3,
	.px-sm-3 {
		padding-left: 1rem !important; }

	.p-sm-4 {
		padding: 1.5rem !important; }

	.pt-sm-4,
	.py-sm-4 {
		padding-top: 1.5rem !important; }

	.pr-sm-4,
	.px-sm-4 {
		padding-right: 1.5rem !important; }

	.pb-sm-4,
	.py-sm-4 {
		padding-bottom: 1.5rem !important; }

	.pl-sm-4,
	.px-sm-4 {
		padding-left: 1.5rem !important; }

	.p-sm-5 {
		padding: 3rem !important; }

	.pt-sm-5,
	.py-sm-5 {
		padding-top: 3rem !important; }

	.pr-sm-5,
	.px-sm-5 {
		padding-right: 3rem !important; }

	.pb-sm-5,
	.py-sm-5 {
		padding-bottom: 3rem !important; }

	.pl-sm-5,
	.px-sm-5 {
		padding-left: 3rem !important; }

	.m-sm-n1 {
		margin: -0.25rem !important; }

	.mt-sm-n1,
	.my-sm-n1 {
		margin-top: -0.25rem !important; }

	.mr-sm-n1,
	.mx-sm-n1 {
		margin-right: -0.25rem !important; }

	.mb-sm-n1,
	.my-sm-n1 {
		margin-bottom: -0.25rem !important; }

	.ml-sm-n1,
	.mx-sm-n1 {
		margin-left: -0.25rem !important; }

	.m-sm-n2 {
		margin: -0.5rem !important; }

	.mt-sm-n2,
	.my-sm-n2 {
		margin-top: -0.5rem !important; }

	.mr-sm-n2,
	.mx-sm-n2 {
		margin-right: -0.5rem !important; }

	.mb-sm-n2,
	.my-sm-n2 {
		margin-bottom: -0.5rem !important; }

	.ml-sm-n2,
	.mx-sm-n2 {
		margin-left: -0.5rem !important; }

	.m-sm-n3 {
		margin: -1rem !important; }

	.mt-sm-n3,
	.my-sm-n3 {
		margin-top: -1rem !important; }

	.mr-sm-n3,
	.mx-sm-n3 {
		margin-right: -1rem !important; }

	.mb-sm-n3,
	.my-sm-n3 {
		margin-bottom: -1rem !important; }

	.ml-sm-n3,
	.mx-sm-n3 {
		margin-left: -1rem !important; }

	.m-sm-n4 {
		margin: -1.5rem !important; }

	.mt-sm-n4,
	.my-sm-n4 {
		margin-top: -1.5rem !important; }

	.mr-sm-n4,
	.mx-sm-n4 {
		margin-right: -1.5rem !important; }

	.mb-sm-n4,
	.my-sm-n4 {
		margin-bottom: -1.5rem !important; }

	.ml-sm-n4,
	.mx-sm-n4 {
		margin-left: -1.5rem !important; }

	.m-sm-n5 {
		margin: -3rem !important; }

	.mt-sm-n5,
	.my-sm-n5 {
		margin-top: -3rem !important; }

	.mr-sm-n5,
	.mx-sm-n5 {
		margin-right: -3rem !important; }

	.mb-sm-n5,
	.my-sm-n5 {
		margin-bottom: -3rem !important; }

	.ml-sm-n5,
	.mx-sm-n5 {
		margin-left: -3rem !important; }

	.m-sm-auto {
		margin: auto !important; }

	.mt-sm-auto,
	.my-sm-auto {
		margin-top: auto !important; }

	.mr-sm-auto,
	.mx-sm-auto {
		margin-right: auto !important; }

	.mb-sm-auto,
	.my-sm-auto {
		margin-bottom: auto !important; }

	.ml-sm-auto,
	.mx-sm-auto {
		margin-left: auto !important; } }



@media (min-width: 768px) {
	.m-md-0 {
		margin: 0 !important; }

	.mt-md-0,
	.my-md-0 {
		margin-top: 0 !important; }

	.mr-md-0,
	.mx-md-0 {
		margin-right: 0 !important; }

	.mb-md-0,
	.my-md-0 {
		margin-bottom: 0 !important; }

	.ml-md-0,
	.mx-md-0 {
		margin-left: 0 !important; }

	.m-md-1 {
		margin: 0.25rem !important; }

	.mt-md-1,
	.my-md-1 {
		margin-top: 0.25rem !important; }

	.mr-md-1,
	.mx-md-1 {
		margin-right: 0.25rem !important; }

	.mb-md-1,
	.my-md-1 {
		margin-bottom: 0.25rem !important; }

	.ml-md-1,
	.mx-md-1 {
		margin-left: 0.25rem !important; }

	.m-md-2 {
		margin: 0.5rem !important; }

	.mt-md-2,
	.my-md-2 {
		margin-top: 0.5rem !important; }

	.mr-md-2,
	.mx-md-2 {
		margin-right: 0.5rem !important; }

	.mb-md-2,
	.my-md-2 {
		margin-bottom: 0.5rem !important; }

	.ml-md-2,
	.mx-md-2 {
		margin-left: 0.5rem !important; }

	.m-md-3 {
		margin: 1rem !important; }

	.mt-md-3,
	.my-md-3 {
		margin-top: 1rem !important; }

	.mr-md-3,
	.mx-md-3 {
		margin-right: 1rem !important; }

	.mb-md-3,
	.my-md-3 {
		margin-bottom: 1rem !important; }

	.ml-md-3,
	.mx-md-3 {
		margin-left: 1rem !important; }

	.m-md-4 {
		margin: 1.5rem !important; }

	.mt-md-4,
	.my-md-4 {
		margin-top: 1.5rem !important; }

	.mr-md-4,
	.mx-md-4 {
		margin-right: 1.5rem !important; }

	.mb-md-4,
	.my-md-4 {
		margin-bottom: 1.5rem !important; }

	.ml-md-4,
	.mx-md-4 {
		margin-left: 1.5rem !important; }

	.m-md-5 {
		margin: 3rem !important; }

	.mt-md-5,
	.my-md-5 {
		margin-top: 3rem !important; }

	.mr-md-5,
	.mx-md-5 {
		margin-right: 3rem !important; }

	.mb-md-5,
	.my-md-5 {
		margin-bottom: 3rem !important; }

	.ml-md-5,
	.mx-md-5 {
		margin-left: 3rem !important; }

	.p-md-0 {
		padding: 0 !important; }

	.pt-md-0,
	.py-md-0 {
		padding-top: 0 !important; }

	.pr-md-0,
	.px-md-0 {
		padding-right: 0 !important; }

	.pb-md-0,
	.py-md-0 {
		padding-bottom: 0 !important; }

	.pl-md-0,
	.px-md-0 {
		padding-left: 0 !important; }

	.p-md-1 {
		padding: 0.25rem !important; }

	.pt-md-1,
	.py-md-1 {
		padding-top: 0.25rem !important; }

	.pr-md-1,
	.px-md-1 {
		padding-right: 0.25rem !important; }

	.pb-md-1,
	.py-md-1 {
		padding-bottom: 0.25rem !important; }

	.pl-md-1,
	.px-md-1 {
		padding-left: 0.25rem !important; }

	.p-md-2 {
		padding: 0.5rem !important; }

	.pt-md-2,
	.py-md-2 {
		padding-top: 0.5rem !important; }

	.pr-md-2,
	.px-md-2 {
		padding-right: 0.5rem !important; }

	.pb-md-2,
	.py-md-2 {
		padding-bottom: 0.5rem !important; }

	.pl-md-2,
	.px-md-2 {
		padding-left: 0.5rem !important; }

	.p-md-3 {
		padding: 1rem !important; }

	.pt-md-3,
	.py-md-3 {
		padding-top: 1rem !important; }

	.pr-md-3,
	.px-md-3 {
		padding-right: 1rem !important; }

	.pb-md-3,
	.py-md-3 {
		padding-bottom: 1rem !important; }

	.pl-md-3,
	.px-md-3 {
		padding-left: 1rem !important; }

	.p-md-4 {
		padding: 1.5rem !important; }

	.pt-md-4,
	.py-md-4 {
		padding-top: 1.5rem !important; }

	.pr-md-4,
	.px-md-4 {
		padding-right: 1.5rem !important; }

	.pb-md-4,
	.py-md-4 {
		padding-bottom: 1.5rem !important; }

	.pl-md-4,
	.px-md-4 {
		padding-left: 1.5rem !important; }

	.p-md-5 {
		padding: 3rem !important; }

	.pt-md-5,
	.py-md-5 {
		padding-top: 3rem !important; }

	.pr-md-5,
	.px-md-5 {
		padding-right: 3rem !important; }

	.pb-md-5,
	.py-md-5 {
		padding-bottom: 3rem !important; }

	.pl-md-5,
	.px-md-5 {
		padding-left: 3rem !important; }

	.m-md-n1 {
		margin: -0.25rem !important; }

	.mt-md-n1,
	.my-md-n1 {
		margin-top: -0.25rem !important; }

	.mr-md-n1,
	.mx-md-n1 {
		margin-right: -0.25rem !important; }

	.mb-md-n1,
	.my-md-n1 {
		margin-bottom: -0.25rem !important; }

	.ml-md-n1,
	.mx-md-n1 {
		margin-left: -0.25rem !important; }

	.m-md-n2 {
		margin: -0.5rem !important; }

	.mt-md-n2,
	.my-md-n2 {
		margin-top: -0.5rem !important; }

	.mr-md-n2,
	.mx-md-n2 {
		margin-right: -0.5rem !important; }

	.mb-md-n2,
	.my-md-n2 {
		margin-bottom: -0.5rem !important; }

	.ml-md-n2,
	.mx-md-n2 {
		margin-left: -0.5rem !important; }

	.m-md-n3 {
		margin: -1rem !important; }

	.mt-md-n3,
	.my-md-n3 {
		margin-top: -1rem !important; }

	.mr-md-n3,
	.mx-md-n3 {
		margin-right: -1rem !important; }

	.mb-md-n3,
	.my-md-n3 {
		margin-bottom: -1rem !important; }

	.ml-md-n3,
	.mx-md-n3 {
		margin-left: -1rem !important; }

	.m-md-n4 {
		margin: -1.5rem !important; }

	.mt-md-n4,
	.my-md-n4 {
		margin-top: -1.5rem !important; }

	.mr-md-n4,
	.mx-md-n4 {
		margin-right: -1.5rem !important; }

	.mb-md-n4,
	.my-md-n4 {
		margin-bottom: -1.5rem !important; }

	.ml-md-n4,
	.mx-md-n4 {
		margin-left: -1.5rem !important; }

	.m-md-n5 {
		margin: -3rem !important; }

	.mt-md-n5,
	.my-md-n5 {
		margin-top: -3rem !important; }

	.mr-md-n5,
	.mx-md-n5 {
		margin-right: -3rem !important; }

	.mb-md-n5,
	.my-md-n5 {
		margin-bottom: -3rem !important; }

	.ml-md-n5,
	.mx-md-n5 {
		margin-left: -3rem !important; }

	.m-md-auto {
		margin: auto !important; }

	.mt-md-auto,
	.my-md-auto {
		margin-top: auto !important; }

	.mr-md-auto,
	.mx-md-auto {
		margin-right: auto !important; }

	.mb-md-auto,
	.my-md-auto {
		margin-bottom: auto !important; }

	.ml-md-auto,
	.mx-md-auto {
		margin-left: auto !important; } }



@media (min-width: 992px) {
	.m-lg-0 {
		margin: 0 !important; }

	.mt-lg-0,
	.my-lg-0 {
		margin-top: 0 !important; }

	.mr-lg-0,
	.mx-lg-0 {
		margin-right: 0 !important; }

	.mb-lg-0,
	.my-lg-0 {
		margin-bottom: 0 !important; }

	.ml-lg-0,
	.mx-lg-0 {
		margin-left: 0 !important; }

	.m-lg-1 {
		margin: 0.25rem !important; }

	.mt-lg-1,
	.my-lg-1 {
		margin-top: 0.25rem !important; }

	.mr-lg-1,
	.mx-lg-1 {
		margin-right: 0.25rem !important; }

	.mb-lg-1,
	.my-lg-1 {
		margin-bottom: 0.25rem !important; }

	.ml-lg-1,
	.mx-lg-1 {
		margin-left: 0.25rem !important; }

	.m-lg-2 {
		margin: 0.5rem !important; }

	.mt-lg-2,
	.my-lg-2 {
		margin-top: 0.5rem !important; }

	.mr-lg-2,
	.mx-lg-2 {
		margin-right: 0.5rem !important; }

	.mb-lg-2,
	.my-lg-2 {
		margin-bottom: 0.5rem !important; }

	.ml-lg-2,
	.mx-lg-2 {
		margin-left: 0.5rem !important; }

	.m-lg-3 {
		margin: 1rem !important; }

	.mt-lg-3,
	.my-lg-3 {
		margin-top: 1rem !important; }

	.mr-lg-3,
	.mx-lg-3 {
		margin-right: 1rem !important; }

	.mb-lg-3,
	.my-lg-3 {
		margin-bottom: 1rem !important; }

	.ml-lg-3,
	.mx-lg-3 {
		margin-left: 1rem !important; }

	.m-lg-4 {
		margin: 1.5rem !important; }

	.mt-lg-4,
	.my-lg-4 {
		margin-top: 1.5rem !important; }

	.mr-lg-4,
	.mx-lg-4 {
		margin-right: 1.5rem !important; }

	.mb-lg-4,
	.my-lg-4 {
		margin-bottom: 1.5rem !important; }

	.ml-lg-4,
	.mx-lg-4 {
		margin-left: 1.5rem !important; }

	.m-lg-5 {
		margin: 3rem !important; }

	.mt-lg-5,
	.my-lg-5 {
		margin-top: 3rem !important; }

	.mr-lg-5,
	.mx-lg-5 {
		margin-right: 3rem !important; }

	.mb-lg-5,
	.my-lg-5 {
		margin-bottom: 3rem !important; }

	.ml-lg-5,
	.mx-lg-5 {
		margin-left: 3rem !important; }

	.p-lg-0 {
		padding: 0 !important; }

	.pt-lg-0,
	.py-lg-0 {
		padding-top: 0 !important; }

	.pr-lg-0,
	.px-lg-0 {
		padding-right: 0 !important; }

	.pb-lg-0,
	.py-lg-0 {
		padding-bottom: 0 !important; }

	.pl-lg-0,
	.px-lg-0 {
		padding-left: 0 !important; }

	.p-lg-1 {
		padding: 0.25rem !important; }

	.pt-lg-1,
	.py-lg-1 {
		padding-top: 0.25rem !important; }

	.pr-lg-1,
	.px-lg-1 {
		padding-right: 0.25rem !important; }

	.pb-lg-1,
	.py-lg-1 {
		padding-bottom: 0.25rem !important; }

	.pl-lg-1,
	.px-lg-1 {
		padding-left: 0.25rem !important; }

	.p-lg-2 {
		padding: 0.5rem !important; }

	.pt-lg-2,
	.py-lg-2 {
		padding-top: 0.5rem !important; }

	.pr-lg-2,
	.px-lg-2 {
		padding-right: 0.5rem !important; }

	.pb-lg-2,
	.py-lg-2 {
		padding-bottom: 0.5rem !important; }

	.pl-lg-2,
	.px-lg-2 {
		padding-left: 0.5rem !important; }

	.p-lg-3 {
		padding: 1rem !important; }

	.pt-lg-3,
	.py-lg-3 {
		padding-top: 1rem !important; }

	.pr-lg-3,
	.px-lg-3 {
		padding-right: 1rem !important; }

	.pb-lg-3,
	.py-lg-3 {
		padding-bottom: 1rem !important; }

	.pl-lg-3,
	.px-lg-3 {
		padding-left: 1rem !important; }

	.p-lg-4 {
		padding: 1.5rem !important; }

	.pt-lg-4,
	.py-lg-4 {
		padding-top: 1.5rem !important; }

	.pr-lg-4,
	.px-lg-4 {
		padding-right: 1.5rem !important; }

	.pb-lg-4,
	.py-lg-4 {
		padding-bottom: 1.5rem !important; }

	.pl-lg-4,
	.px-lg-4 {
		padding-left: 1.5rem !important; }

	.p-lg-5 {
		padding: 3rem !important; }

	.pt-lg-5,
	.py-lg-5 {
		padding-top: 3rem !important; }

	.pr-lg-5,
	.px-lg-5 {
		padding-right: 3rem !important; }

	.pb-lg-5,
	.py-lg-5 {
		padding-bottom: 3rem !important; }

	.pl-lg-5,
	.px-lg-5 {
		padding-left: 3rem !important; }

	.m-lg-n1 {
		margin: -0.25rem !important; }

	.mt-lg-n1,
	.my-lg-n1 {
		margin-top: -0.25rem !important; }

	.mr-lg-n1,
	.mx-lg-n1 {
		margin-right: -0.25rem !important; }

	.mb-lg-n1,
	.my-lg-n1 {
		margin-bottom: -0.25rem !important; }

	.ml-lg-n1,
	.mx-lg-n1 {
		margin-left: -0.25rem !important; }

	.m-lg-n2 {
		margin: -0.5rem !important; }

	.mt-lg-n2,
	.my-lg-n2 {
		margin-top: -0.5rem !important; }

	.mr-lg-n2,
	.mx-lg-n2 {
		margin-right: -0.5rem !important; }

	.mb-lg-n2,
	.my-lg-n2 {
		margin-bottom: -0.5rem !important; }

	.ml-lg-n2,
	.mx-lg-n2 {
		margin-left: -0.5rem !important; }

	.m-lg-n3 {
		margin: -1rem !important; }

	.mt-lg-n3,
	.my-lg-n3 {
		margin-top: -1rem !important; }

	.mr-lg-n3,
	.mx-lg-n3 {
		margin-right: -1rem !important; }

	.mb-lg-n3,
	.my-lg-n3 {
		margin-bottom: -1rem !important; }

	.ml-lg-n3,
	.mx-lg-n3 {
		margin-left: -1rem !important; }

	.m-lg-n4 {
		margin: -1.5rem !important; }

	.mt-lg-n4,
	.my-lg-n4 {
		margin-top: -1.5rem !important; }

	.mr-lg-n4,
	.mx-lg-n4 {
		margin-right: -1.5rem !important; }

	.mb-lg-n4,
	.my-lg-n4 {
		margin-bottom: -1.5rem !important; }

	.ml-lg-n4,
	.mx-lg-n4 {
		margin-left: -1.5rem !important; }

	.m-lg-n5 {
		margin: -3rem !important; }

	.mt-lg-n5,
	.my-lg-n5 {
		margin-top: -3rem !important; }

	.mr-lg-n5,
	.mx-lg-n5 {
		margin-right: -3rem !important; }

	.mb-lg-n5,
	.my-lg-n5 {
		margin-bottom: -3rem !important; }

	.ml-lg-n5,
	.mx-lg-n5 {
		margin-left: -3rem !important; }

	.m-lg-auto {
		margin: auto !important; }

	.mt-lg-auto,
	.my-lg-auto {
		margin-top: auto !important; }

	.mr-lg-auto,
	.mx-lg-auto {
		margin-right: auto !important; }

	.mb-lg-auto,
	.my-lg-auto {
		margin-bottom: auto !important; }

	.ml-lg-auto,
	.mx-lg-auto {
		margin-left: auto !important; } }



@media (min-width: 1200px) {
	.m-xl-0 {
		margin: 0 !important; }

	.mt-xl-0,
	.my-xl-0 {
		margin-top: 0 !important; }

	.mr-xl-0,
	.mx-xl-0 {
		margin-right: 0 !important; }

	.mb-xl-0,
	.my-xl-0 {
		margin-bottom: 0 !important; }

	.ml-xl-0,
	.mx-xl-0 {
		margin-left: 0 !important; }

	.m-xl-1 {
		margin: 0.25rem !important; }

	.mt-xl-1,
	.my-xl-1 {
		margin-top: 0.25rem !important; }

	.mr-xl-1,
	.mx-xl-1 {
		margin-right: 0.25rem !important; }

	.mb-xl-1,
	.my-xl-1 {
		margin-bottom: 0.25rem !important; }

	.ml-xl-1,
	.mx-xl-1 {
		margin-left: 0.25rem !important; }

	.m-xl-2 {
		margin: 0.5rem !important; }

	.mt-xl-2,
	.my-xl-2 {
		margin-top: 0.5rem !important; }

	.mr-xl-2,
	.mx-xl-2 {
		margin-right: 0.5rem !important; }

	.mb-xl-2,
	.my-xl-2 {
		margin-bottom: 0.5rem !important; }

	.ml-xl-2,
	.mx-xl-2 {
		margin-left: 0.5rem !important; }

	.m-xl-3 {
		margin: 1rem !important; }

	.mt-xl-3,
	.my-xl-3 {
		margin-top: 1rem !important; }

	.mr-xl-3,
	.mx-xl-3 {
		margin-right: 1rem !important; }

	.mb-xl-3,
	.my-xl-3 {
		margin-bottom: 1rem !important; }

	.ml-xl-3,
	.mx-xl-3 {
		margin-left: 1rem !important; }

	.m-xl-4 {
		margin: 1.5rem !important; }

	.mt-xl-4,
	.my-xl-4 {
		margin-top: 1.5rem !important; }

	.mr-xl-4,
	.mx-xl-4 {
		margin-right: 1.5rem !important; }

	.mb-xl-4,
	.my-xl-4 {
		margin-bottom: 1.5rem !important; }

	.ml-xl-4,
	.mx-xl-4 {
		margin-left: 1.5rem !important; }

	.m-xl-5 {
		margin: 3rem !important; }

	.mt-xl-5,
	.my-xl-5 {
		margin-top: 3rem !important; }

	.mr-xl-5,
	.mx-xl-5 {
		margin-right: 3rem !important; }

	.mb-xl-5,
	.my-xl-5 {
		margin-bottom: 3rem !important; }

	.ml-xl-5,
	.mx-xl-5 {
		margin-left: 3rem !important; }

	.p-xl-0 {
		padding: 0 !important; }

	.pt-xl-0,
	.py-xl-0 {
		padding-top: 0 !important; }

	.pr-xl-0,
	.px-xl-0 {
		padding-right: 0 !important; }

	.pb-xl-0,
	.py-xl-0 {
		padding-bottom: 0 !important; }

	.pl-xl-0,
	.px-xl-0 {
		padding-left: 0 !important; }

	.p-xl-1 {
		padding: 0.25rem !important; }

	.pt-xl-1,
	.py-xl-1 {
		padding-top: 0.25rem !important; }

	.pr-xl-1,
	.px-xl-1 {
		padding-right: 0.25rem !important; }

	.pb-xl-1,
	.py-xl-1 {
		padding-bottom: 0.25rem !important; }

	.pl-xl-1,
	.px-xl-1 {
		padding-left: 0.25rem !important; }

	.p-xl-2 {
		padding: 0.5rem !important; }

	.pt-xl-2,
	.py-xl-2 {
		padding-top: 0.5rem !important; }

	.pr-xl-2,
	.px-xl-2 {
		padding-right: 0.5rem !important; }

	.pb-xl-2,
	.py-xl-2 {
		padding-bottom: 0.5rem !important; }

	.pl-xl-2,
	.px-xl-2 {
		padding-left: 0.5rem !important; }

	.p-xl-3 {
		padding: 1rem !important; }

	.pt-xl-3,
	.py-xl-3 {
		padding-top: 1rem !important; }

	.pr-xl-3,
	.px-xl-3 {
		padding-right: 1rem !important; }

	.pb-xl-3,
	.py-xl-3 {
		padding-bottom: 1rem !important; }

	.pl-xl-3,
	.px-xl-3 {
		padding-left: 1rem !important; }

	.p-xl-4 {
		padding: 1.5rem !important; }

	.pt-xl-4,
	.py-xl-4 {
		padding-top: 1.5rem !important; }

	.pr-xl-4,
	.px-xl-4 {
		padding-right: 1.5rem !important; }

	.pb-xl-4,
	.py-xl-4 {
		padding-bottom: 1.5rem !important; }

	.pl-xl-4,
	.px-xl-4 {
		padding-left: 1.5rem !important; }

	.p-xl-5 {
		padding: 3rem !important; }

	.pt-xl-5,
	.py-xl-5 {
		padding-top: 3rem !important; }

	.pr-xl-5,
	.px-xl-5 {
		padding-right: 3rem !important; }

	.pb-xl-5,
	.py-xl-5 {
		padding-bottom: 3rem !important; }

	.pl-xl-5,
	.px-xl-5 {
		padding-left: 3rem !important; }

	.m-xl-n1 {
		margin: -0.25rem !important; }

	.mt-xl-n1,
	.my-xl-n1 {
		margin-top: -0.25rem !important; }

	.mr-xl-n1,
	.mx-xl-n1 {
		margin-right: -0.25rem !important; }

	.mb-xl-n1,
	.my-xl-n1 {
		margin-bottom: -0.25rem !important; }

	.ml-xl-n1,
	.mx-xl-n1 {
		margin-left: -0.25rem !important; }

	.m-xl-n2 {
		margin: -0.5rem !important; }

	.mt-xl-n2,
	.my-xl-n2 {
		margin-top: -0.5rem !important; }

	.mr-xl-n2,
	.mx-xl-n2 {
		margin-right: -0.5rem !important; }

	.mb-xl-n2,
	.my-xl-n2 {
		margin-bottom: -0.5rem !important; }

	.ml-xl-n2,
	.mx-xl-n2 {
		margin-left: -0.5rem !important; }

	.m-xl-n3 {
		margin: -1rem !important; }

	.mt-xl-n3,
	.my-xl-n3 {
		margin-top: -1rem !important; }

	.mr-xl-n3,
	.mx-xl-n3 {
		margin-right: -1rem !important; }

	.mb-xl-n3,
	.my-xl-n3 {
		margin-bottom: -1rem !important; }

	.ml-xl-n3,
	.mx-xl-n3 {
		margin-left: -1rem !important; }

	.m-xl-n4 {
		margin: -1.5rem !important; }

	.mt-xl-n4,
	.my-xl-n4 {
		margin-top: -1.5rem !important; }

	.mr-xl-n4,
	.mx-xl-n4 {
		margin-right: -1.5rem !important; }

	.mb-xl-n4,
	.my-xl-n4 {
		margin-bottom: -1.5rem !important; }

	.ml-xl-n4,
	.mx-xl-n4 {
		margin-left: -1.5rem !important; }

	.m-xl-n5 {
		margin: -3rem !important; }

	.mt-xl-n5,
	.my-xl-n5 {
		margin-top: -3rem !important; }

	.mr-xl-n5,
	.mx-xl-n5 {
		margin-right: -3rem !important; }

	.mb-xl-n5,
	.my-xl-n5 {
		margin-bottom: -3rem !important; }

	.ml-xl-n5,
	.mx-xl-n5 {
		margin-left: -3rem !important; }

	.m-xl-auto {
		margin: auto !important; }

	.mt-xl-auto,
	.my-xl-auto {
		margin-top: auto !important; }

	.mr-xl-auto,
	.mx-xl-auto {
		margin-right: auto !important; }

	.mb-xl-auto,
	.my-xl-auto {
		margin-bottom: auto !important; }

	.ml-xl-auto,
	.mx-xl-auto {
		margin-left: auto !important; } }


/*# sourceMappingURL=bootstrap-grid.css.map */

.a-1 {
	background-image: url('../assets/images/about/bg-1.png');
	background-size: cover;
	background-position: left;
	background-repeat: no-repeat;
	&__content {
		.block-title {
			margin-bottom: 30px; }
		.left {
			.desc {
				p {
					font-size: 16px;
					font-family: "Montserrat";
					color: #666666;
					margin-bottom: 30px;
					line-height: 28px;
					strong {
						font-size: 16px;
						font-family: "Montserrat";
						color: rgb(102, 102, 102);
						font-weight: bold; }
					&:last-child {
						margin-bottom: 0; } } } }
		.img {
			padding-top: 25px;
			width: 100%;
			height: 333px;
			position: relative;
			img {
				width: 100%;
				height: 100%;
				object-fit: cover; }
			&::after {
				position: absolute;
				width: 100%;
				height: 25px;
				top: 0;
				left: 25px;
				background-color: rgb(26, 119, 185);
				content: ''; }
			&::before {
				position: absolute;
				width: 25px;
				height: 92.5%;
				top: 0;
				right: -25px;
				background-color: rgb(26, 119, 185);
				content: ''; } }
		.a-1__content {
			.left {
				.desc {
					p {
						margin-bottom: 15px;
						&:last-child {
							margin-bottom: 0; } } } }
			.img {
				padding-top: 15px;
				&::after,
				&::before {
					display: none; } } } }
	@media screen and ( max-width: 575px) {
		#sidebar-wrapper {
			top: 40%; } } }
.a-2 {
	width: 100%;
	height: 100%;
	background-color: rgb(239, 248, 255);
	.block-title {
		margin-bottom: 30px; }
	.desc {
		margin-bottom: 110px;
		p {
			font-size: 16px;
			font-family: "Montserrat";
			color: rgb(51, 51, 51); } }
	.history-slide {
		position: relative;
		.swiper-slide {
			figure {
				cursor: pointer;
				.img {
					width: 100%;
					height: 212px;
					margin-bottom: 30px;
					img {
						width: 100%;
						height: 100%;
						object-fit: cover;
						filter: grayscale(100%);
						transition: all .3s ease-in-out; } }
				figcaption {
					.content {
						overflow: hidden;
						text-overflow: ellipsis;
						display: -webkit-box;
						-webkit-line-clamp: 3;
						-webkit-box-orient: vertical;
						p {
							font-size: 16px;
							font-family: "Montserrat";
							color: rgb(51, 51, 51); } } }
				&:hover {
					.img {
						img {
							filter: grayscale(0);
							transition: all .3s ease-in-out; } } } } }
		.swiper-pagination {
			width: 100%;
			margin-top: -80px;
			.swiper-pagination-bullet {
				position: relative;
				background: rgb(255, 255, 255);
				box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.05);
				width: 33.33%;
				height: 10px;
				border-radius: 0;
				opacity: unset;
				h3 {
					position: absolute;
					left: 0;
					bottom: -40px;
					font-size: 24px;
					font-family: "Montserrat";
					color: rgb(102, 102, 102);
					font-weight: bold; }
				&.swiper-pagination-bullet-active {
					background-color: rgb(26, 119, 185);
					border: none;
					h3 {
						color: rgb(26, 119, 185); } } } } }
	@media screen and ( max-width: 375px) {
		.desc {
			margin-bottom: 20px; }
		.history-slide {
			.swiper-slide {
				figure {
					.img {
						margin-bottom: 20px; } } }
			.swiper-pagination {
				display: none; } } } }



.a-3 {
	width: 100%;
	height: 100%;
	.block-title {
		margin-bottom: 38px; }
	.left {
		ul {
			list-style: none;
			padding-left: 0;
			li {
				margin-bottom: 40px;
				h3 {
					font-size: 18px;
					font-family: "Montserrat";
					color: rgb(51, 51, 51);
					font-weight: bold;
					margin-bottom: 15px; }
				h4 {
					display: flex;
					font-size: 24px;
					font-family: "Montserrat";
					color: rgb(26, 119, 185);
					font-weight: bold; }

				p {
					font-size: 16px;
					font-family: "Montserrat";
					color: rgb(51, 51, 51); } } } }

	.right {
		.img {
			width: 525px;
			height: 350px;
			margin-left: auto;
			img {
				width: 100%;
				height: 100%;
				object-fit: cover; } } }


	@media screen and ( max-width: 768px) {
		.block-title {
			margin-bottom: 20px; }
		.left {
			ul {
				li {
					margin-bottom: 20px; } } }
		.right {
			.img {
				width: 100%;
				img {
					object-fit: contain; } } } }
	@media screen and ( max-width: 375px) {
		.left {
			ul {
				li {
					margin-bottom: 10px;
					h3 {
						margin-bottom: 0; } } } }
		.right {
			.img {
				width: 100%;
				height: auto; } } } }
.a-4 {
	width: 100%;
	height: 100%;
	background-color: #f9f9f9;
	.block-title {
		margin-bottom: 30px; }
	.left,
	.right {
		figure {
			position: relative;
			width: 100%;
			height: 213px;
			margin-bottom: 25px;
			cursor: pointer;
			.img {
				width: 100%;
				height: 100%;
				overflow: hidden;
				img {
					width: 100%;
					height: 100%;
					object-fit: cover;
					transform: scale(1);
					transition: all ease-in-out .3s; } }
			figcaption {
				position: absolute;
				top: 0;
				left: 0;
				height: 100%;
				width: 100%;
				padding: 20px;
				display: flex;
				align-items: flex-end;
				transition: all ease-in-out .3s;
				.desc {
					width: 100%;
					max-height: 135px;
					overflow: hidden;
					h3 {
						font-size: 18px;
						font-family: "Montserrat";
						color: rgb(255, 255, 255);
						font-weight: bold;
						margin-bottom: 0;
						transition: all ease-in-out .3s; }
					p {
						font-size: 14px;
						font-family: "Montserrat";
						color: rgb(255, 255, 255);
						line-height: 1.429;
						transition: all ease-in-out .3s;
						visibility: hidden;
						opacity: 0;
						display: none; } } }
			&:hover {
				.img {
					img {
						transform: scale(1.1); } }
				figcaption {
					background-color: rgba($color: #000, $alpha: .5);
					.desc {
						h3 {
							margin-bottom: 20px; }
						p {
							display: block;
							opacity: 1;
							visibility: visible; } } } } } }
	.right {
		figure {
			height: 450px;
			.img {
				img {
					object-position: right;
					object-fit: cover; } } } }
	@media screen and ( max-width: 575px) {
		.left,
		.right {
			figure {
				height: auto;
				figcaption {
					.desc {
						max-height: 150px; } } } } } }

.a-5 {
	width: 100%;
	height: 100%;
	.block-title {
		margin-bottom: 40px; }
	.left {
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 14;
		-webkit-box-orient: vertical;
		p {
			font-size: 16px;
			font-family: "Montserrat";
			color: rgb(102, 102, 102);
			line-height: 1.5;
			text-align: left; } }
	.right {
		width: 525px;
		height: 350px;
		img {
			width: 100%;
			height: 100%;
			object-position: left;
			object-fit: cover; } }
	@media screen and ( max-width:  1024px) {
		.right {
			width: 100%; } }
	@media screen and ( max-width:  575px) {
		.block-title {
			margin-bottom: 20px; }
		.left {
			margin-bottom: 20px; }
		.right {
			height: auto; } } }
.a-6 {
	width: 100%;
	height: 100%;
	background-image: url('../assets/images/index/h7-bg.png');
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	.block-title {
		margin-bottom: 40px;
		&::before {
			background-color: #ffffff; }
		h2 {
			color: #ffffff; } }
	.content {
		width: 100%;
		background-color: rgba(255, 255, 255, .3);
		.col {
			padding: 0;
			display: flex;
			flex: 1 0 25%;
			height: 208px;
			&:nth-child(1),
			&:nth-child(3),
			&:nth-child(6),
			&:nth-child(8) {
				background-color: rgba(246, 246, 246, .3); }
			@media screen and ( max-width: 1280px) {
				flex: 1 0 25%;
				height: 180px; }
			@media screen and ( max-width: 1024px) {
				flex: 1 0 33.333%;
				&:nth-child(1n), {
					background-color: rgba(246, 246, 246, .3); } }
			@media screen and ( max-width: 768px) {
				flex: 1 0 50%;
				padding: 15px 0; }

			.item {
				width: 100%;
				height: 100%;
				padding: 20px;
				transition: all .5s ease-in-out;
				a {
					display: flex;
					width: 100%;
					height: 100%;
					align-items: center;
					justify-content: center;
					img {
						filter: brightness(0) invert(1);
						transition: all .5s ease-in-out; } }
				&:hover {
					background-color: #ffffff;
					transition: all .5s ease-in-out;
					img {
						filter: brightness(1) invert(0);
						transition: all .5s ease-in-out; } } } } } }


.Cr-banner {
	position: relative;
	margin-left: auto;
	width: 100%;

	@media screen and (max-width: 997px) {
		// padding-top: 70px
		padding-top: 0; }


	&__slide {
		position: relative;
		height: 100vh;

		.swiper-slide {
			height: 100%;
			position: relative;
			overflow: hidden;

			.swiper-inner {
				width: 100%;
				height: 100%;
				display: block;
				img {
					width: 100%;
					height: 100%;
					object-fit: cover; } }



			.content {
				position: absolute;
				bottom: 20%;
				left: 50%;
				transform: translateX(-50%);
				width: fit-content;
				text-align: center;

				h1 {
					font-size: 48px;
					font-family: "Sarabun";
					color: rgb(255, 255, 255);
					font-weight: bold;
					text-align: center;
					line-height: 48px;
					margin-bottom: 10px; }

				h2 {
					text-align: center;
					font-size: 36px;
					line-height: 48px;
					font-family: 'Sarabun';
					font-weight: 400;
					color: #fff; }
				@media screen and ( max-width: 1024px) {
					h1 {
						font-size: 33.6px;
						line-height: unset;
						margin-bottom: 0; }
					h2 {
						font-size: 25.2px;
						line-height: unset; } }
				@media screen and ( max-width: 575px) {
					h1 {
						font-size: 23.52px; }
					h2 {
						font-size: 17.64px; } }
				@media screen and ( max-width: 375px) {
					h1 {
						font-size: 20px; }
					h2 {
						font-size: 12.5px; } } }
			// .icon
			// 	width: 130px
			// 	height: 130px
			// 	position: absolute
			// 	top: 50%
			// 	left: 50%
			// 	transform: translate(-50%, -50%)
			// 	z-index: 2

			//mg
			//width: 100%
			//height: 100%
 }			//object-fit: cover




		.crolldown {
			position: absolute;
			left: 50%;
			bottom: 15%;
			width: 24px;
			height: 24px;
			z-index: 9;
			transform: translateX(-50%);
			span.ri-arrow-down-line {
				font-size: 24px;
				color: #ffffff;
				position: absolute;
				left: 50%;
				bottom: 0;
				-webkit-animation: sdb07 2s infinite;
				animation: sdb07 2s infinite;
				opacity: 0;
				box-sizing: border-box;

				&:nth-of-type(1) {
					-webkit-animation-delay: 0s;
					animation-delay: 0s; }


				&:nth-of-type(2) {
					bottom: -50%;
					-webkit-animation-delay: .15s;
					animation-delay: .15s; }


				&:nth-of-type(3) {
					bottom: -100%;
					-webkit-animation-delay: .3s;
					animation-delay: .3s; } }
			@media screen and ( max-width: 1024px) {
				bottom: 10%; }

			@-webkit-keyframes sdb07 {
				0% {
					opacity: .5; }


				50% {
					opacity: 1; }


				100% {
					opacity: 0; } }



			@keyframes sdb07 {
				0% {
					opacity: 0; }


				50% {
					opacity: 1; }


				100% {
					opacity: 0; } } } }

	.swiper-button-prev,
	.swiper-button-next {
		position: absolute;
		background-image: none;
		width: 50px;
		height: 50px;
		visibility: hidden;
		opacity: 0;
		transition: all .3s ease-in-out;
		border: none;
		display: flex;
		align-items: center;
		justify-content: center; }


	.swiper-button-prev {
		left: 10px;

		&::after {
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			content: '\e875';
			font-family: 'Linearicons-Free';
			font-size: 30px;
			color: #fff;
			font-weight: 900; } }



	.swiper-button-next {
		right: 0;

		&::after {
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			content: '\e876';
			font-family: 'Linearicons-Free';
			font-size: 30px;
			color: #fff;
			font-weight: 900; } }

	&__list {
		position: absolute;
		width: 100%;
		bottom: 0;
		left: 0;
		z-index: 2;
		.col-xl-2 {
			padding: 0 8px;
			&:first-child {
				padding-left: 15px; }
			&:last-child {
				padding-right: 15px; } }
		.item {
			position: relative;
			background-image: linear-gradient( 0deg, rgb(29,213,230) 0%, rgb(70,174,247) 100%);
			box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.05);
			height: 80px;
			overflow: hidden;
			&::before {
				content: '';
				background-color: rgb(255, 255, 255);
				opacity: 0.2;
				position: absolute;
				left: -60%;
				top: -115%;
				transform: translateY(-50%);
				width: 100%;
				height: 200%;
				z-index: 3;
				transform: rotate(45deg); }
			.nav-link {
				padding-left: 30px;
				height: 100%;
				display: flex;
				align-items: center;
				i {
					font-size: 36px;
					color: rgb(255, 255, 255);
					text-align: center;
					padding-right: 15px; }
				p {
					font-size: 16px;
					font-family: "Sarabun";
					color: rgb(255, 255, 255); } }
			&:hover {
				background-color: #ed1b24;
				background-image: linear-gradient( -45deg,  rgba($color: #fff, $alpha: .3) 0%, rgba($color: #fff, $alpha: .2) 100%); }
			&:first-child {
				margin-left: 0; } }
		@media screen and ( max-width: 1199px) {
			position: static;
			padding-top: 30px;
			.col-xl-2 {
				padding: 0 15px;
				.item {
					margin-bottom: 30px;
					.nav-link {
						padding-left: 10px; }
					&::before {
						top: -160%; } } } }
		@media screen and ( max-width: 768px) {
			.col-xl-2 {
				.item {
					&::before {
						top: -120%;
						left: -50%; } } } }
		@media screen and ( max-width: 600px) {
			.col-xl-2 {
				.item {
					&::before {
						top: -140%;
						left: -50%; } } } } }
	@media screen and (max-width: 1024px) {
		&__slide {
			height: 50vh; } } }

#page-banner {
	width: 100%;
	position: relative;

	.banner__pages {
		width: 100%;
		height: 40vh;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover; }

		@media screen and ( max-width: 1024px) {
			height: 30vh; } }
	.global-breadcrumb {
		position: absolute;
		bottom: 0;
		left: 0;
		background-color: rgba($color: #000, $alpha: .5);
		.breadcrumb {
			li {
				height: 100%;
				border-right: solid 1px #fff;
				position: relative;
				padding: 0 10px;
				display: flex;
				align-items: center;
				a {
					font-size: 14px;
					font-family: "Montserrat";
					color: rgb(255, 255, 255);

					@media(max-width: 991.98px) {
						font-size: 10px; } }

				&:hover {
					a {
						color: $main-color; } }

				&:first-child {
					a {
						span {
							position: relative;
							font-size: 0;
							&::before {
								content: 'home';
								font-size: 14px;
								color: #ffffff;
								font-family: 'Material Icons'; } } } } } } } }

.dnn-contact {
	width: 100%;
	height: 100%;
	background-image: url('../assets/images/index/contact.png');
	background-position: center;
	background-repeat: no-repeat;
	.block-title {
		margin-bottom: 30px; }
	&__infor {
		background-color: rgba($color: #d1e4f1, $alpha: .54);
		padding-left: 75px;
		padding-right: 80px;
		height: 495px;
		display: flex;
		align-items: center;

		&__logo {
			width: 185px;
			height: 54px;
			margin: 0 auto;

			img {
				width: 100%;
				height: 100%; } }



		&__title {
			margin-top: 30px;

			h2 {
				font-size: 16px;
				font-family: "Montserrat";
				color: #333333;
				font-weight: bold; } }





		&__address {
			padding-top: 40px;

			ul {
				padding: 0;
				list-style: none;

				li {
					display: flex;
					margin-bottom: 15px;

					span .material-icons {
						padding-right: 10px;
						font-size: 20px;
						font-family: "Material Icons";
						color: #333333; }


					p {
						font-size: 15px;
						font-family: "Montserrat";
						color: #333333; } } } }




		@media screen and ( max-width: 1024px) {
			padding: 15px; } }



	.wrap-form>div {
		display: flex;
		flex-wrap: wrap;
		width: 100%;
		span {
			color: red;
			font-size: 13px;
			font-family: 'Montserrat';
			font-weight: normal;

			&::before {
				content: ''; } } }




	.box-form {
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		.form-group {
			flex: 0 0 100%;
			margin-bottom: 20px;
			&:nth-child(5) {
				padding-right: 0; }


			&:nth-child(2n) {
				padding-right: 0; }


			@media(max-width: 1024px) {
				margin-bottom: 15px; } }



		.form-group input {
			border: solid 1px #e1e1e1;
			width: 100%;
			height: 45px;
			padding-left: 15px;

			&::placeholder {
				font-size: 16px; } }



		.form-title {
			-webkit-box-flex: 0;
			-ms-flex: 0 0 100%;
			flex: 0 0 100%; }


		.qparagraph {
			flex: 0 0 100%;
			height: 100px;
			margin-bottom: 15px;

			@media(max-width: 1024px) {
				margin-bottom: 15px; }


			textarea {
				border: 1px solid #e1e1e1;
				width: 100%;
				height: 100%;
				color: #333;
				caret-color: #064975;
				font-size: 15px;
				padding-left: 15px; } }



		.frm-captcha {
			display: flex;
			flex-direction: row-reverse;
			align-items: center;
			justify-content: flex-end;
			margin: 0;
			flex: none;
			padding: 0;
			float: left;
			width: auto;

			.RadCaptcha {
				position: relative;
				width: unset !important;

				em {
					position: absolute;
					bottom: 100%;
					color: red; } }


			.RadCaptcha>div>div {
				display: flex;
				align-items: center;
				position: relative; }

			.RadCaptcha {
				height: 40px;
				display: flex;
				flex-direction: row;
				align-items: center;
				margin: 0;
				position: relative;
				span {
					position: absolute;
					bottom: -30px;
					left: 0; }

				img {
					height: 40px !important; }


				.rcRefreshImage {
					font-size: 0;
					text-decoration: none;
					margin-left: 25px;

					&::after {
						content: 'replay';
						font-family: 'Material Icons';
						font-size: 30px;
						color: #999999; }


					@media screen and (max-width: 1025px) {
						margin-left: 0; } } }




			.frm-captcha-input {
				.label {
					display: none;

					height: 50px;
					width: 170px;
					display: flex;
					flex-direction: row-reverse;
					align-items: center;
					justify-content: flex-end;
					margin-left: 25px; }

				input {
					margin-bottom: 0;
					height: 100%; }


				@media screen and (max-width: 1025px) {
					margin-left: 0;
					width: unset; }


				@media screen and (max-width: 480px) {
					margin-bottom: 15px; } }



			@media(max-width: 1024px) {
				width: unset;

				&.frm-captcha-input {
					width: 100%;
					margin-top: 15px; } }



			@media(max-width: 768px) {
				width: 100%; } }



		.frm-btnwrap {
			margin-left: auto;
			margin-bottom: 0;
			flex: none;
			padding: 0;
			float: right;
			width: 170px;

			label {
				display: none; }


			.frm-btn {
				position: relative;
				transition: all ease-in-out .3s;
				border: none;
				background-color: rgb(26, 119, 186);
				width: 190px;
				height: 50px;
				border-radius: 50px;
				input {
					text-align: center;
					border-radius: 0;
					padding-left: 0;
					font-size: 16px;
					font-family: "Montserrat";
					color: rgb(255, 255, 255);
					font-weight: bold;
					text-transform: uppercase;
					border: none; }


				.ri-arrow-right-line {
					position: absolute;
					right: 20px;
					top: 50%;
					transform: translateY(-50%);
					font-size: 18px;
					font-family: "remixicon";
					color: rgb(255, 255, 255); }

				@media(max-width: 991.98px) {
					width: 120px;
					font-size: 14px; }

				&:hover {
					border-radius: 0;
					transition: all ease-in-out .3s; } }



			@media(max-width: 1024px) {
				width: unset;
				margin-top: 15px;
				float: left; }


			.frm-btn-reset {
				display: none; } }



		@media screen and (max-width: 1024px) {
			margin-left: 0;
			margin-top: 30px; } } }






@media(max-width: 991.98px) {

	&__content {
		&__infor {
			margin-bottom: 25px;

			&__address>ul {
				li {
					margin-bottom: 7px; } }



			&__title>h4 {
				font-size: 18px;
				margin-bottom: 15px; } }



		&__form {
			.form-group input {
				margin-bottom: 0; } } } }




.map-wrapper {
	width: 100%;
	height: 63.985vh;
	padding-top: 0;

	iframe {
		width: 100%;
		height: 100%; }


	@media(max-width: 991.98px) {
		padding: 0; } }


.dnn-hr-detail {
	.dnn-hr-detail__left {
		.block-title {
			margin-bottom: 20px;
			&::before {
				display: none; }
			h1 {
				font-size: 24px;
				font-family: "Montserrat";
				color: rgb(26, 119, 185);
				font-weight: bold; }
			@media screen and ( max-width: 1024px) {
				margin-bottom: 0; } }
		.dnn-hr-detail__left__timeline {
			width: 100%;
			padding: 0 0 10px 0;
			margin-bottom: 20px;
			display: flex;
			align-items: center;
			border-bottom: solid 1px rgb(241, 241, 241);

			.time {
				display: flex;
				align-items: center;
				flex: 1;
				text-align: left;
				font-size: 12px;
				font-family: "Montserrat";
				color: #999999; }
			.icon-social {
				flex: 1;

				ul {
					width: 100px;
					display: flex;
					align-items: center;
					list-style: none;
					justify-content: flex-end;
					margin-left: auto;

					li {
						a {
							.fa {
								color: #999999;
								font-size: 13px; } }
						padding: 0 15px;
						&:first-child {
							margin-right: 10px; } } } }

			@media(max-width: 991.98px) {
				padding: 0;
				margin-bottom: 7px;
				.time {
					p {
						font-size: 13px; } } } }
		.dnn-hr-detail__left__content {
			padding: 15px 20px;
			background-color: #ebf8ff;
			margin-bottom: 30px;
			table {
				width: 100%;
				margin-bottom: 0;
				border: none;
				tbody {
					width: 100%;
					tr {
						display: flex;
						border-bottom: solid 1px #cccccc;
						&:last-child {
							border: none; }
						td {
							padding: 15px 0;
							flex: 1;
							&:nth-child(1) {
								font-size: 16px;
								font-family: "Montserrat";
								color: rgb(26, 119, 185);
								font-weight: bold; }
							h2 {
								font-size: 16px;
								font-family: "Montserrat";
								color: rgb(26, 119, 185);
								font-weight: bold; }

							p {
								font-size: 16px;
								font-family: "Montserrat";
								color: rgb(51, 51, 51); }

							&:nth-child(2) {
								padding-left: 100px;
								font-size: 16px;
								font-family: "Montserrat";
								color: rgb(51, 51, 51);
								@media screen and ( max-width: 575px) {
									padding: 15px 0;
									text-align: center; } } } } } }
			@media screen and ( max-width: 768px) {
				padding: 15px; } }
		.dnn-hr-detail__left__list {
			margin-bottom: 50px;
			h2 {
				font-size: 20px;
				font-family: "Montserrat";
				color: rgb(26, 119, 185);
				font-weight: bold;
				text-transform: uppercase; }

			ul {
				padding-top: 25px;
				padding-left: 0;
				list-style: none;

				li {
					position: relative;
					padding-bottom: 15px;
					padding-left: 20px;

					p {
						font-size: 16px;
						font-family: "Montserrat";
						color: rgb(51, 51, 51); }

					&::before {
						content: 'fiber_manual_record	';
						position: absolute;
						top: 10px;
						left: 0;
						font-family: 'Material Icons';
						font-size: 6px;
						color: #999999; } } }
			&:nth-child(3) {
				margin-bottom: 30px; } }
		#form-inner {
			width: 810px;
			@media screen and ( max-width: 1199px) {
				width: 100%; }
			#form-hr {
				width: 100%;
				.box-form {
					width: 100%;
					h2 {
						font-size: 20px;
						font-family: "Montserrat";
						color: rgb(26, 119, 185);
						font-weight: bold;
						text-transform: uppercase;
						margin-bottom: 10px; }
					.form-parent {
						display: flex;
						.form-group {
							flex: 1 50%;
							label {
								display: none; }
							span {
								color: red;
								font-family: 'Montserrat';
								font-weight: 500;
								&::before {
									font-family: 'Font Awesome 5 Free';
									font-weight: 900; } }
							input {
								border-width: 1px;
								border-color: rgb(225, 225, 225);
								border-style: solid;
								background-color: rgb(255, 255, 255);
								width: 388px;
								height: 43px;
								margin-right: 29px;
								&:last-child {
									margin-right: 0; } }
							textarea {
								border-width: 1px;
								border-color: rgb(225, 225, 225);
								border-style: solid;
								background-color: rgb(255, 255, 255);
								width: 100%;
								height: 138px; } }
						.frm-captcha {
							display: flex;
							float: left;
							.frm-captcha-input {
								input {
									margin-right: 15px; } } }
						.frm-btnwrap {
							float: right;
							@media screen and ( max-width: 575px) {
								float: none; }
							.frm-btn {
								display: flex;
								align-items: center;
								background-color: rgb(26, 119, 186);
								width: 160px;
								height: 40px;
								padding: 0 50px;
								border-radius: 20px;
								input {
									background-color: transparent;
									border: none;
									font-size: 14px;
									font-family: "Montserrat";
									color: #ffffff;
									font-weight: bold;
									text-transform: uppercase;
									padding: 0; }

								.material-icons {
									padding-left: 25px;
									font-size: 18px;
									font-family: "Material Icons";
									color: #ffffff; } } }
						@media screen and ( max-width: 1199px) {
							width: 100%;
							flex-direction: column;
							.form-group {
								margin-bottom: 0;
								flex: 1 100%;
								input {
									width: 100%;
									margin-bottom: 15px; } }
							.frm-btnwrap {
								.frm-btn {
									input {
										margin: 0; } } } } }
					.wrap-form {
						.frm-captcha {
							display: flex;
							float: left;
							.frm-captcha-input {
								label {
									display: none; }
								input {
									margin-right: 15px;
									width: 390px;
									height: 45px;
									background-color: #ffffff;
									border: solid 1px #e1e1e1;
									@media screen and ( max-width: 666px) {
										width: auto; } } }
							@media screen and ( max-width: 480px) {
								flex-direction: column;
								width: 100%;
								justify-content: center; }
							.RadCaptcha_Default {
								display: flex;
								position: relative;
								span {
									position: absolute;
									top: 100%; }
								div {
									div {
										display: flex;
										flex-direction: row-reverse;
										align-items: center;
										.rcRefreshImage {
											font-size: 0;
											position: relative;
											#ctl00_altContent2_ctl00_captcha_CaptchaImageUP {
												height: 45px; }
											&::before {
												content: 'replay';
												font-family: 'Material Icons';
												font-size: 30px; } } } } } }
						.frm-btnwrap {
							float: right;
							label {
								display: none; }
							@media screen and ( max-width: 835px) {
								float: none; }
							.frm-btn {
								display: flex;
								justify-content: center;
								align-items: center;
								background-color: rgb(26, 119, 186);
								width: 160px;
								height: 40px;
								padding: 0 50px;
								border-radius: 20px;

								input {
									background-color: transparent;
									border: none;
									font-size: 14px;
									font-family: "Montserrat";
									color: #ffffff;
									font-weight: bold;
									text-transform: uppercase;
									padding: 0; }
								.frm-btn-reset {
									display: none; }
								.material-icons {
									padding-left: 25px;
									font-size: 18px;
									font-family: "Material Icons";
									color: #ffffff; } } } } } } } }
	.dnn-hr-detail__right {
		width: 100%;
		.dnn-hr-detail__right__content {
			padding-top: 30px;
			position: sticky;
			top: 80px;
			.block-title {
				margin-bottom: 0;
				background-color: #1a77b9;
				height: 50px;
				&::before {
					display: none; }
				h2 {
					font-size: 18px;
					font-family: "Montserrat";
					color: #ffffff;
					font-weight: bold;
					line-height: 50px;
					padding-bottom: 10px;
					text-transform: uppercase;
					@media screen and ( max-width: 1024px) {
						font-size: 14px; } } }

			.dnn-hr-detail__right__content__list {
				padding: 35px;
				padding-top: 0;
				list-style: none;
				background-color: #f3f3f3;
				.dnn-hr-detail__right__content__list__item {
					padding: 15px 0;
					border-bottom: solid 1px #f1f1f1;

					.date {
						display: flex;
						align-items: center;
						font-size: 14px;
						font-family: "Montserrat";
						color: rgb(153, 153, 153);
						line-height: 20px;
						padding-bottom: 15px;

						.material-icons {
							font-size: 16px;
							color: rgb(153, 153, 153);
							padding-right: 10px; } }


					h2 {
						font-size: 14px;
						font-family: "Montserrat";
						color: rgb(51, 51, 51);
						font-weight: bold; }

					&:hover {
						h2 {
							color: $main-color; } } } } }
		@media screen and ( max-width: 1024px) {
			.dnn-hr-detail__right__content {
				position: relative;
				top: 0; } } } }


.apply-frm {
	max-width: 1230px;
	padding: 30px 105px;
	margin: 0 auto;

	div {
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		position: relative; }


	h4 {
		font-size: 16px;
		font-weight: 600;
		color: $main-color;
		text-transform: uppercase;
		margin-bottom: 20px; }


	.col-left {
		.form-group {
			width: 50%;
			padding: 0 10px;

			.label {
				font-size: 14px;
				font-weight: 400;
				color: #999;
				margin-bottom: 4px; }


			.fa-exclamation-triangle {
				top: 50%;
				transform: translateY(-50%);
				right: 15px;
				color: red; }


			.form-control {
				border-radius: 0;
				width: 100%;
				width: 100%;
				border: 1px solid #e5e5e5;
				color: #333;
				font-size: 13px;
				padding: 7px 15px; } } }




	.col-right {
		flex: 0 0 100%;
		max-width: 100%;
		display: flex;
		flex-wrap: wrap;

		textarea {
			height: 200px;
			width: 100%;
			border: 1px solid #e5e5e5;
			color: #333;
			font-size: 13px;
			padding: 7px 15px;
			border-radius: 0; }


		.form-group {
			margin-bottom: 10px;
			flex: 0 0 100%;
			max-width: 100%;
			padding: 0 10px;

			label {
				.required {
					color: red; } } } }





	.RadUpload {
		width: auto !important;

		.ruFileWrap {
			position: relative;
			display: block;
			height: 50px;

			.ruBrowse,
			.ruFileInput {
				width: 120px;
				height: 50px;
				background: #e5e5e5;
				text-transform: uppercase;
				font-size: 15px;
				display: flex;
				justify-content: center;
				align-items: center;
				border: 0; } }



		.ruDropZone {
			display: none; } }


	.frm-btnwrap {
		padding: 0 10px;
		.frm-btn {
			margin-top: 20px;
			text-align: left;

			input[type=submit] {
				height: 40px;
				width: 100%;
				max-width: 180px;
				display: inline-flex;
				justify-content: center;
				align-items: center;
				border-radius: 0;
				background: 0 0;
				border: 2px solid #ed1c24;
				color: #ed1c24;
				text-transform: uppercase; } } } }




@media screen and ( max-width: 1024px) {
	.fancybox-slide {
		padding: 15px; }

	.apply-frm {
		padding: 30px 0; } }


@media screen and ( max-width: 576px) {
	.apply-frm {
		h4 {
			font-size: 14px; }

		.col-left {
			.form-group {
				width: 100%; } }


		.col-right {
			label {
				font-size: 14px;
				.required {
					font-size: 10px; } } } } }





.dnn-hr {
	width: 100%;

	.block-title {
		margin-bottom: 30px; }
	.content {
		width: 100%;
		background-color: #f1f1f1;
		.left {
			.img {
				width: 100%;
				height: 450px;
				img {
					width: 100%;
					height: 100%;
					object-fit: cover; } }
			@media screen and ( max-width: 575px) {
				.img {
					height: auto; } } }
		.right {
			width: 100%;
			height: 100%;
			display: flex;
			align-items: center;

			.content {
				padding: 0 60px;

				h3 {
					padding-bottom: 30px;
					font-size: 30px;
					font-family: "Montserrat";
					color: rgb(26, 119, 185);
					font-weight: bold;

					@media(max-width: 991.98px) {
						padding: 10px 0;
						h3 {
							font-size: 20px; } } }

				.desc {
					p {
						font-size: 16px;
						font-family: "Montserrat";
						color: rgb(102, 102, 102); } }

				@media(max-width: 991.98px) {
					padding: 15px;
					&__desc {
						p {
							font-size: 14px; } } } } } } }
.dnn-hr3 {
	padding-top: 0;
	.block-title {
		margin-bottom: 30px;
		h2 {
			font-size: 24px;
			font-family: "Montserrat";
			color: #1a77b9;
			font-weight: bold;
			text-align: center; }
		&::before {
			display: none; } }
	table {
		border: 1px solid #f3f3f3;
		margin-bottom: 30px;

		thead {
			background-color: $main-color;

			tr {
				border: none;

				th {
					font-size: 24px;
					font-family: "Montserrat";
					color: rgb(255, 255, 255);
					font-weight: 600;
					border: none;

					@media screen and (max-width: 1024px) {
						font-size: 14px; } } } }

		tbody {
			tr {
				th {
					font-weight: 600;
					font-size: 16px;
					font-family: "Montserrat";
					color: rgb(51, 51, 51); }


				td {
					cursor: pointer;

					a {
						text-decoration: none;
						font-size: 16px;
						font-family: "Montserrat";
						color: rgb(51, 51, 51); } } } }





		tr {
			border-top: none; }


		td {
			border-top: none; }


		th {
			border-top: none; } }


	@media screen and (max-width: 1024px) {
		.table {
			display: none; } }

	.table-striped tbody tr:nth-of-type(odd) {
		background-color: #fff; }


	.table-striped tbody tr:nth-child(1) {
		border: none; }


	.table-striped tbody tr {
		background-color: #f5f5f5; }


	.table-hover tbody tr:hover {
		th {
			color: $main-color; }


		color: $main-color;

		a {
			color: $main-color; } }



	.dnn-hr-v-mobile {
		.item {
			display: block;
			text-decoration: none;
			padding: 28px 15px;
			margin-bottom: 30px;
			box-shadow: 0 0 4px 0 rgba(50, 50, 50, .2);

			h2 {
				font-size: 15px;
				font-weight: 600;
				line-height: 24px;
				color: $main-color;
				overflow: hidden;
				height: 24px; }


			p {
				display: flex;
				align-items: center;
				color: #333;
				font-size: 15px;
				margin-top: 12px;

				@media screen and (max-width: 576px) {
					margin: 10px 0; }


				em {
					color: $main-color;
					font-size: 18px;
					margin-right: 10px; } }


			.btn-apply,
			.btn-view {
				display: inline-flex;
				align-items: center;
				justify-content: center;
				background-color: #024b9d;
				color: #fff;
				width: 100%;
				height: 35px;
				margin-top: 20px; }

			.btn-view {
				background-color: $main-color; } }



		@media screen and (min-width: 1025px) {
			display: none; } } }
#ctl00_divAltContent2 {
	#form-hr {
		display: none; } }

.filed {
	width: 100%;
	height: 100%;
	background-image: url('../assets/images/linhvuchd/f-bg.png');
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	.block-title {
		margin-bottom: 27px; }
	&-list {
		.filed-item {
			.img {
				width: 100%;
				height: 400px;
				img {
					width: 100%;
					height: 100%;
					object-fit: cover; } }
			.desc {
				padding-top: 53px;
				padding-left: 30px;
				padding-right: 55px;
				h3 {
					font-size: 18px;
					font-family: "Montserrat";
					color: rgb(26, 119, 185);
					font-weight: bold;
					text-transform: uppercase;
					text-align: left;
					margin-bottom: 25px; }
				p {
					font-size: 16px;
					font-family: "Montserrat";
					color: rgb(51, 51, 51);
					line-height: 1.5; } }
			&:nth-child(2n) {
				margin-bottom: 60px;
				margin-top: 60px;
				.left {
					order: 2; }
				.right {
					order: 1;
					.desc {
						padding-left: 0;
						padding-right: 75px; } } }
			@media screen and ( max-width: 1199px) {
				&:nth-child(2) {
					margin: 30px 0; }
				.img {
					height: 300px; } }
			@media screen and ( max-width: 768px) {
				.desc {
					padding: 0;
					padding-top: 25px; }

				&:nth-child(2) {
					.right {
						.desc {
							padding-right: 0; } } } }
			@media screen and ( max-width: 575px) {
				margin-bottom: 15px;
				.left {
					order: 2; }
				.right {
					order: 1;
					.desc {
						padding: 0;
						margin-bottom: 15px; } }
				&:nth-child(2) {
					.right {
						.desc {
							padding: 0; } } } } } } }
.h-1 {
	padding: 0;
	width: 100%;
	.main-banner {
		margin-left: auto;
		width: 100%;
		height: 100vh;
		@media screen and ( max-width:  1240px) {
			height: auto; }
		.Module,
		.ModuleContent {
			width: 100%;
			height: 100%; }
		.home-banner {
			position: relative;
			width: 100%;
			height: 100%;

			.swiper-slide {
				width: 100% !important;
				height: 100%;
				position: relative;
				overflow: hidden;
				img {
					width: 100%;
					height: 100%;
					object-fit: cover; }

				.content {
					position: absolute;
					width: 55%;
					// background-color: rgba($color: #aaaaaa, $alpha: .3)
					bottom: 14%;
					left: 50%;
					transform: translateX(-50%);

					p {
						font-size: 23px;
						font-family: "Montserrat";
						color: rgb(255, 255, 255);
						font-weight: 500;
						text-transform: uppercase;
						text-align: center;
						line-height: 40px;
						text-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);

						@media screen and ( max-width:  1024px) {
							font-size: 25px; }
						@media screen and ( max-width: 600px) {
							font-size: 20px; } }
					@media screen and ( max-width: 1024px) {
						width: 100%;
						padding: 25px 15px;
						height: auto;
						bottom: 0;
						p {
							line-height: unset;
							font-size: 13px !important; } } }
				.socail-icon {
					position: absolute;
					bottom: 3%;
					right: 5%;
					z-index: 2;
					ul {
						display: flex;
						list-style: none;
						li {
							width: 28px;
							height: 28px;
							border: solid 1px #fff;
							border-radius: 50%;
							margin-right: 10px;
							line-height: 28px;
							text-align: center;
							a {
								.fa {
									font-size: 16px;
									color: rgb(255, 255, 255); } } } } }
				.icon {
					width: 100px;
					height: 100px;
					position: absolute;
					top: 38%;
					left: 50%;
					transform: translate(-50%, -50%);
					z-index: 2;
					text-align: center;

					a {
						.text {
							font-size: 16px;
							font-family: "Segoe UI";
							color: rgb(255, 255, 255);
							text-transform: uppercase;
							font-weight: 600;
							padding-top: 20px; }
						img {
							width: 100%;
							height: 100%; }
						&:hover {
							p {
								text-decoration: underline;
								color: #e95d68; } } } } } } }

	.swiper-button-prev,
	.swiper-button-next {
		display: none; } }
.h-2 {
	width: 100%;
	height: 100%;
	background-image: url('../assets/images/index/i-2.png');
	background-size: cover;
	background-position: center;
	.main-title {
		margin-bottom: 60px; }
	.left {
		width: 100%;
		display: flex;
		align-items: center;
		&__content {
			.block-title {
				margin-bottom: 43px; }
			&__desc {
				p {
					font-family: "Montserrat";
					font-size: 20px;
					color: #666666;
					text-align: left;
					padding-bottom: 32px;

					&:nth-child(1) {
						font-weight: bold; } } }
			.btn-detail {
				background-color: rgb(26, 119, 186);
				border: solid 1px transparent;
				.icon {
					&::before {
						content: url('../assets/icons/plus-hover.png'); } }
				&:hover {
					color: rgb(26, 119, 186);
					background-color: #fff;
					border: solid 1px rgb(26, 119, 186);
					.icon {
						&::before {
							content: url('../assets/icons/plus.png'); } } } } } }
	.right {
		width: 100%;
		height: 100%;
		&__content {
			width: 100%;
			.block-title {
				margin-bottom: 30px;
				padding-left: 73px;
				h2 {
					color: #ffffff; }
				&::before {
					background-color: #ffffff; } }
			&__img {
				width: 495px;
				height: 420px;
				img {
					width: 100%;
					height: 100%;
					object-fit: cover; } } } }
	@media screen and ( max-width: 1600px) {
		.main-title {
			margin-top: 50px;
			margin-bottom: 40px; }
		.left {
			&__content {
				.block-title {
					margin-bottom: 20px; }
				&__desc {
					p {
						padding-bottom: 15px; } } } }
		.right {
			&__content {
				.block-title {
					padding-left: 40px; }
				&__img {
					width: 85%;
					height: 350px; } } } }
	@media screen and ( max-width: 1440px) {
		.main-title {
			margin-bottom: 30px; }
		.left {
			&__content {
				.block-title {
					margin-bottom: 30px; }
				&__desc {
					p {
						font-size: 16px;
						padding-bottom: 32px; } } } }
		.right {
			&__content {
				.block-title {
					padding-left: 25px; }
				&__img {
					width: 80%;
					height: 329px; } } } }
	@media screen and ( max-width: 1200px) {
		.main-title {
			margin-top: 0; }
		.left {
			&__content {
				.block-title {
					margin-bottom: 20px; }
				&__desc {
					p {
						padding-bottom: 15px; } } } }
		.right {
			&__content {
				.block-title {
					padding-left: 105px; } } } }
	@media screen and ( max-width: 768px) {
		background-color: rgba($color: #a2d8ff, $alpha: .5);
		background-image: none !important;
		.left {
			&__content {
				.block-title {
					margin-bottom: 15px; } } }
		.right {
			&__content {
				.block-title {
					padding-left: 0;
					margin-bottom: 15px;
					h2 {
						color: #333333; }
					&::before {
						background-color: rgb(26, 119, 185); } }
				&__img {
					width: 100%;
					height: auto; } } } }
	@media screen and ( max-width: 767px) {
		.main-title {
			margin-bottom: 20px; }
		.left {
			margin-bottom: 30px;
			&__content {
				.block-title {
					margin-bottom: 15px; }
				&__desc {
					p {
						padding-bottom: 10px; } } } } } }
.h-3 {
	width: 100%;
	height: 100%;
	background-image: url('../assets/images/index/f-bg.png');
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	.block-title {
		margin-bottom: 90px;
		&::before {
			background-color: #fff; }
		h2 {
			color: #fff; } }
	.h-3__slide {
		position: relative;
		.slide-filed {
			.item {
				width: 400px;
				figure {
					.img {
						width: 100%;
						height: 245px;
						img {
							width: 100%;
							height: 100%;
							object-fit: cover; } }
					figcaption {
						width: 328px;
						height: 115px;
						background-color: #fff;
						padding: 20px;
						margin: 0 auto;
						margin-top: -51px;
						position: relative;
						.desc {
							h3 {
								font-size: 18px;
								font-family: "Montserrat";
								color: rgb(51, 51, 51);
								font-weight: bold;
								white-space: nowrap;
								overflow: hidden;
								text-overflow: ellipsis; }
							.btn-detail {
								position: relative;
								width: 170px;
								height: 40px;
								margin-top: 20px;
								background-color: #ffffff;
								color: rgb(26, 119, 186);
								border: 1px solid rgb(26, 119, 186); }

							&:hover {
								h3 {
									color: rgb(26, 119, 186);
									transition: all .3s ease-in-out; }
								.btn-detail {
									color: #fff;
									border: 1px solid transparent;
									background-color: rgb(26, 119, 186);
									transition: all .3s ease-in-out;
									.icon {
										&::before {
											content: url('../assets/icons/plus-hover.png');
											transition: all .3s ease-in-out; } } } } } } } } } }
	@media screen and ( max-width: 1440px) {
		.block-title {
			margin-bottom: 45px; } }
	@media screen and ( max-width: 1366px) {
		.h-3__slide {
			.slide-filed {
				.item {
					width: 100%; } } } }
	@media screen and ( max-width: 1280px) {
		.h-3__slide {
			.swiper-button-next {
				right: 0; }
			.swiper-button-prev {
				left: 0; } } }
	@media screen and ( max-width: 1199px) {
		.h-3__slide {
			.slide-filed {
				.item {
					figure {
						figcaption {
							width: 100%;
							height: auto;
							padding: 15px;
							margin-top: 0;
							.desc {
								h3 {
									font-size: 16px; }
								.btn-detail {
									width: 135px;
									height: 35px; } } } } } } } }
	@media screen and ( max-width: 991px) {
		.h-3__slide {
			.slide-filed {
				.item {
					figure {
						.img {
							width: 100%;
							height: 150px; }
						figcaption {
							width: 100%;
							height: auto;
							padding: 15px;
							margin-top: 0; } } } } } }
	@media screen and ( max-width: 768px) {
		// background-color: rgba($color: #a2d8ff, $alpha: .5)
		// background-image: none !important
		// .block-title
		// 	h1
		// 		color: #333
		.h-3__slide {
			.slide-filed {
				.item {
					figure {
						.img {
							width: 100%;
							height: 130px; } } } } } }
	@media screen and ( max-width: 375px) {
		.block-title {
			margin-bottom: 20px;
			h1 {
				color: #333; } }
		.h-3__slide {
			.slide-filed {
				.item {
					figure {
						.img {
							width: 100%;
							height: auto; } } } } } } }
.h-4 {
	width: 100%;
	height: 100%;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	background-image: url('../assets/images/index/project-bg.png');
	.block-title {
		margin-bottom: 44px;
		margin-top: 50px;
		h2 {
			color: #ffffff; }
		&::before {
			background-color: #ffffff; } }
	.left {
		&__content {
			&__desc {
				width: 500px;
				p {
					font-size: 20px;
					font-family: "Montserrat";
					color: rgb(255, 255, 255); } } }
		.btn-detail {
			background-color: #ffffff;
			color: rgb(26, 119, 185);
			margin-top: 32px;
			border: solid 1px transparent;
			&:hover {
				color: #ffffff;
				background: transparent;
				border: solid 1px #fff; }
			@media screen and ( max-width: 768px) {
				display: none; } } }
	.right {
		padding-top: 50px;
		figure {
			margin-bottom: 30px;
			.big-img {
				width: 600px;
				height: 380px;
				overflow: hidden;
				img {
					width: 100%;
					height: 100%;
					object-fit: cover;
					transform: scale(1);
					transition: all ease-in-out .35s; } }
			figcaption {
				width: 100%;
				height: 50px;
				background-color: rgba(26, 119, 186, .52);
				position: relative;
				margin-top: -50px;
				transition: all ease-in-out .35s;
				.desc {
					padding-left: 30px;
					h3 {
						line-height: 50px;
						color: #fff;
						font-size: 18px;
						font-weight: 700;
						transition: all ease-in-out .35s; } } }
			&:hover {
				.big-img {
					img {
						transform: scale(1.1);
						transition: all ease-in-out .35s; } }
				figcaption {
					background-color: #ffffff;
					transition: all ease-in-out .35s;
					.desc {
						h3 {
							color: #1a77ba;
							transition: all ease-in-out .35s; } } } } } }
	figure {
		margin-bottom: 30px;
		.small-img {
			width: 100%;
			height: 255px;
			overflow: hidden;
			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
				transform: scale(1);
				transition: all ease-in-out .35s; } }
		figcaption {
			width: 100%;
			height: 50px;
			background-color: rgba(26, 119, 186, .52);
			position: relative;
			margin-top: -50px;
			transition: all ease-in-out .35s;
			.desc {
				padding-left: 30px;
				h3 {
					line-height: 50px;
					color: #fff;
					font-size: 18px;
					font-weight: 700;
					transition: all ease-in-out .35s; } } }
		&:hover {
			.small-img {
				img {
					transform: scale(1.1);
					transition: all ease-in-out .35s; } }
			figcaption {
				background-color: #ffffff;
				transition: all ease-in-out .35s;
				.desc {
					h3 {
						color: #1a77ba;
						transition: all ease-in-out .35s; } } } }
		@media screen and ( max-width: 375px) {
			margin-bottom: 30px; } }
	.btn-detail-mobile {
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 25px;
		font-family: Montserrat;
		font-size: 14px;
		font-weight: 500;
		text-transform: uppercase;
		width: 160px;
		height: 40px;
		margin-left: auto;
		background-color: #ffffff;
		color: rgb(26, 119, 185);
		border: solid 1px transparent;
		&:hover {
			color: #ffffff;
			background: transparent;
			border: solid 1px #fff; }
		@media screen and ( min-width: 768px) {
			display: none; } }
	@media screen and ( max-width: 1600px) {
		.right {
			figure {
				.big-img {
					height: 375px;
					width: 100%; } } }
		figure {
			.small-img {
				width: 100%;
				height: 195px; } } }
	@media screen and ( max-width: 1400px) {
		.block-title {
			margin-top: 90px; }
		.right {
			padding-top: 90px; } }
	@media screen and ( max-width: 1366px) {
		.block-title {
			margin-top: 70px;
			margin-bottom: 22px; }
		.right {
			width: 100%;
			float: right;
			padding-top: 70px;
			figure {
				.big-img {
					height: 270px;
					width: 100%; } } }
		figure {
			.small-img {
				width: 100%;
				height: 182px; } } }
	@media screen and ( max-width: 1280px) {
		padding-top: 0;
		.block-title {
			margin-top: 0; }
		.left {
			&__content {
				&__desc {
					width: 100%;
					p {
						font-size: 16px; } } } }
		.right {
			padding-top: 0;
			figure {
				.big-img {
					height: 337px; } } }
		figure {
			.small-img {
				height: 262px; } } }
	@media screen and ( max-width: 1199px) {
		.left {
			&__content {
				margin-bottom: 30px; } }
		.right {
			figure {
				.big-img {
					height: 262px; } } } }
	@media screen and ( max-width: 768px) {
		.block-title {
			margin-bottom: 15px; }
		.left {
			&__content {
				.btn-detail {
					margin-top: 15px; } } } } }
.h-5 {
	width: 100%;
	height: 100%;
	background-image: url('../assets/images/index/f-bg.png');
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	.block-title {
		padding-top: 55px;
		margin-bottom: 30px;
		&::before {
			background-color: #fff; }
		h2 {
			color: #fff; } }
	.item {
		figure {
			width: 100%;
			height: 100%;
			.big-img {
				width: 100%;
				height: 340px;
				overflow: hidden;
				img {
					width: 100%;
					height: 100%;
					object-fit: cover;
					transform: scale(1);
					transition: all .3s ease-in-out; } }
			.small-img {
				width: 100%;
				height: 220px;
				overflow: hidden;
				img {
					width: 100%;
					height: 100%;
					object-fit: cover;
					transform: scale(1);
					transition: all .3s ease-in-out; } }
			figcaption {
				width: 90.33%;
				height: 103px;
				background-color: #fff;
				padding: 20px 25px;
				margin: 0 auto;
				margin-top: -60px;
				position: relative;
				overflow: hidden;
				.time {
					p {
						font-size: 12px;
						font-family: "Montserrat";
						color: rgb(153, 153, 153); } }
				.desc {
					overflow: hidden;
					text-overflow: ellipsis;
					display: -webkit-box;
					-webkit-line-clamp: 2;
					-webkit-box-orient: vertical;
					p {
						font-size: 18px;
						font-family: "Montserrat";
						color: rgb(51, 51, 51);
						font-weight: 600; } } } }
		&:nth-child(1),
		&:nth-child(2) {
			margin-bottom: 30px; }
		&:hover {
			figure {
				.big-img,
				.small-img {
					img {
						transform: scale(1.1);
						transition: all .3s ease-in-out; } }
				figcaption {
					.desc {
						p {
							color: rgb(26, 119, 185); } } } } } }
	.btn-detail-mobile {
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 25px;
		font-family: Montserrat;
		font-size: 14px;
		font-weight: 500;
		text-transform: uppercase;
		width: 160px;
		height: 40px;
		margin-left: auto;
		margin-top: 30px;
		background-color: #ffffff;
		color: rgb(26, 119, 185);
		border: solid 1px transparent;
		&:hover {
			color: #ffffff;
			background: transparent;
			border: solid 1px #fff; }
		@media screen and ( min-width: 768px) {
			display: none; } }
	@media screen and ( max-width:  1600px) {
		.block-title {
			padding-top: 30px; }
		.item {
			figure {
				.big-img {
					width: 100%;
					height: 300px; }
				figcaption {
					margin-top: -80px;
					width: 100%;
					height: 80px;
					padding: 10px 15px;
					.desc {
						p {
							font-size: 16px; } } } } } }
	@media screen and ( max-width:  1366px) {
		.block-title {
			padding-top: 50px;
			margin-bottom: 20px; }
		.item {
			figure {
				.big-img {
					height: 260px; }
				.small-img {
					width: 100%;
					height: 195px; }
				figcaption {
					margin-top: -60px;
					width: 100%;
					height: 60px;
					padding: 10px 15px;
					.desc {
						-webkit-line-clamp: 1; } } } } }
	@media screen and ( max-width: 1280px) {
		.block-title {
			padding-top: 0; }
		.item {
			figure {
				figcaption {
					margin-top: 0;
					height: 110px;
					.desc {
						-webkit-line-clamp: 3; } } } } }
	@media screen and ( max-width: 1024px) {
		.item {
			figure {
				.big-img {
					height: 200px; }
				.small-img {
					height: 130px; } } } }
	@media screen and ( max-width: 768px) {
		.item {
			figure {
				.big-img {
					height: 190px; }
				.small-img {
					height: 130px; } }
			&:nth-child(3),
			&:nth-child(4) {
				margin-bottom: 30px; } } }
	@media screen and ( max-width: 575px) {
		.item {
			figure {
				.big-img {
					height: 195.5px; }
				.small-img {
					height: 195.5px; } } } } }

.h-6 {
	width: 100%;
	height: 100%;
	background-image: url('../assets/images/index/h5-bg.png');
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	&__content {
		background-image: url('../assets/images/index/h7-content.png');
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
		width: 476px;
		height: 478px;
		padding: 63px 50px;
		.title {
			margin-bottom: 37px;
			h1 {
				font-size: 30px;
				font-family: "Montserrat";
				color: rgb(255, 255, 255);
				line-height: 1.333; } }
		.desc {
			margin-bottom: 43px;
			p {
				font-size: 16px;
				font-family: "Montserrat";
				color: rgb(255, 255, 255);
				line-height: 1.5;
				overflow: hidden; } }
		.btn-detail {
			color: rgb(26, 119, 186);
			font-size: 14px;
			background-color: #fff;
			&:hover {
				color: #ffffff;
				background: rgb(26, 119, 186); } } }
	@media screen and ( max-width: 1280px) {
		&__content {
			width: 100%;
			height: auto;
			padding: 50px;
			.title {
				margin-bottom: 20px;
				h1 {
					font-size: 25px; } }
			.desc {
				margin-bottom: 20px; } } }
	@media screen and ( max-width: 768px) {
		&__content {
			.title {
				margin-bottom: 20px;
				h1 {
					font-size: 20px; } } } }
	@media screen and ( max-width: 575px) {
		&__content {
			padding: 30px; } } }


.h-7 {
	width: 100%;
	height: 100%;
	background-image: url('../assets/images/index/h7-bg.png');
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	.block-title {
		margin-bottom: 40px;
		padding-top: 35px;
		&::before {
			background-color: #ffffff; }
		h2 {
			color: #ffffff; } }
	.content {
		width: 100%;
		background-color: rgba(255, 255, 255, .3);
		.col {
			padding: 0;
			display: flex;
			flex: 1 0 18%;
			height: 220px;
			&:nth-child(2n) {
				background-color: rgba(246, 246, 246, .3); }
			@media screen and ( max-width: 1280px) {
				flex: 1 0 25%;
				height: 180px; }
			@media screen and ( max-width: 1024px) {
				flex: 1 0 33.333%; }
			@media screen and ( max-width: 768px) {
				flex: 1 0 50%;
				padding: 15px 0; }

			.item {
				width: 100%;
				height: 100%;
				padding: 20px;
				transition: all .5s ease-in-out;
				a {
					display: flex;
					width: 100%;
					height: 100%;
					align-items: center;
					justify-content: center;
					img {
						filter: brightness(0) invert(1);
						transition: all .5s ease-in-out; } }
				&:hover {
					background-color: #ffffff;
					transition: all .5s ease-in-out;
					img {
						filter: brightness(1) invert(0);
						transition: all .5s ease-in-out; } } } } } }

.h-8 {
	width: 100%;
	height: 100%;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	background-image: url('../assets/images/index/footer-bg.png');
	.footer-left {
		.footer-card {
			&:nth-child(1),
			&:nth-child(2) {
				margin-bottom: 55px; }
			.footer-title {
				margin-bottom: 30px;
				p {
					font-size: 24px;
					font-family: "Montserrat";
					color: rgb(255, 255, 255);
					font-weight: 600;
					text-transform: uppercase; }
				span {
					font-size: 14px;
					font-family: "Montserrat";
					color: rgb(255, 255, 255); } }

			.footer-body {
				ul {
					padding-left: 0;
					list-style: none;
					li {
						display: flex;
						margin-bottom: 10px;
						.material-icons {
							font-size: 16px;
							padding-right: 12px;
							font-family: "Material Icons";
							color: rgb(255, 255, 255); }
						p {
							font-size: 14px;
							font-family: "Montserrat";
							color: rgb(255, 255, 255); }
						a {
							font-size: 14px;
							font-family: "Montserrat";
							color: rgb(255, 255, 255); }
						&:last-child {
							margin-bottom: 0; } } }
				&.footer-nav {
					ul {
						li {
							position: relative;
							padding-left: 40px;
							&:before {
								position: absolute;
								top: 50%;
								left: 0;
								transform: translateY(-50%);
								width: 20px;
								height: 1px;
								background-color: #ffffff;
								content: ''; } } } } } } }
	.footer-right {
		.footer-title {
			margin-bottom: 15px;
			p {
				font-size: 24px;
				font-family: "Montserrat";
				color: rgb(255, 255, 255);
				font-weight: 600;
				text-transform: uppercase; } }
		.desc {
			margin-bottom: 15px;
			p {
				font-size: 14px;
				font-family: "Montserrat";
				color: rgb(255, 255, 255); } }
		.wrap-form {
			.frm-thanks {
				h3 {
					color: #ffffff;
					font-weight: 400;
					font-size: 18px; } }
			.form-group {
				position: relative;
				margin-bottom: 20px;
				input {
					width: 100%;
					height: 38px;
					padding-left: 20px;
					border-width: 1px;
					border-radius: 20px;
					color: #fff;
					border-color: rgb(235, 235, 235);
					border-style: solid;
					background-color: rgba($color:#000, $alpha: .2);
					&::placeholder {
						font-size: 14px;
						font-family: "Montserrat";
						color: rgba(255, 255, 255, 0.702); } }
				textarea {
					padding-left: 20px;
					padding-top: 15px;
					border-width: 1px;
					border-style: solid;
					border-color: rgb(235, 235, 235);
					border-radius: 10px;
					color: #ffffff;
					width: 100%;
					height: 148px;
					background-color: rgba($color:#000, $alpha: .2);
					&::placeholder {
						font-size: 14px;
						font-family: "Montserrat";
						color: rgba(255, 255, 255, 0.702); } }
				span {
					position: absolute;
					bottom: -15px;
					color: red;
					font-family: "Montserrat";
					font-size: 14px;
					left: 20px;
					&::before {
						display: none; } }
				label {
					display: none; }
				.frm-btn {
					display: flex;
					align-items: center;
					background-color: rgb(255, 255, 255);
					width: 160px;
					height: 40px;
					padding: 0 50px;
					border-radius: 20px;
					input {
						background-color: transparent;
						border: none;
						font-size: 14px;
						font-family: "Montserrat";
						color: rgb(26, 119, 186);
						font-weight: bold;
						text-transform: uppercase;
						padding: 0; }
					.frm-btn-reset {
						display: none; }
					.material-icons {
						padding-left: 25px;
						font-size: 18px;
						font-family: "Material Icons";
						color: rgb(26, 119, 186); } } } } }
	.copyright {
		border-top: solid 1px rgba($color: #fff, $alpha: .2);
		padding-top: 20px;
		.copy {
			p {
				font-size: 13px;
				font-family: "Montserrat";
				color: rgb(255, 255, 255);
				text-align: left;
				a {
					color: rgb(255, 255, 255); } } }
		ul {
			display: flex;
			justify-content: flex-end;
			li {
				border-radius: 50%;
				background-color: rgba($color: #ffffff, $alpha: .2);
				width: 45px;
				height: 45px;
				margin-left: 10px;
				padding: 0;
				display: flex;
				align-items: center;

				&:last-child {
					padding: 0;
					border: none; }
				a {
					width: 100%;
					text-align: center;
					padding: 10px 0;
					i {
						font-size: 18px;
						color: rgb(255, 255, 255);
						text-align: center;
						z-index: 20; } } } } }

	@media screen and ( max-width: 1600px) {
		.footer-left {
			padding-top: 70px;
			.footer-card {
				&:nth-child(1),
				&:nth-child(2) {
					margin-bottom: 20px; }
				.footer-title {
					margin-bottom: 10px; } } }
		.footer-right {
			padding-top: 70px;
			.footer-title {
				margin-bottom: 10px; }
			.desc {
				margin-bottom: 10px; }
			.wrap-form {
				.form-group {
					textarea {
						height: 100px; } } } } }
	@media screen and ( max-width: 1366px) {
		.footer-left {
			padding-top: 50px;
			.footer-card {
				&:nth-child(1),
				&:nth-child(2) {
					margin-bottom: 10px; }
				.footer-title {
					p {
						font-size: 18px; } } } }
		.footer-right {
			padding-top: 50px;
			.footer-title {
				p {
					font-size: 18px; } }
			.wrap-form {
				.form-group {
					margin-bottom: 15px; } } } }
	@media screen and ( max-width: 1199px) {
		.copyright {
			.copy {
				p {
					font-size: 12px;
					a {
						font-size: 12px; } } } } }
	@media screen and ( max-width: 767px) {
		.footer-left {
			padding-top: 0;
			.col-xl-6 {
				&:nth-child(2),
				&:nth-child(4) {
					.footer-card {
						float: right; } } }
			.footer-card {
				.footer-body {
					ul {
						li {
							p,
							a {
								font-size: 13px; } } } } } }
		.footer-right {
			padding-top: 0; } }
	@media screen and ( max-width: 575px) {
		.footer-left {
			.col-xl-6 {
				&:nth-child(2),
				&:nth-child(4) {
					.footer-card {
						float: left; } } } } } }

.lab-images {
	width: 100%;
	height: 100%;
	background-image: url('../assets/images/lab/bg.png');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: right;
	.block-title {
		margin-bottom: 30px;
		h2 {
			text-transform: uppercase; } }
	.news-nav {
		ul {
			display: flex;
			list-style: none;
			padding-left: 0;
			margin-bottom: 25px;
			li {
				position: relative;
				padding-right: 20px;
				&::before {
					position: absolute;
					top: 50%;
					transform: translateY(-50%);
					right: 0;
					background-color: rgb(43, 33, 33);
					width: 1px;
					height: 13px;
					content: ''; }
				&:last-child {
					padding-left: 20px;
					&::before {
						display: none; } }
				a {
					font-size: 18px;
					font-family: "Montserrat";
					color: rgb(51, 51, 51); } } } }
	figure {
		width: 100%;
		position: relative;
		.big-img {
			width: 100%;
			height: 400px;
			margin-bottom: 30px;
			img {
				width: 100%;
				height: 100%;
				object-fit: cover; } }
		.small-img {
			width: 100%;
			height: 185px;
			margin-bottom: 30px;
			img {
				width: 100%;
				height: 100%;
				object-fit: cover; } }
		figcaption {
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 50px;
			transition: all ease-in-out .3s;
			background-color: rgba($color: #000, $alpha: .5);
			.desc {
				line-height: 50px;
				text-align: center;
				.fas {
					visibility: hidden;
					display: none;
					transition: all ease-in-out .3s;
					font-size: 36px;
					color: rgb(255, 255, 255);
					margin-bottom: 20px; }

				p {
					font-size: 18px;
					font-family: "Montserrat";
					color: rgb(255, 255, 255); } } }
		&:hover {
			figcaption {
				display: flex;
				align-items: center;
				justify-content: center;
				height: 100%;
				transition: all ease-in-out .3s;
				.desc {
					line-height: 100%;
					.fas {
						display: block;
						visibility: visible;
						transition: all ease-in-out .3s; } } } }
		@media screen and ( max-width:  375px) {
			.big-img,
			.small-img {
				height: 230px; } }
		@media screen and ( max-width:  768px) {
			.big-img,
			.small-img {
				height: 400px; } }
		@media screen and ( max-width:  1024px) {
			.big-img,
			.small-img {
				height: 620px; } } } }




.news-detail {
	width: 100%;
	padding-bottom: 0;
	.block-title {
		h1 {
			font-size: 24px;
			font-family: "Montserrat";
			color: rgb(26, 119, 186);
			font-weight: bold;
			text-align: left; }
		&:before {
			display: none; } }
	.time-line {
		padding: 10px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-bottom: solid 1px #cccccc;
		.time {
			p {
				color: #999999;
				font-size: 14px;
				font-family: "Montserrat"; } }
		.icon-social {
			ul {
				display: flex;
				list-style: none;
				li {
					margin-left: 17px;
					a {
						i {
							color: #333333;
							font-size: 20px;
							font-weight: 400; } } } } } }
	.content {
		padding-top: 20px;
		p {
			font-size: 16px;
			font-family: "Montserrat";
			color: #333333;
			text-align: left;
			padding-bottom: 30px; }
		.img {
			width: 1020px;
			margin: 0 auto;
			text-align: center;
			padding-bottom: 30px;
			img {
				height: 567px; }
			span {
				font-size: 14px;
				font-family: "Montserrat";
				color: #333333;
				font-style: italic; } } }
	.news-other {
		background-color: #eef8ff;
		padding-top: 60px;
		padding-bottom: 120px;
		.block-title {
			margin-bottom: 23px;
			&:before {
				display: block; } }
		.slider {
			position: relative;
			.news-list {
				.swiper-slide {
					figure {
						width: 100%;
						height: 100%;
						.img {
							width: 100%;
							height: 220px;
							overflow: hidden;
							img {
								width: 100%;
								height: 100%;
								object-fit: cover;
								transform: scale(1);
								transition: all .3s ease-in-out; } }
						figcaption {
							position: relative;
							background-color: rgb(255, 255, 255);
							box-shadow: 0px 3px 20px 0px rgba(51, 51, 51, 0.1);
							width: 90%;
							height: 96px;
							margin-top: -40px;
							margin: 0 auto;
							padding: 15px 20px;

							.time {
								p {
									font-size: 12px;
									font-family: "Montserrat";
									color: rgb(153, 153, 153); } }
							.desc {
								overflow: hidden;
								text-overflow: ellipsis;
								display: -webkit-box;
								-webkit-line-clamp: 4;
								-webkit-box-orient: vertical;
								h3 {
									font-size: 14px;
									font-family: "Montserrat";
									color: #333333;
									font-weight: 600; } } }

						&:hover {
							.img {
								img {
									transform: scale(1.1);
									transition: all .3s ease-in-out; } }
							figcaption {
								.desc {
									h3 {
										color: rgb(26, 119, 185); } } } } }
					@media screen and ( max-width: 1199px) {
						figure {
							.small-img {
								height: 160px; }
							figcaption {
								padding: 15px;
								.desc {
									-webkit-line-clamp: 3; } } } } } }
			.swiper-button-next,
			.swiper-button-prev {
				&::before {
					color: #333333; } } } } }





.news {
	width: 100%;
	padding-top: 0;
	#full-content {
		background-image: url('../assets/images/linhvuchd/f-bg.png');
		background-position: center;
		background-size: cover;
		background-repeat: no-repeat;
		padding-top: 80px; }
	.block-title {
		margin-bottom: 30px;
		h2 {
			text-transform: uppercase; } }
	.news-nav {
		ul {
			display: flex;
			list-style: none;
			padding-left: 0;
			margin-bottom: 25px;
			li {
				position: relative;
				padding-right: 20px;
				&::before {
					position: absolute;
					top: 50%;
					transform: translateY(-50%);
					right: 0;
					background-color: rgb(43, 33, 33);
					width: 1px;
					height: 13px;
					content: ''; }
				&:last-child {
					padding-left: 20px;
					&::before {
						display: none; } }
				a {
					font-size: 18px;
					font-family: "Montserrat";
					color: rgb(51, 51, 51); } } } }
	.item {
		figure {
			width: 100%;
			height: 100%;
			.big-img {
				width: 100%;
				height: 340px;
				overflow: hidden;
				img {
					width: 100%;
					height: 100%;
					object-fit: cover;
					transform: scale(1);
					transition: all .3s ease-in-out; } }
			.small-img {
				width: 100%;
				height: 220px;
				overflow: hidden;
				img {
					width: 100%;
					height: 100%;
					object-fit: cover;
					transform: scale(1);
					transition: all .3s ease-in-out; } }
			figcaption {
				width: 90.33%;
				height: 130px;
				background-color: #fff;
				padding: 20px 25px;
				margin: 0 auto;
				margin-top: -60px;
				position: relative;
				overflow: hidden;
				.time {
					p {
						font-size: 12px;
						font-family: "Montserrat";
						color: rgb(153, 153, 153); } }
				.desc {
					overflow: hidden;
					text-overflow: ellipsis;
					display: -webkit-box;
					-webkit-line-clamp: 4;
					-webkit-box-orient: vertical;
					h3 {
						font-size: 18px;
						font-family: "Montserrat";
						columns: #333333;
						font-weight: 600; } } } }
		&:nth-child(1),
		&:nth-child(2),
		&:nth-child(4),
		&:nth-child(5) {
			margin-bottom: 30px; }
		&:nth-child(6),
		&:nth-child(7),
		&:nth-child(8) {
			margin-bottom: 15px; }
		&:hover {
			figure {
				.big-img,
				.small-img {
					img {
						transform: scale(1.1);
						transition: all .3s ease-in-out; } }
				figcaption {
					.desc {
						h3 {
							color: rgb(26, 119, 185); } } } } } }
	@media screen and ( max-width:  1366px) {
		.block-title {
			margin-bottom: 20px; }
		.item {
			figure {
				.big-img {
					height: 300px; }
				.small-img {
					width: 100%;
					height: 197px; }
				figcaption {
					margin-top: 0;
					width: 100%;
					padding: 10px 15px;
					.desc {
						-webkit-line-clamp: 4; } } } } }
	@media screen and ( max-width: 1199px) {
		.item {
			figure {
				.big-img {
					height: 255px; }
				.small-img {
					height: 160px; }
				figcaption {
					height: auto;
					padding: 15px;
					.desc {
						-webkit-line-clamp: 3; } } } } }
	@media screen and ( max-width: 1024px) {
		.item {
			figure {
				.big-img {
					height: 200px; }
				.small-img {
					height: 130px; } } } }
	@media screen and ( max-width: 768px) {
		.item {
			figure {
				.big-img {
					height: 190px; }
				.small-img {
					height: 195px; } }
			&:nth-child(3),
			&:nth-child(4) {
				margin-bottom: 30px; } } }
	@media screen and ( max-width: 575px) {
		.block-title {
			margin-bottom: 10px; }
		.news-nav {
			ul {
				margin-bottom: 10px;
				li {
					padding-right: 5px;
					&:last-child {
						padding-left: 5px;
						padding-right: 0; }
					a {
						font-size: 12px; } } } }
		.item {
			&:nth-child(1),
			&:nth-child(2),
			&:nth-child(4),
			&:nth-child(5),
			&:nth-child(6),
			&:nth-child(7),
			&:nth-child(8),
			&:nth-child(9) {
				margin-bottom: 15px; }
			figure {
				.big-img {
					height: 300px; }
				.small-img {
					height: 300px; } } } }

	@media screen and ( max-width: 375px) {
		.item {
			figure {
				.big-img {
					height: 195px; }
				.small-img {
					height: 195px; } } } } }


.project {
	width: 100%;
	height: 100%;
	background-image: url('../assets/images/pro/pro-bg.png');
	background-size: cover;
	background-repeat: no-repeat;
	background-position: right;
	.block-title {
		margin-bottom: 22px; }
	&-nav {
		width: 100%;
		margin-bottom: 35px;
		padding: 15px;
		background-color: rgb(239, 248, 255);
		box-shadow: 1.721px 2.457px 10px 0px rgba(19, 28, 35, 0.1);
		.list-nav {
			display: flex;
			align-items: center;
			padding-left: 0;
			list-style: none;
			li {
				margin-left: 5px;
				height: 48px;
				width: 210px;
				background-color: #ffffff;
				box-shadow: 1.721px 2.457px 10px 0px rgba(19, 28, 35, 0.1);

				&:first-child {
					margin-left: 0;
					a {
						font-size: 15px;
						font-family: "Montserrat";
						color: rgb(39, 15, 15);
						text-transform: uppercase; } }
				&:last-child {
					width: 69px;
					display: flex;
					align-items: center;
					background-color: #1a77ba;
					justify-content: center;
					a {
						.material-icons {
							padding: 7px 17px;
							font-size: 36px;
							font-family: "Material Icons";
							color: rgb(255, 255, 255); } } }

				a {
					padding: 0 15px;
					display: flex;
					line-height: 48px;
					justify-content: center;
					em {
						padding-right: 0.9375rem;
						color: rgb(39, 15, 15); }
					p {
						font-size: 0.9375rem;
						font-family: "Montserrat";
						color: rgb(39, 15, 15); } }

				&.active {
					background-color: #1a77ba;
					a {
						color: #ffffff;
						p,
						em,
						i {
							color: #ffffff; } } } } }
		.filter-nav {
			margin-top: 20px;
			width: 100%;
			ul {
				padding-left: 0;
				display: flex;
				align-items: center;
				list-style: none;
				li {
					margin-right: auto;
					&:last-child {
						margin-right: 0; }
					select,
					input {
						background-color: rgb(255, 255, 255);
						box-shadow: 1.721px 2.457px 10px 0px rgba(19, 28, 35, 0.1);
						width: 260px;
						height: 48px;
						border: none;
						padding: 0 15px;
						option {
							border: none;
							background-color: #ffffff; } }
					&:last-child {
						background-color: rgb(26, 119, 186);
						width: 120px;
						height: 48px;
						line-height: 48px;
						text-align: center;
						a {
							display: flex;
							align-items: center;
							justify-content: center;
							color: #ffffff;
							font-size: 15px;
							em {
								color: #ffffff;
								font-size: 24px; } } } } }
			&.active {
				display: none; } } }

	&-list {
		.item {
			margin-bottom: 30px;
			figure {
				.img {
					width: 100%;
					height: 250px;
					overflow: hidden;
					img {
						width: 100%;
						height: 100%;
						object-fit: cover;
						transform: scale(1);
						transition: all ease-in-out .3s; } }
				figcaption {
					background-color: rgba(26,119,186,.52);
					width: 100%;
					height: 160px;
					padding: 25px 30px;
					.desc {
						h3 {
							font-size: 18px;
							font-family: "Montserrat";
							color: rgb(26, 119, 186);
							font-weight: bold;
							position: relative;
							width: max-content;
							&::before {
								position: absolute;
								bottom: 0;
								left: 0;
								width: 100%;
								height: 1px;
								background-color: $main-color;
								content: '';
								visibility: hidden;
								opacity: 0; } }
						ul {
							margin-top: 18px;
							list-style: none;
							padding-left: 0;
							li {
								span {
									display: flex;
									em {
										padding-right: 13px;
										font-size: 18px;
										color: rgb(26, 119, 186); }
									p {
										font-size: 16px;
										font-family: "Montserrat";
										color: rgb(51, 51, 51);
										text-align: left; } } } } } }
				&:hover {
					.img {
						overflow: hidden;
						img {
							transform: scale(1.1);
							transition: all ease-in-out .3s; } }
					figcaption {
						.desc {
							h3 {
								color: #ffffff;
								&::before {
									visibility: visible;
									opacity: 1;
									transition: all ease-in-out .3s; } }
							ul {
								li {
									span {
										em,
										p {
											color: #fff; } } } } } } } } } }
	@media screen and ( max-width: 1440px) {
		&-nav {
			.list-nav {
				li {
					a {
						p {
							font-size: 13px; } } } } } }
	@media screen and ( max-width: 1399px) {
		.filter-nav {
			ul {
				li {
					select {
						width: 225px; } } } } }
	@media screen and ( max-width: 1366px) {
		&-nav {
			.list-nav {
				li {
					margin-left: auto;
					width: 164px;
					a {
						em {
							padding-right: 7px; }
						p {
							font-size: 12px; } } } } }
		&-list {
			.item {
				figure {
					figcaption {
						.desc {
							h3 {
								font-size: 16px; }
							ul {
								li {
									span {
										p {
											font-size: 15px; } } } } } } } } } }
	@media screen and ( max-width: 1199px) {
		&-nav {
			.list-nav {
				li {
					width: auto; } }
			.filter-nav {
				ul {
					li {
						select {
							width: auto; } } } } } }
	@media screen and ( max-width: 1024px) {
		&-list {
			.item {
				figure {
					figcaption {
						height: 140px;
						padding: 25px 10px;
						.desc {
							h3 {
								font-size: 16px; }
							ul {
								li {
									span {
										em {
											padding-right: 7px;
											font-size: 16px; }
										p {
											font-size: 14px; } } } } } } } } } }
	@media screen and ( max-width: 767px) {
		&-list {
			.item {
				figure {
					.img {
						height: 400px; } } } } }
	@media screen and ( max-width: 575px) {
		&-list {
			.item {
				figure {
					.img {
						height: 275px; } } } } }
	@media screen and ( max-width: 375px) {
		&-list {
			.item {
				figure {
					.img {
						height: 221px; } } } } }
	@media screen and ( min-width: 991.98px) {
		&-nav {
			.show-nav {
				display: none; } } }
	@media screen and ( max-width: 991.88px) {
		&-nav {
			.show-nav {
				h5 {
					color: #1a77ba;
					font-weight: 600; } }
			.list-nav {
				display: none;
				li {
					margin-left: 0;
					margin-bottom: 10px;
					a {
						justify-content: flex-start;
						em,
						i,
						p {
							font-size: 18px; } }
					&:last-child {
						margin: 0 auto;
						margin-top: 15px; } } }
			.filter-nav {
				ul {
					flex-direction: column;
					li {
						width: 100%;
						margin-right: 0;
						margin-bottom: 10px;
						select,
						input {
							width: 100%; }
						&:last-child {
							width: 100%; } } } } } } }
.pro-detail,
.pro-detail-2,
.pro-detail-3,
.pro-detail-4,
.pro-detail-5,
.pro-detail-6,
.pro-detail-7 {
	.content {
		min-width: 969px;
		margin-left: auto;
		h2 {
			font-size: 24px;
			font-family: "Montserrat";
			color: rgb(26, 119, 186);
			font-weight: bold;
			margin-bottom: 30px; }
		.desc {
			margin-bottom: 30px;
			p {
				font-size: 16px;
				font-family: "Montserrat";
				color: rgb(51, 51, 51); } }
		@media screen and ( max-width: 1399px) {
			min-width: unset; }
		@media screen and ( max-width: 768px) {
			h2 {
				margin-bottom: 10px; }
			.desc {
				margin-bottom: 10px; } } } }
.pro-detail {
	width: 100%;
	height: 100%;
	background-image: url('../assets/images/pro/pro-bg.png');
	background-size: cover;
	background-repeat: no-repeat;
	background-position: right;
	.content {
		float: left;
		.slide {
			position: relative;
			padding-bottom: 30px;
			border-bottom: solid 1px #e0e0e0;
			.gallery-top {
				.swiper-slide {
					.img {
						cursor: pointer;
						width: 100%;
						height: 600px;
						img {
							width: 100%;
							height: 100%;
							object-fit: cover; } } } }
			.gallery-thumbs {
				overflow: hidden;
				margin-top: 13px;
				.swiper-slide {
					position: relative;
					border: solid 2px transparent;
					.img {
						width: 100%;
						height: 111px;
						img {
							width: 100%;
							height: 100%;
							object-fit: cover; } }
					&.swiper-slide-thumb-active {
						border: solid 2px #237cbc;
						transition: all ease-in-out .3s;
						&::after {
							background: none; } }
					&::after {
						cursor: pointer;
						position: absolute;
						top: 0;
						left: 0;
						content: '';
						width: 100%;
						height: 100%;
						background-color: rgba($color: #000, $alpha: .5);
						transition: all ease-in-out .3s; } } }
			.swiper-button-next,
			.swiper-button-prev {
				top: unset;
				bottom: 9%; }
			.swiper-button-next {
				right: 0; }
			.swiper-button-prev {
				left: 0; } }
		.table {
			width: 100%;
			margin-top: 30px;
			table {
				width: 100%;
				tbody {
					tr {
						th {
							width: 50%;
							p {
								font-size: 16px;
								font-family: "Montserrat";
								color: rgb(51, 51, 51);
								font-weight: bold; } }
						td {
							p {
								font-size: 16px;
								color: #333333;
								font-family: "Montserrat"; } }
						th,
						td {
							border-top: none;
							border-bottom: solid 1px #f5f5f5; } } } }

			.show-more {
				width: 100%;
				text-align: center;
				padding: 20px 0;
				box-shadow: 0px 2px 0px 0px rgba($color: #fff, $alpha: .8);
				.view-more {
					font-size: 16px;
					font-family: "Montserrat";
					color: rgb(26, 119, 186); } } }
		@media screen and ( max-width: 575px) {
			.slide {
				.gallery-top {
					.swiper-slide {
						.img {
							height: 212px; } } }
				.swiper-button-next,
				.swiper-button-prev {
					bottom: 18%; } } } } }
.pro-detail-2 {
	position: relative;
	width: 100%;
	background-color: rgba($color: #1a77ba, $alpha: .2);
	.content {
		.img {
			width: 100%;
			height: 600px;
			img {
				width: 100%;
				height: 100%;
				object-fit: cover; } }
		@media screen and ( max-width: 575px) {
			.img {
				height: auto; } } } }
.pro-detail-3 {
	.content {
		.slide {
			position: relative;
			&-utilities {
				.swiper-slide {
					figure {
						cursor: pointer;
						.img {
							width: 100%;
							height: 180px;
							img {
								width: 100%;
								height: 100%;
								object-fit: cover; } }
						figcaption {
							width: 100%;
							padding-top: 20px;
							.desc {
								overflow: hidden;
								text-overflow: ellipsis;
								display: -webkit-box;
								-webkit-line-clamp: 2;
								-webkit-box-orient: vertical;
								p {
									font-size: 16px;
									font-family: "Montserrat";
									color: rgb(51, 51, 51); } } } } } }
			.swiper-button-next,
			.swiper-button-prev {
				&::before {
					font-size: 36px;
					color: rgb(51, 51, 51); } }
			.swiper-button-next {
				right: 0; }
			.swiper-button-prev {
				left: 0; } } } }
.pro-detail-4 {
	padding-top: 0;
	.content {
		.tab-panels {
			.tabs {
				li {
					cursor: pointer;
					border-radius: 19px;
					background-color: rgb(239, 248, 255);
					width: 240px;
					height: 40px;
					font-size: 15px;
					font-family: "Montserrat";
					color: rgb(51, 51, 51);
					margin-right: 20px;
					display: flex;
					align-items: center;
					justify-content: center;
					.ri-arrow-drop-down-line {
						color: #1a77ba;
						font-size: 36px; }
					&:last-child {
						margin-right: 0; }
					a {
						font-size: 14px; }
					@media screen and ( max-width: 575px) {
						font-size: 13px;
						.ri-arrow-drop-down-line {
							font-size: 20px; } } } }
			.panel {
				padding: 30px 0; } } } }
.pro-detail-5 {
	padding-top: 0;
	width: 100%;
	.slider {
		position: relative;
		&-images {
			.swiper-slide {
				position: relative;
				transition: all ease-in-out .5s;
				transform: scale(.8);
				cursor: pointer;
				&::before {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					background-color: rgba(26, 119, 186, .5);
					transition: all ease-in-out .5s;
					content: ''; }
				.img {
					width: 100%;
					height: 435px;
					img {
						width: 100%;
						height: 100%;
						object-fit: cover; } }
				@media screen and ( max-width: 768px) {
					.img {
						height: 247px; } }
				@media screen and ( max-width: 575px) {
					.img {
						height: 110px; } }
				&.swiper-slide-active {
					transform: scale(1.2);
					transition: all ease-in-out .5s;
					&::before {
						background: none;
						transition: all ease-in-out .5s; } } }
			.swiper-button-next,
			.swiper-button-prev {
				top: 50%; }
			.swiper-button-next {
				right: 16%; }
			.swiper-button-prev {
				left: 16%; } }
		@media screen and ( max-width: 575px) {
			.swiper-button-next {
				right: 10%; }
			.swiper-button-prev {
				left: 10%; } } } }
.pro-detail-6 {
	padding-top: 0;
	.content {
		.img {
			position: relative;
			width: 810px;
			height: 490px;
			img {
				width: 100%;
				height: 100%;
				object-fit: cover; }
			.desc {
				position: absolute;
				top: 50%;
				left: 50%;
				text-align: center;
				transform: translate(-50%, -50%);
				.ri-play-circle-line {
					font-size: 72px;
					font-family: "remixicon";
					color: rgb(255, 255, 255);
					font-weight: 400; }
				p {
					font-size: 18px;
					font-family: "Montserrat";
					color: rgb(255, 255, 255); } } }
		@media screen and ( max-width: 768px) {
			.img {
				width: 100%;
				height: 415px; } }
		@media screen and ( max-width: 575px) {
			.img {
				height: 200px;
				.desc {
					.ri-play-circle-line {
						font-size: 50px; } } } } } }
.pro-detail-7 {
	.slide {
		position: relative;
		.slide-utilities {
			.swiper-slide {
				figure {
					.img {
						width: 100%;
						height: 175px;
						overflow: hidden;
						img {
							width: 100%;
							height: 100%;
							object-fit: cover;
							transition: all ease-in-out .3s;
							transform: scale(1); } }
					figcaption {
						position: relative;
						width: 90%;
						margin-top: -40px;
						margin: 0 auto;
						background-color: #fff;
						transition: all ease-in-out .3s;
						.desc {
							overflow: hidden;
							text-overflow: ellipsis;
							display: -webkit-box;
							-webkit-line-clamp: 4;
							-webkit-box-orient: vertical;
							padding: 15px 20px;
							.time {
								font-size: 12px;
								font-family: "Montserrat";
								color: rgb(153, 153, 153); }
							.title {
								font-size: 14px;
								font-family: "Montserrat";
								color: rgb(51, 51, 51);
								font-weight: bold; } } }
					&:hover {
						.img {
							img {
								transform: scale(1.1);
								transition: all ease-in-out .3s; } }
						figcaption {
							box-shadow: 0px 0px 25px 0px rgba($color: #000, $alpha: .1);
							transition: all ease-in-out .3s; } }
					@media screen and ( max-width: 768px) {
						figcaption {
							margin-top: 0;
							width: 100%;
							padding: 15px;
							.desc {
								overflow: hidden;
								text-overflow: ellipsis;
								display: -webkit-box;
								-webkit-line-clamp: 4;
								-webkit-box-orient: vertical;
								padding: 0; } } } } } } } }
.pro-detail-8 {
	padding-top: 50px;
	width: 100%;
	height: 260px;
	background-image: url('../assets/images/pro/pro-d-18.png');
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	.title {
		text-align: center;
		h2 {
			font-size: 24px;
			font-family: "Montserrat";
			color: rgb(255, 255, 255);
			font-weight: bold; }
		h1 {
			font-size: 48px;
			font-family: "Montserrat";
			color: rgb(255, 255, 255);
			font-weight: bold;
			text-transform: uppercase; }
		h3 {
			font-size: 16px;
			font-family: "Montserrat";
			color: rgb(255, 255, 255);
			text-transform: uppercase;
			font-weight: 500; } }
	.block-form {
		display: flex;
		justify-content: center;
		margin-top: 15px;
		.Module {
			width: 100%;
			display: flex;
			justify-content: center;
			.ModuleContent {
				@media screen and ( max-width: 575px) {
					width: 100%; }
				.wrap-form {
					width: 100%;
					display: flex;
					@media screen and ( max-width: 575px) {
						flex-direction: column; } }
				div {
					div {
						div {
							display: flex;
							justify-content: center;
							float: left;
							@media screen and ( max-width: 575px) {
								flex-direction: column; } } } } } }

		.form-group {
			position: relative;
			margin-right: 13px;
			input {
				border-radius: 18px;
				background-color: rgba(255, 255, 255, .3);
				width: 240px;
				height: 36px;
				padding-left: 15px;
				border: none;
				color: #ffffff;
				&::placeholder {
					font-size: 14px;
					font-family: "Montserrat";
					color: rgb(255, 255, 255); } }
			span {
				position: absolute;
				bottom: -30px;
				color: red; } }
		.frm-btnwrap {
			label {
				display: none; }
			.frm-btn {
				width: 141px;
				height: 40px;
				position: relative;
				input {
					border-radius: 19px;
					background-color: rgb(255, 255, 255);
					padding-left: 0;
					width: 100%;
					height: 100%;
					font-size: 14px;
					font-family: "Montserrat";
					color: rgb(26, 119, 186);
					font-weight: bold; }
				.material-icons {
					position: absolute;
					top: 50%;
					right: 20%;
					transform: translate(-50%, -50%);
					font-size: 14px;
					font-family: "Material Icons";
					color: rgb(26, 119, 186); } }
			.frm-btn-reset {
				display: none; } } }
	@media screen and ( max-width: 768px) {
		height: auto;
		.title {
			h2 {
				font-size: 18px; }
			h1 {
				font-size: 20px; }
			h3 {
				font-size: 14px; } }
		.block-form {
			flex-direction: column;
			.form-group {
				input {
					width: 100%; } } } } }









.global-breadcrumb {
	width: 100%;
	background-color: #f9f9f9;
	.breadcrumb {
		display: flex;
		align-items: center;
		background-color: transparent;
		flex-wrap: wrap;
		padding: 10px 0;
		list-style: none;
		margin-bottom: 0;
		li {
			height: 100%;
			border-right: solid 1px #fff;
			position: relative;
			padding: 0 10px;
			display: flex;
			align-items: center;
			a {
				font-size: 14px;
				font-family: "Montserrat";
				color: #666666;

				@media(max-width: 991.98px) {
					font-size: 10px; }

				&:hover {
					a {
						color: $main-color; } } }

			&:first-child {
				a {
					span {
						position: relative;
						font-size: 0;
						&::before {
							content: 'home';
							font-size: 14px;
							color: #666666;
							font-family: 'Material Icons'; } } } } } }


	@media(min-width: 768.98px) {
		margin-bottom: 0; }

	@media(min-width: 320px) {
		margin-bottom: 0; } }

footer {
	width: 100%;
	height: 100%;
	padding: 80px 0;
	padding-bottom: 30px;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	background-image: url('../assets/images/index/footer.png');
	.footer-card {
		.footer-title {
			margin-bottom: 30px;
			p {
				font-size: 18px;
				font-family: "Montserrat";
				color: rgb(255, 255, 255);
				font-weight: 600;
				text-transform: uppercase; }
			span {
				font-size: 14px;
				font-family: "Montserrat";
				color: rgb(255, 255, 255); } }
		.desc {
			margin-bottom: 15px;
			p {
				font-size: 14px;
				font-family: "Montserrat";
				color: rgb(255, 255, 255); } }
		.footer-body {
			ul {
				padding-left: 0;
				list-style: none;
				li {
					display: flex;
					margin-bottom: 10px;
					.material-icons {
						font-size: 16px;
						padding-right: 12px;
						font-family: "Material Icons";
						color: rgb(255, 255, 255); }
					p {
						font-size: 14px;
						font-family: "Montserrat";
						color: rgb(255, 255, 255); }
					a {
						font-size: 14px;
						font-family: "Montserrat";
						color: rgb(255, 255, 255); }
					&:last-child {
						margin-bottom: 0; } } }
			&.footer-nav {
				ul {
					li {
						position: relative; } } } }
		.wrap-form {
			.form-group {
				margin-bottom: 15px;
				input {
					width: 100%;
					height: 40px;
					padding-left: 20px;
					border-width: 1px;
					border-radius: 20px;
					color: #fff;
					border-color: rgb(235, 235, 235);
					border-style: solid;
					background-color: rgba($color:#000, $alpha: .2);
					&::placeholder {
						font-size: 14px;
						font-family: "Montserrat";
						color: rgba(255, 255, 255, 0.702); } }
				textarea {
					padding-left: 20px;
					padding-top: 15px;
					border-width: 1px;
					border-style: solid;
					border-color: rgb(235, 235, 235);
					border-radius: 10px;
					color: #ffffff;
					width: 100%;
					height: 80px;
					background-color: rgba($color:#000, $alpha: .2);
					&::placeholder {
						font-size: 14px;
						font-family: "Montserrat";
						color: rgba(255, 255, 255, 0.702); } }
				.frm-btn {
					display: flex;
					align-items: center;
					background-color: rgb(255, 255, 255);
					width: 160px;
					height: 40px;
					padding: 0 50px;
					border-radius: 20px;
					input {
						background-color: transparent;
						border: none;
						font-size: 14px;
						font-family: "Montserrat";
						color: rgb(26, 119, 186);
						font-weight: bold;
						text-transform: uppercase;
						padding: 0; }
					.frm-btn-reset {
						display: none; }
					.material-icons {
						padding-left: 25px;
						font-size: 18px;
						font-family: "Material Icons";
						color: rgb(26, 119, 186); } } }
			.frm-btnwrap {
				label {
					display: none; } } } }
	.copyright {
		border-top: solid 1px rgba($color: #fff, $alpha: .2);
		padding-top: 20px;
		.copy {
			p {
				font-size: 13px;
				font-family: "Montserrat";
				color: rgb(255, 255, 255);
				text-align: left;
				a {
					em {
						color: rgb(255, 255, 255); } } } }
		ul {
			display: flex;
			justify-content: flex-end;
			li {
				border-radius: 50%;
				background-color: rgba($color: #ffffff, $alpha: .2);
				width: 45px;
				height: 45px;
				margin-left: 10px;
				padding: 0;
				display: flex;
				align-items: center;

				&:last-child {
					padding: 0;
					border: none; }
				a {
					width: 100%;
					text-align: center;
					padding: 10px 0;
					em {
						font-size: 18px;
						color: rgb(255, 255, 255);
						text-align: center;
						z-index: 20; } } } } }
	@media screen and ( max-width: 1366px) {
		padding: 30px 0;
		.footer-card {
			margin-bottom: 20px;
			.footer-title {
				margin-bottom: 10px;
				p {
					font-size: 15px; } }
			.wrap-form {
				.form-group {
					margin-bottom: 15px; } } } }
	@media screen and ( max-width: 1199px) {
		.copyright {
			.copy {
				p {
					font-size: 10px;
					a {
						font-size: 12px; } } } } }
	@media screen and ( max-width: 767px) {
		.footer-left {
			padding-top: 0;
			.col-xl-6 {
				&:nth-child(2),
				&:nth-child(4) {
					.footer-card {
						float: right; } } }
			.footer-card {
				.footer-body {
					ul {
						li {
							p,
							a {
								font-size: 13px; } } } } } }
		.footer-right {
			padding-top: 0; } }
	@media screen and ( max-width: 575px) {
		.footer-left {
			.col-xl-6 {
				&:nth-child(2),
				&:nth-child(4) {
					.footer-card {
						float: left; } } } }
		.copyright {
			ul {
				justify-content: flex-start;
				padding-left: 0;
				margin-top: 15px;
				li {
					&:first-child {
						margin-left: 0; } } } } } }
header {
	position: fixed;
	width: 100%;
	z-index: 999;
	background-color: #fff;
	transition: all .3s ease-in-out;
	&.active {
		transition: all .3s ease-in-out;
		background-color: rgba($color: #fff, $alpha: .8); }

	nav {
		display: flex;
		align-items: center;
		padding: 0;
		width: 100%;
		@media screen and ( max-width: 1024px) {
			padding: 10px 0; }

		.logo {
			max-width: 166px;
			width: 100%;
			height: 80px;
			margin-right: 63px;
			display: flex;
			align-items: center;
			img {
				width: 100%;
				height: 100%; }
			@media screen and ( max-width: 1199px) {
				margin-right: 20px; }
			@media screen and ( max-width: 1024px) {
				width: 110px;
				margin-right: auto;
				height: unset;
				order: 1; } }
		.main-nav {
			display: flex;
			width: 100%;
			flex-direction: column;
			.Module {
				display: flex;
				width: 100%;
				.ModuleContent {
					width: 100%;
					.nav {
						width: 100%; } } }
			.top-nav {
				margin-left: auto;
				@media screen and ( max-width:  1280px) {
					margin-left: auto; }
				@media screen and ( max-width: 1025px) {
					display: none; }
				#desktop {
					display: flex;
					align-items: center;
					margin-left: auto;
					.Module {
						.ModuleContent {
							display: flex;
							align-items: center;
							margin-left: auto; } }
					#search-box {
						width: 0;
						opacity: 0;
						visibility: hidden;
						position: relative;
						padding: 0 7px;
						transition: .5s;
						.form-group {
							width: 100%;
							height: 50px;
							margin-bottom: 0;
							display: flex;
							align-items: center;
							input {
								width: 100%;
								height: 40px;
								padding: 0;
								border-radius: 0;
								padding-left: 15px; }
							.searchbutton {
								background-color: rgb(26, 119, 185);
								width: 40px;
								height: 40px;
								display: flex;
								align-items: center;
								justify-content: center;
								margin-left: -40px;
								border-radius: 0;
								.material-icons {
									color: #ffffff; } } }
						&.active {
							width: 500px;
							visibility: visible;
							opacity: 1;
							transition: .5s; } }
					.hotline {
						background-color: rgb(26, 119, 185);
						width: 127px;
						height: 26px;
						display: flex;
						align-items: center;
						padding: 0 7px;
						.ri-phone-fill {
							margin-left: 0;
							text-align: center;
							line-height: 30px;
							font-size: 16px;
							font-family: "remixicon";
							color: #fff;
							margin-right: 8px; }
						a {
							font-size: 12px;
							font-family: "Montserrat";
							color: rgb(255, 255, 255);
							font-weight: 500;
							text-transform: uppercase; } }
					.social {
						display: flex;
						align-items: center;
						list-style: none;
						padding-left: 0;
						li {
							width: 26px;
							height: 26px;
							line-height: 26px;
							text-align: center;
							margin-left: 10px;
							.ri-facebook-fill,
							.ri-youtube-fill {
								color: #ffffff;
								font-size: 18px;
								font-weight: 400; }
							&:first-child {
								background-color: #3b5998; }
							&:nth-child(2) {
								background-color: #e50914; } } }
					.search {
						background-color: rgb(245, 245, 245);
						min-width: 26px;
						height: 26px;
						margin-left: 10px;
						text-align: center;
						.material-icons {
							font-size: 18px;
							color: #666666;
							line-height: 26px; } }
					.language {
						margin-left: 20px;
						button {
							border: none;
							display: flex;
							align-items: center;
							padding: 0;
							a {
								font-size: 16px;
								font-family: "Montserrat";
								color: #333333;
								font-weight: 600; }
							&::after {
								border: none;
								content: '';
								font-family: 'Material Icons';
								font-size: 18px;
								color: rgb(153, 153, 153); } }

						.dropdown-menu {
							min-width: 10%;
							a {
								font-size: 16px;
								font-family: "Montserrat";
								color: #333333;
								font-weight: 600; } } } } }
			.nav-list {
				width: 100%;
				padding-left: 0;
				display: flex;
				align-items: center;
				justify-content: flex-end;
				.nav-item {
					margin-left: auto;
					display: flex;
					align-items: center;
					.nav-link {
						padding: 0;
						font-size: 16px;
						font-family: "Sarabun";
						color: rgb(17, 17, 17);
						font-weight: 500;
						text-transform: uppercase;

						.material-icons {
							font-size: 24px;
							font-family: "Material Icons";
							color: rgb(17, 17, 17); } }
					@media screen and ( min-width: 1024px) {
						&:hover {
							.nav-link {
								color: $main-color; } } }
					&.active {
						.nav-link {
							color: $main-color;
							.material-icons {
								color: $main-color; } } }
					&.dropdown {
						position: relative;
						cursor: pointer;
						&::before {
							position: absolute;
							top: 100%;
							left: 0;
							width: 100%;
							height: 20px;
							content: ''; }
						.sub-menu {
							position: absolute;
							border: none;
							border-radius: 0;
							width: max-content;
							top: 300%;
							opacity: 0;
							visibility: hidden;
							background-color: #ffffff;
							padding-left: 0;
							list-style: none;
							li {
								border-bottom: solid 1px #f5f5f5;
								padding: 15px;
								&:last-child {
									border: none; } } }
						@media screen and ( min-width: 1024px) {
							&:hover {
								.sub-menu {
									display: block;
									opacity: 1;
									visibility: visible;
									top: 156%;
									transition: all ease-in-out .3s; } } } } }
				@media screen and ( max-width: 1199px) {
					.nav-item {
						.nav-link {
							font-size: 14px; } } } }
			#mobile {
				border-top: solid 1px $main-color;
				padding: 15px 0;
				.Module {
					.ModuleContent {
						display: flex;
						align-items: center;
						margin-left: auto;
						justify-content: center; } }
				@media screen and ( min-width: 1025px) {
					display: none; }
				#desktop {
					width: 100%;
					display: flex;
					align-items: center;
					justify-content: center;
					#search-box {
						display: none; }
					.hotline {
						background-color: rgb(26, 119, 185);
						width: 127px;
						height: 26px;
						display: flex;
						align-items: center;
						padding: 0 7px;
						.ri-phone-fill {
							margin-left: 0;
							text-align: center;
							line-height: 30px;
							font-size: 16px;
							font-family: "remixicon";
							color: #fff;
							margin-right: 8px; }
						a {
							font-size: 12px;
							font-family: "Montserrat";
							color: rgb(255, 255, 255);
							font-weight: 500;
							text-transform: uppercase; } }
					.social {
						display: flex;
						align-items: center;
						list-style: none;
						padding-left: 0;
						li {
							width: 26px;
							height: 26px;
							line-height: 26px;
							text-align: center;
							margin-left: 10px;
							.ri-facebook-fill,
							.ri-youtube-fill {
								color: #ffffff;
								font-size: 18px;
								font-weight: 400; }
							&:first-child {
								background-color: #3b5998; }
							&:nth-child(2) {
								background-color: #e50914; } } }
					.language,
					.search {
						display: none; } } }
			@media screen and ( max-width:  1024px) {
				width: 80%;
				height: 100vh;
				position: absolute;
				right: -100%;
				top: 100%;
				background: #1a77b9;
				transition: ease-in-out all .3s;
				.nav-list {
					width: 100%;
					display: flex;
					flex-direction: column;
					.nav-item {
						width: 100%;
						padding: 0;
						margin-left: 0;
						border-bottom: solid 1px #ffffff;
						justify-content: center;
						&.dropdown {
							position: static;
							display: flex;
							flex-direction: column;
							.sub-menu {
								transition: unset;
								display: none;
								visibility: visible;
								opacity: 1;
								padding: 0;
								position: relative;
								background-color: rgba($color: #000, $alpha: .2);
								width: 100%;
								text-align: center;
								li {
									border-bottom: solid 1px #ffffff;
									a {
										color: #ffffff;
										font-size: 12px; } } } }

						.nav-link {
							color: #fff;
							padding: 15px 0; }
						&:first-child {
							width: 100%;
							border-bottom: solid 1px #fff;
							border: none;
							padding: 0;
							height: auto;
							.nav-link {
								padding: 15px 0;
								.material-icons {
									color: #ffffff; } } }
						&.active {
							background-color: #fff;
							.nav-link {
								color: $main-color; }
							&:first-child {
								.nav-link {
									.material-icons {
										color: $main-color; } } } } } }
				&.active {
					right: 0;
					transition: ease-in-out all .3s; } } }
		.nav-mobile {
			display: none;
			@media screen and ( max-width: 1024px) {
				display: block;
				order: 2;
				margin-right: 20px;
				#desktop {
					display: flex;
					align-items: center;
					#search-box {
						width: 100%;
						opacity: 1;
						visibility: visible;
						position: absolute;
						padding: 0 7px;
						transition: .5s;
						top: -100%;
						left: 0;
						.form-group {
							width: 100%;
							height: 40px;
							margin-bottom: 0;
							display: flex;
							align-items: center;
							input {
								width: 100%;
								height: 100%;
								padding: 0;
								border-radius: 0;
								padding-left: 15px; }
							.searchbutton {
								background-color: rgb(26, 119, 185);
								width: 40px;
								height: 40px;
								display: flex;
								align-items: center;
								justify-content: center;
								margin-left: -26px;
								border-radius: 0;
								.material-icons {
									color: #ffffff; } } }
						&.active {
							top: 100%;
							visibility: visible;
							opacity: 1;
							transition: .5s;
							z-index: 999; } }
					.hotline {
						display: none; }
					.social {
						display: none; }
					.search {
						margin-right: 10px;
						background-color: transparent;
						width: 26px;
						height: 26px;
						text-align: center;
						border: none;
						.material-icons {
							font-size: 25px;
							color: $main-color;
							line-height: 26px;
							font-weight: 900; } }
					.language {
						margin-left: auto;
						button {
							border: none;
							display: flex;
							align-items: center;
							padding: 0;
							a {
								font-size: 16px;
								font-family: "Montserrat";
								color: #333333;
								font-weight: 600; }
							&::after {
								border: none;
								content: '';
								font-family: 'Material Icons';
								font-size: 18px;
								color: rgb(153, 153, 153); } }

						.dropdown-menu {
							min-width: 10%;
							a {
								font-size: 16px;
								font-family: "Montserrat";
								color: #333333;
								font-weight: 600; } } } } } }
		.toggle-menu {
			cursor: pointer;
			position: relative;
			width: 40px;
			height: 40px;
			padding: 20px;
			background: $main-color;
			border-radius: 5px;
			display: none;
			@media screen and ( max-width:  1024px) {
				display: block;
				order: 3; }
			&:before,
			&:after {
				position: absolute;
				left: 50%;
				transform: translate(-50%, -50%);
				width: 18px;
				height: 2px;
				background-color: #fff;
				content: "";
				transition: .3s all linear; }


			&:before {
				top: 23px; }


			&:after {
				top: 15px; }


			&.active {

				&:before,
				&:after {
					top: 50%;
					left: 50%; }


				&:before {
					transform: translate(-50%, -50%) rotate(45deg); }


				&:after {
					transform: translate(-50%, -50%) rotate(-45deg); } } } } }

#loading-container {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 99999;
	// background-color: #ffffff
	transition: 1s;
	&::before {
		content: '';
		position: absolute;
		left: 0;
		width: 50%;
		height: 100%;
		background-color: #ffffff;
		transition: 1s; }
	&::after {

		content: '';
		position: absolute;
		right: 0;
		width: 50%;
		height: 100%;
		background-color: #ffffff;
		transition: 1s; }
	&.complete {
		opacity: 0;
		visibility: hidden;
		pointer-events: none; }

	&.complete::before {
		left: -50%; }
	&.complete::after {
		right: -50%; }
	.loading-wrapper {
		position: absolute;
		padding-bottom: 75px;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 100%;
		max-width: 450px;
		padding: 0 20px;
		z-index: 1000;
		svg {
			width: 100%; }
		svg {
			#fill {
				opacity: 0.8;
				transition: opacity 1s; } } } }


// #block__tool
// 	position: fixed
// 	top: 287px
// 	right: 20px
// 	z-index: 99
// 	opacity: 1
// 	transition: ease-in-out all .3s
// 	ul
// 		padding: 0
// 		list-style: none

// 	li
// 		width: 60px
// 		height: 60px
// 		background-color: rgb(15, 105, 171)
// 		border-radius: 3px
// 		border: solid 2px rgb(127, 216, 255)
// 		margin-bottom: 10px
// 		display: flex
// 		align-items: center
// 		justify-content: center
// 		transition: .3s all ease-in-out
// 		cursor: pointer
// 		a
// 			color: #fff
// 			font-size: 30px
// 			font-weight: 900

// 	@media(min-width: 1025px)
// 		&:hover
// 			background-color: #fff
// 			a
// 				color: $main-color

// 	&.active
// 		opacity: 0
// 		visibility: hidden
// 		transition: .3s all ease-in-out
